import { Autocomplete, Box, Button, Chip, debounce, Dialog, DialogActions, DialogContent, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { SkillsAssignGroupsUsersModel } from '../../../models/SkillsData';
import { useAPI } from '../../../../../shared/services/api/API';
import { OPTIVAL_CONSTANTS } from '../../../../../shared/Constants';

export const SelectUsersDialog = (Props : any) => {
    const { t } = useTranslation();
    const { httpGet,httpPost, handleAlertBar } = useAPI();
    const [groupUsersList, setGroupUsersList] = useState<SkillsAssignGroupsUsersModel[]>([]);
    const [searchGroupUsersText, setSearchGroupUsersText] = useState("");

    const handleSelectUsers = (usersDetails: any) => {
      const selectedUsersIndex = Props?.selectUsersList.findIndex(
        (emp: any) => emp?.id === usersDetails?.id
      );
      if (selectedUsersIndex === -1) {
        const list = [...Props?.selectUsersList, usersDetails];
        Props?.setSelectUsersList(list);
      }
    };

    const handleSelectGroups = (groupDetails: any) => {
      const selectedGroupsIndex = Props?.selectGroupsList.findIndex(
        (skill: any) => skill.groupId === groupDetails.groupId
      );
      if (selectedGroupsIndex === -1) {
        const list = [...Props?.selectGroupsList, groupDetails];
        Props?.setSelectGroupsList(list);
      }
    };


    const handleRemoveUsersGroup = async (item: any) => {
        const removeSelectUsersList = Props?.selectUsersList?.filter(
          (items: any) => items.id != item?.id 
        );
        const removeSelectGroupsList = Props?.selectGroupsList?.filter(
          (items: any) => items.groupId != item?.groupId 
        );
        Props?.setSelectGroupsList(removeSelectGroupsList);
        Props?.setSelectUsersList(removeSelectUsersList);
    };

    const fetchGroupUsersList = useMemo(
      () =>
        debounce((searchUser: string) => {
          const queryParams = {
            query: searchUser,
          };
          const alreadySelectedGroupId = Props?.selectGroupsList?.map((e: any) => e?.groupId);
          const alreadySelectedUserId = Props?.selectUsersList?.map((e: any) => e?.id);
          const requestBody = {
            userIds: alreadySelectedUserId,
            groupIds: alreadySelectedGroupId
          }
          httpPost(OPTIVAL_CONSTANTS.API_URLS.SEARCH_GROUP_USERS,requestBody, queryParams).then(
            (response: any) => {
              if (response && response.data && !response.data?.error) {
                setGroupUsersList(response.data);
              } else {
                handleAlertBar(
                  "error",
                  response?.data?.message || t("opva.serviceUnavailable")
                );
              }
            },
            (error: any) => {
              handleAlertBar(
                "error",
                error.message || t("opva.serviceUnavailable")
              );
            }
          );
        }, 500),
      [Props?.selectUsersList, Props?.selectGroupsList]
    );

    useEffect(() => {
      fetchGroupUsersList('');
    },[Props?.selectUsersList])
    return (
      <Dialog fullWidth open={Props?.openDialog} onClose={Props?.handleCloseDialog}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant='h5'>{t("opva.selectUsers")}</Typography>
            <IconButton aria-label="close" onClick={Props?.handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </div>
          <Box>
            <div style={{ display: "flex", flexDirection: "column" }} className='mt-4'>
            <Typography variant='bodySmall'>{t("opva.search")}</Typography>
          <Autocomplete
            id="search-by-group"
            className="w-100"
            autoComplete
            options={groupUsersList ? groupUsersList : []}
            noOptionsText={
              !groupUsersList ? (
                <Typography variant="labelNormal">
                  {t("opva.type2Letters")}
                </Typography>
              ) : (
                <Typography variant="labelNormal">
                  {t("opva.noGroupUserFound")}
                </Typography>
              )
            }
            renderOption={(props, option: SkillsAssignGroupsUsersModel) => (
              <li {...props} value={option?.employee?.id || option?.group?.groupId}>
                  <Typography variant="labelLarge">{option.employee?.firstName || option?.group?.name}</Typography>
              </li>
            )}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Regular option
              return option?.employee?.firstName || option?.group?.name;
            }}
            value={null}
            onChange={(event: any, newValue) => {
              if (newValue && typeof newValue === "object") {
                if(newValue?.group) {
                  handleSelectGroups(newValue?.group);
                } else {
                  handleSelectUsers(newValue?.employee);
                }
              }
            }}
            onInputChange={(event: any, newInputValue: string) => {
              // if (newInputValue && newInputValue.length > 2) {
                fetchGroupUsersList(newInputValue);
              // }
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size="small"
                variant="standard"
                placeholder={t("opva.searchByGroupsUsersName")}
                className="search-name-text pe-0"
                value={searchGroupUsersText}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="inherit" />
                    </InputAdornment>
                  ), 
                  endAdornment: (
                    <InputAdornment position="end">
                      <></>
                    </InputAdornment>
                  ), 
                }}
                onChange={(event) => setSearchGroupUsersText(event.target.value)}
              />
            )}
          />
        </div>
        <div className="mt-1">
          {Props?.selectGroupsList?.map((item: any, index: any) => (
            <Chip
              color="default"
              size="medium"
              variant="filled"
              className="me-2 mt-2"
              key={index}
              label={item?.name}
              onDelete={() => handleRemoveUsersGroup(item)}
            />
          ))}
          {Props?.selectUsersList?.map((item: any, index: any) => (
            <Chip
              color="default"
              size="medium"
              variant="filled"
              className="me-2 mt-2"
              key={index}
              label={item?.firstName}
              onDelete={() => handleRemoveUsersGroup(item)}
            />
          ))}
        </div>
      </Box>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            onClick={Props?.selectUsers}
            color="primary"
            variant="contained"
          >
            {t("opva.selectUsers")}
          </Button>
        </DialogActions>
      </Dialog>
    );
}