import "./NewGroup.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty, map } from "lodash";
import {
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import { GroupManagementConstants } from "../../constants/GroupManagementConstants";
import {
  GroupMgmtListModel,
  NewGroupDataModel,
  NewGroupDataProps,
  UserListModel,
} from "../../models/GroupManagementModels";
import { UserList } from "../user-list/UserList";
import { UserGroupList } from "../user-group-list/UserGroupList";
import { AuthenticationContext } from "../../../../shared/Contexts";
import { useAPI } from "../../../../shared/services/api/API";
import { updateURLParams } from "../../../../shared/utils/Utils";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useTranslation } from "react-i18next";

export const NewGroup = (props: NewGroupDataProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const routeParams = new URLSearchParams(location.search);
  const { authUserDetails } = useContext(AuthenticationContext);
  const editGroupData: GroupMgmtListModel = location.state?.groupData;
  const { httpPost, httpPut, handleAlertBar } = useAPI();
  const [newGroupData, setNewGroupData] = useState({} as NewGroupDataModel);

  const backToGroupsMgmt = () => {
    navigate("/group-management");
  };

  const handleGroupDataOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewGroupData((prevState: NewGroupDataModel) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const getAddedUserList = (userList: UserListModel[]) => {
    setNewGroupData((prevState: NewGroupDataModel) => ({
      ...prevState,
      addedUsersList: userList,
    }));
  };

  const getRemovededUserList = (userList: UserListModel[]) => {
    setNewGroupData((prevState: NewGroupDataModel) => ({
      ...prevState,
      removedUsersList: userList,
    }));
  };

  const getBulkUserUploadFile = (uploadedFile: File | null) => {
    setNewGroupData((prevState: NewGroupDataModel) => ({
      ...prevState,
      bulkUserFile: uploadedFile,
    }));
  };

  const handleGroupCreation = () => {
    if (props.isEdit) {
      handleUpdateGroupCreation();
    } else {
      handleAddGroupCreation();
    }
  };

  const handleAddGroupCreation = () => {
    const urlParams = {
      organizationId: authUserDetails.organizationId,
    };
    const requestFormData = new FormData();
    requestFormData.append("name", newGroupData.groupName);
    requestFormData.append("description", newGroupData.groupDescription || "");
    requestFormData.append("location", "");
    requestFormData.append(
      "employeeIds",
      map(newGroupData.addedUsersList, "id").join()
    );
    requestFormData.append("file", newGroupData.bulkUserFile || "");
    httpPost(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.ADD_GROUP_BY_ORGANIZATION,
        urlParams
      ),
      requestFormData
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            handleAlertBar(
              "success",
              t("opva.groupCreatedSuccessMsg")
            );
            backToGroupsMgmt();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleUpdateGroupCreation = () => {
    const urlParams = {
      groupId:
        routeParams && routeParams.get("groupId")
          ? routeParams.get("groupId")
          : 0,
    };
    const requestFormData = new FormData();
    requestFormData.append("name", newGroupData.groupName);
    requestFormData.append("description", newGroupData.groupDescription || "");
    requestFormData.append("location", "");
    requestFormData.append(
      "employeeIdsToAdd",
      map(newGroupData.addedUsersList, "id").join()
    );
    requestFormData.append(
      "employeeIdsToRemove",
      map(newGroupData.removedUsersList, "id").join()
    );
    requestFormData.append("file", newGroupData.bulkUserFile || "");
    httpPut(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.UPDATE_GROUP, urlParams),
      requestFormData
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            handleAlertBar(
              "success",
              t("opva.groupUpdatedSuccessMsg")
            );
            backToGroupsMgmt();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (editGroupData && !isEmpty(editGroupData)) {
      const groupData = {
        groupName: editGroupData.name,
        groupDescription: editGroupData.description,
      };
      setNewGroupData((prevState: NewGroupDataModel) => ({
        ...prevState,
        ...groupData,
      }));
    }
  }, [editGroupData]);

  return (
    <div className="new-group-container">
      <div className="bg-light-blue px-4 pt-2">
        <Breadcrumb
          pathList={[
            { name: t("opva.management"), path: "management" },
            { name: t("opva.groupManagement"), path: "group-management" },
          ]}
        />
        <Grid container alignItems="center" className="pt-2 pb-4">
          <Grid item xs md lg={6} className="d-flex align-items-center">
            <Link
              component="button"
              title="Back to Group Management"
              onClick={backToGroupsMgmt}
            >
              <KeyboardArrowLeftIcon fontSize="large" />
            </Link>
            <Typography variant="h3" className="mb-0">
              {props.isEdit ? t("opva.updateGroup") : t("opva.newGroup")}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="new-group-content px-5 py-3"
      >
        <Typography variant="bodyMedium">
          {props.isEdit ? t("opva.editGroup") : t("opva.addGroup")}
        </Typography>
        <Grid container alignItems="center" className="mt-3">
          <Grid item xs md lg={6} className="d-flex align-items-center">
            <Grid item xs md lg={6} className="d-flex flex-column">
              <Typography variant="bodySmall">{t("opva.groupName")}</Typography>
              <Input
                id="groupName"
                sx={{
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  } }}
                name="groupName"
                placeholder={t("opva.enterGroupName")}
                value={newGroupData.groupName ?? ""}
                endAdornment={
                  newGroupData.groupName && (
                    <InputAdornment position="end">
                      <Link
                        component="button"
                        title={t("opva.clear")}
                        onClick={() =>
                          setNewGroupData((prevState: NewGroupDataModel) => ({
                            ...prevState,
                            groupName: "",
                          }))
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </Link>
                    </InputAdornment>
                  )
                }
                className="w-75"
                aria-describedby="group-name-text"
                onChange={handleGroupDataOnChange}
              />
            </Grid>
            <Grid item xs md lg={6} className="d-flex flex-column">
              <Typography variant="bodySmall">
                {t("opva.description")}
              </Typography>
              <Input
                id="groupDescription"
                name="groupDescription"
                placeholder={t("opva.enterDescription")}
                sx={{
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  } }}
                value={newGroupData.groupDescription ?? ""}
                endAdornment={
                  newGroupData.groupDescription && (
                    <InputAdornment position="end">
                      <Link
                        component="button"
                        title={t("opva.clear")}
                        onClick={() =>
                          setNewGroupData((prevState: NewGroupDataModel) => ({
                            ...prevState,
                            groupDescription: "",
                          }))
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </Link>
                    </InputAdornment>
                  )
                }
                className="w-75"
                aria-describedby="group-description-text"
                onChange={handleGroupDataOnChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <div className="mt-3">
          <UserGroupList
            title={t("opva.addedUsers")}
            sendAddedUserList={getAddedUserList}
            sendRemovedUserList={getRemovededUserList}
            sendBulkUserFile={getBulkUserUploadFile}
            isEditMode={props.isEdit}
          />
          <Button
            variant="contained"
            size="medium"
            className="px-4 mb-5"
            disabled={!newGroupData.groupName}
            onClick={handleGroupCreation}
          >
            {props.isEdit ? t("opva.saveUpdate") : t("opva.addGroup")}
          </Button>
          <UserList title={t("opva.addUsers")} isEditMode={props.isEdit} />
        </div>
      </Box>
    </div>
  );
};
