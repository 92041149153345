import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { OrganisationDetails } from "./organisation-details/OrganisationDetails";
import { PlansAndBilling } from "../../plans-and-building/components/PlansAndBuilding";
import { useTranslation } from "react-i18next";

export const Organisation = () => {
  const { t } = useTranslation();
  return (
    <div className="organisation-container">
      <OrganisationDetails
        title={t("opva.organizationProfile")}
      />
      <PlansAndBilling />
    </div>
  );
};
