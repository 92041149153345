import { t } from "i18next";
import { TableHeadCell } from "../../../../shared/models/TableModels";
import { useTranslation } from "react-i18next";

export const ManageOrgConstants = {
  NO_DATA_AVAILABLE: "No manage oraganisation(s) data available.",
  NO_SEARCH_DATA_AVAILABLE: "Search manage organisation data is not available.",
  NO_USER_DATA_AVAILABLE: "No manage organisation(s) data available.",
  NO_ADDER_USER_DATA_AVAILABLE: "No manage Orgaisation added here.",
  // MANAGE_ORG_TABLE_HEADER: [
  //   {
  //     id: "name",
  //     label: t("opva.organizationName"),
  //     width: 40,
  //     sorting: true,
  //     sortingProperty: "name",
  //     position: "left",
  //   },

  //   {
  //     id: "createdBy",
  //     label:t("opva.createdBy"),
  //     width: 30,
  //     sorting: true,
  //     sortingProperty: "createdBy",
  //     position: "left",
  //   },
  //   {
  //     id: "endDate",
  //     label: t("opva.expirationDate"),
  //     width: 30,
  //     sorting: true,
  //     sortingProperty: "endDate",
  //     position: "left",
  //   },

  //   {
  //     id: "actions",
  //     label: t("opva.actions"),
  //     width: 30,
  //     sorting: false,
  //     sortingProperty: "",
  //     position: "center",
  //   },
  // ] as TableHeadCell[],
};

export const useManageOrganisationUtil = () => {
  const { t } = useTranslation();
  const getManageOrgHeaders = () => {
    return [
      {
        id: "name",
        label: t("opva.organizationName"),
        width: 40,
        sorting: true,
        sortingProperty: "name",
        position: "left",
      },

      {
        id: "createdBy",
        label: t("opva.createdBy"),
        width: 30,
        sorting: true,
        sortingProperty: "createdBy",
        position: "left",
      },
      {
        id: "endDate",
        label: t("opva.expirationDate"),
        width: 30,
        sorting: true,
        sortingProperty: "endDate",
        position: "left",
      },

      {
        id: "actions",
        label: t("opva.actions"),
        width: 30,
        sorting: false,
        sortingProperty: "",
        position: "center",
      },
    ] as TableHeadCell[];
  };
  return { getManageOrgHeaders };
};
