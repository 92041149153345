import { Divider, Grid, Typography, Button } from "@mui/material";
import { useState } from "react";
import "./Sign.scss";
import Successful from "./Successful";
import OrganizationForm from "../../../../shared/components/organization-form/OrganizationForm";
import { useAPI } from "../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";

import { LoginHereLink } from "../../../../shared/components/login-here-link/LoginHereLink";
import { SignUpFormData } from "../../models/SignUpModels";
import { useTranslation } from "react-i18next";

const SignUp = () => {
  const { t } = useTranslation();
  const { httpPost, handleAlertBar } = useAPI();
  const [formData, setFormData] = useState<SignUpFormData>({
    organizationName: "",
    addressLine: "",
    addressLine2: "",
    state: "",
    country: "",
    zipCode: "",
    email: "",
    mobileNumber: "",
    industrySector: "",
    // numberOfUsers: "",
    opva: "",
    orgPreferredLanguages: [
      {
        actualDisplayName: "English",
        aws_language_code: "en",
        display_name: "English",
        gcp_language_code: "en-IN",
        id: 1,
        language_name: "ENGLISH",
      },
    ],
    errors: {
      organizationName: false,
      addressLine: false,
      addressLine2: false,
      state: false,
      country: false,
      zipCode: false,
      email: false,
      mobileNumber: false,
      industrySector: false,
      orgPreferredLanguages: false,
      opva: false,
    },
  });
  const [success, setSuccess] = useState(false);

  const isFormValid = () => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    const isMobileValid = /^\d{10}$/.test(formData.mobileNumber);
    const isIndustrySectorValid = /^[A-Za-z]+$/.test(formData.industrySector);
    // const isNumberOfUsersValid = /^\d+$/.test(formData.numberOfUsers);

    return (
      formData.organizationName.trim() !== "" &&
      formData.addressLine.trim() !== "" &&
      isEmailValid &&
      isMobileValid &&
      isIndustrySectorValid
      // && isNumberOfUsersValid
    );
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    // handleInputChange({ target: { name: name, value: value.trim() } });
    let trimmedValue = value; // Initialize trimmedValue with value
    if (
      value &&
      (name === "email" || name === "mobileNumber" || name === "industrySector")
    ) {
      // Check if value exists before calling trim()
      trimmedValue = value.trim(); // Trim the value
    }

    let isError = false;

    if (!trimmedValue) {
      isError = true;
    } else if (name === "email") {
      isError = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedValue);
    } else if (name === "mobileNumber") {
      isError = !/^\d{10}$/.test(trimmedValue);
    } else if (name === "industrySector") {
      isError = !/^[A-Za-z]+$/.test(trimmedValue);
    }
    // else if (name === "numberOfUsers") {
    //   isError = !/^\d+$/.test(value);
    // }

    setFormData((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
      errors: { ...prevData.errors, [name]: isError },
    }));
  };

  const SignUpData = async (formData: any) => {
    const param = {
      name: formData.organizationName.trim(),
      address: formData.addressLine.trim(),
      address2: formData.addressLine2.trim(),
      state: formData.state,
      country: formData.country,
      zipCode: formData.zipCode,
      mail: formData.email,
      phone: formData.mobileNumber,
      industrySector: formData.industrySector,
      // numberOfUsers: +formData.numberOfUsers,
      purpose: formData.opva,
    };
    await httpPost(OPTIVAL_CONSTANTS.API_URLS.SIGN_UP_REQUEST, param).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar(
            "error",
            response.data.message || OPTIVAL_CONSTANTS.SERVICE_UNAVAILABLE
          );
        } else {
          setSuccess(true);
        }
      },
      (error) => {}
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (isFormValid()) {
      setFormData((prevData) => ({ ...prevData, success: true }));
      SignUpData(formData);
    } else {
      console.log("Form has errors. Please fill in all required fields.");
    }
  };

  return (
    <>
      {success ? (
        <div className="successful-container bg-dark-blue">
          <Successful />
        </div>
      ) : (
        <div className="sign-up-container bg-dark-blue">
          <Grid container spacing={0}>
            <div className="subContainer">
              <div className="signUpContainer">
                <Grid item xs={12} sm={12} md={5.3} lg>
                  <div className="leftSideContainer">
                    <Grid item xs={12} sm={12} md lg>
                      <img
                        src="images/Logo.jpg"
                        title="Optival"
                        alt="Company Logo"
                        className="logo-container"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md lg>
                      {/* <Typography variant="bodySmall" className="sub-content">
                        {t("opva.signupDescription")}
                      </Typography> */}
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={0.01} lg={0.01}>
                  <div className="Dividers">
                    <Divider variant="fullWidth" flexItem />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="sign-up-form-container">
                    <Typography variant="h3" mb={3}>
                      {t("opva.signUpForm")}
                    </Typography>

                    <div>
                      <OrganizationForm
                        formData={formData}
                        handleInputChange={handleInputChange}
                        handleSubmit={handleSubmit}
                        callFrom="signUp"
                      />
                    </div>
                    <div
                      className="mt-4"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <LoginHereLink />
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSubmit}
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                          padding: "8px 22px",
                          borderRadius: "4px",
                        }}
                        disabled={!isFormValid()}
                      >
                        {t("opva.submit")}
                      </Button>
                    </div>
                  </div>
                </Grid>
              </div>
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

export default SignUp;
