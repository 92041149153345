import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./DashboardGroups.scss";
import { TableHeadCell } from "../../../../shared/models/TableModels";
import { useAPI } from "../../../../shared/services/api/API";
import { useContext, useEffect, useState } from "react";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { SpinnerContext } from "../../../../shared/SpinnerContext";
import { DashboardConstants } from "../../models/DashboardInfoProps";
import { TableLoading } from "../../../../shared/components/skeleton/table-loading/TableLoading";
import { useTranslation } from "react-i18next";

const tableHeadCells: readonly TableHeadCell[] = [
  {
    id: "groupName",
    label: "opva.groupName",
    width: 30,
    position: "left",
    sorting: true,
    sortingProperty: "asc",
  },
  {
    id: "users",
    label: "opva.groupName",
    width: 20,
    position: "left",
    sorting: true,
    sortingProperty: "asc",
  },
  {
    id: "workflowAssigned",
    width: 20,
    label: "opva.workflowAssigned",
    position: "left",
    sorting: true,
    sortingProperty: "asc",
  },
  {
    id: "workflowViewed",
    width: 20,
    label: "opva.workflowViewed",
    position: "left",
    sorting: true,
    sortingProperty: "asc",
  },
];
export const DashboardGroups = () => {
  const { t } = useTranslation();
  const { httpGet } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [data, setData] = useState<any>({
    groups: [
      {
        name: "Group1",
        usersAssigned: 1,
        workflowAssigned: 6,
        workflowViewed: 6,
      },
      {
        name: "Group2",
        usersAssigned: 9,
        workflowAssigned: 8,
        workflowViewed: 6,
      },
      {
        name: "Group3",
        usersAssigned: 5,
        workflowAssigned: 1,
        workflowViewed: 6,
      },
    ],
    groupsAddedThisMonth: 3,
  });

  // useEffect(() => {
  //   fetchGroupData();
  // }, []);

  const fetchGroupData = () => {
    const quaryParams = { page: 0, sortColumn: "id", isDescending: false };
    httpGet(
      OPTIVAL_CONSTANTS.API_URLS.GET_GROUP_DATA_FOR_DASHBOARD,
      quaryParams,
      false
    ).then(
      (response) => {
        if (response && response.data) {
          setData(response.data);
        }
      },
      (error) => {}
    );
  };

  return (
    <div className="dashboard-groups-container">
      <h5 className="fw-bold">{t("opva.groups")}</h5>
      <div className="groups-added-month">
        <img src="images/Circle_Tick.svg" alt="Success Tick" className="me-1" />
        <div>
          <label className="count-text">
            {data?.groupsAddedThisMonth || 0} {t("opva.added")}
          </label>
          &nbsp;
          <label className="month-text">{t("opva.thisMonth")}</label>
        </div>
      </div>

      <TableContainer className="groups-table-container">
        <Table size="medium" aria-label="Groups table">
          <TableHead>
            <TableRow>
              {tableHeadCells.map((headCell: TableHeadCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.position}
                  className="py-2"
                  sx={{
                    width: headCell.width + "%",
                  }}
                >
                  {t(headCell.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isSpinnerShow ? (
            <TableLoading column={4} />
          ) : (
            <TableBody>
              {data && data?.groups && data.groups.length ? (
                data.groups.map((row: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="group-name-text">
                        <img
                          src="images/User.svg"
                          alt="User"
                          className="me-2"
                        />
                        <Typography
                          sx={{ width: "200px" }}
                          variant="bodySmall"
                          className="header"
                        >
                          {row.name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall" className="header">
                        {row.usersAssigned}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall" className="header">
                        {row.workflowAssigned}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall" className="header">
                        {row.workflowViewed | 0}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    {t("opva.NoGroupsDataAvailable")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};
