import "./DashboardChart.scss";

export const DashboardChart = () => {
  return (
    <div className="dashboard-chart-container">
      <img
        src="images/Dashboard-Chart.png"
        width={650}
        height={340}
        alt="Dashboard Chart"
      ></img>
    </div>
  );
};
