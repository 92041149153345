import "./Home.scss";
import { Grid, Link, Typography } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import PrintIcon from "@mui/icons-material/Print";
import { DashboardInfo } from "./dashboard-info/DashboardInfo";
import { DashboardChart } from "./dashboard-chart/DashboardChart";
import { DashboardGroups } from "./dashboard-groups/DashboardGroups";
import { useTranslation } from "react-i18next";

export const Home = () => {
  const { t } = useTranslation(); 
  return (
    <div className="home-container bg-light-blue px-4 pt-3">
      <div className="dashboard-mask-container">
        <div className="content">
          <Typography variant="h3">{t("opva.opvaDashboard")}</Typography>
          <Typography variant="h1" className="mt-5">
            {t("opva.dashboardWillPopulate")}
          </Typography>
        </div>
      </div>
      <Grid
        container
        sx={{
          alignItems: "center",
        }}
      >
        <Grid item xs={6} md={6}>
          <Typography variant="h3" className="mb-0">
            {t("opva.opvaDashboard")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
        >
          <Link component="button" title="Share" className="me-4" disabled={true}>
            <ShareIcon color="disabled"/>
          </Link>
          <Link component="button" title="Print" disabled={true}>
            <PrintIcon color="disabled"/>
          </Link>
        </Grid>
      </Grid>

      <Grid container className="mt-1" spacing={5}>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title="opva.mostActiveGroup"
            infoName="Group Name"
            iconName="Theme-Profile.svg"
            bgColorName="blue"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title="opva.monthlyActivity"
            infoName="+3,052"
            infoSubName="-14%"
            iconName="Green-Profile.svg"
            bgColorName="green"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title="opva.noOfWorkflowsDeployed"
            infoName="2,300"
            iconName="Monitor.svg"
            bgColorName="blue"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title="opva.totalWatchedHours"
            infoName="82hrs 23mins"
            iconName="Eye.svg"
            bgColorName="green"
          />
        </Grid>
      </Grid>

      <Grid container className="mt-1" spacing={5}>
        <Grid item xs={12} md={6}>
          <DashboardChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardGroups />
        </Grid>
      </Grid>
    </div>
  );
};
