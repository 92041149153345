
import { t } from "i18next";
import { TableHeadCell } from "../../../shared/models/TableModels";
import { useTranslation } from "react-i18next";

export const SignUpRequestConstants = {
    SINGUP_REQUEST_ORGANISATION: "Sign up Request Organisation",
    SEARCH_FOR_ORG : "Search for organization",
    NO_DATA_AVAILABLE: "No sign up request data available.",
    NO_SEARCH_DATA_AVAILABLE: "Search sign up request data is not available.",
    // SIGNUP_REQUEST_TABLE_HEADER: [
    //   {
    //     id: "name",
    //     label: t("opva.organizationName"),
    //     width: 15,
    //     sorting: true,
    //     sortingProperty: "name",
    //     position: "left",
    //   },
    //   {
    //     id: "mail",
    //     label: t("opva.emailId"),
    //     width: 20,
    //     sorting: true,
    //     sortingProperty: "mail",
    //     position: "left",
    //   },
    //   {
    //     id: "phone",
    //     label: t("opva.phoneNo"),
    //     width: 15,
    //     sorting: true,
    //     sortingProperty: "phone",
    //     position: "left",
    //   },
    //   {
    //     id: "createdAt",
    //     label: t("opva.createdOn"),
    //     width: 15,
    //     sorting: true,
    //     sortingProperty: "createdAt",
    //     position: "left",
    //   },
    //   {
    //     id: "onboardOrganisation",
    //     label: t("opva.onboardOrganisation"),
    //     width: 10,
    //     sorting: false,
    //     sortingProperty: "",
    //     position: "center",
    //   },
    // ] as TableHeadCell[],
  }

  export const useSignUpRequestUtil = () => {
    const { t } = useTranslation();
    const getSignUpReq = () => {
      return [
        {
          id: "name",
          label: t("opva.organizationName"),
          width: 15,
          sorting: true,
          sortingProperty: "name",
          position: "left",
        },
        {
          id: "mail",
          label: t("opva.emailId"),
          width: 20,
          sorting: true,
          sortingProperty: "mail",
          position: "left",
        },
        {
          id: "phone",
          label: t("opva.phoneNo"),
          width: 15,
          sorting: true,
          sortingProperty: "phone",
          position: "left",
        },
        {
          id: "createdAt",
          label: t("opva.createdOn"),
          width: 15,
          sorting: true,
          sortingProperty: "createdAt",
          position: "left",
        },
        {
          id: "onboardOrganisation",
          label: t("opva.onboardOrganisation"),
          width: 10,
          sorting: false,
          sortingProperty: "",
          position: "center",
        },
      ] as TableHeadCell[];
    };
    return { getSignUpReq };
  };