import { Grid, MenuItem, TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { OnBoardOrganisationPlanProps } from "../../models/OnboardOrganisationData";
import { useTranslation } from "react-i18next";

export const PlansBillingPriod = (Props: OnBoardOrganisationPlanProps) => {
    const { t } = useTranslation();
    const handleStartDateChange = (date: any) => {
        Props.handleFormChange({ target: { name: "startDate", value: date } });
    }
    const handleEndDateChange = (date: any) => {
        if (Props.formData.startDate && date < Props.formData.startDate) {
            Props.handleFormChange({ target: { name: "endDate", value: Props.formData.startDate } });
        }
        Props.handleFormChange({ target: { name: "endDate", value: date } });
    }
    return (
        <form>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} >
                    <TextField
                        variant="standard"
                        select
                        required
                        name="plan"
                        value={Props?.formData?.plan}
                        label={t("opva.plans")}
                        fullWidth

                        onChange={Props?.handleFormChange}
                        error={Props?.formData?.errors?.plan && Props?.formData.plan !== ""}
                        helperText={
                            Props?.formData?.errors?.plan && Props?.formData.plan !== ""
                                ? t("opva.enterPlanHelperText")
                                : ""
                        }
                    >
                        {Props?.planList.map((option, index) => (
                            <MenuItem key={index} value={option.id} disabled={option.id !== 1}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>

                </Grid>
                <Grid item xs={12} sm={12} >
                    <TextField
                        variant="standard"
                        required
                        fullWidth
                        name="cost"
                        value={Props?.formData?.cost}
                        label={t("opva.cost")}
                        onChange={Props.handleFormChange}
                        error={Props?.formData.errors.cost && Props?.formData.cost !== ""}
                        helperText={
                            Props?.formData.errors.cost && Props?.formData.cost !== ""
                                ? t("opva.enterCostHelperText")
                                : ""
                        }
                    />

                </Grid>
                <Grid item xs={12} sm={12} >
                    <TextField
                        variant="standard"
                        required
                        name="numberOfUserSeats"
                        value={Props?.formData?.numberOfUserSeats}
                        label={t("opva.noOfUsers")}
                        fullWidth

                        onChange={Props.handleFormChange}
                        error={Props?.formData?.errors?.numberOfUserSeats && Props?.formData.numberOfUserSeats !== ""}
                        helperText={
                            Props?.formData?.errors?.numberOfUserSeats && Props?.formData.numberOfUserSeats !== ""
                                ? t("opva.enterNoOfUsersHelperText")
                                : ""
                        }
                    />

                </Grid>
                <Grid container item xs={12} sm={12} spacing={2} className="pt-4">
                    <Grid item xs={6} sm={6} md lg>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t("opva.startDate")}
                                slotProps={{ textField: { variant: 'standard' } }}
                                name="startDate"
                                value={Props?.formData?.startDate}
                                onChange={handleStartDateChange}
                            />
                        </LocalizationProvider>

                    </Grid>

                    <Grid item xs={6} sm={6} md lg>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t("opva.endDate")}
                                slotProps={{ textField: { variant: 'standard' } }}
                                name="endDate"
                                value={Props?.formData?.endDate}
                                minDate={Props?.formData?.startDate}
                                onChange={handleEndDateChange}
                            />
                        </LocalizationProvider>

                    </Grid>

                </Grid>
            </Grid>
        </form>
    )
}