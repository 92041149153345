import { ChangeEvent, KeyboardEvent, useContext, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Fade,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  OutlinedInput,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpIcon from "@mui/icons-material/Help";
import { Menu } from "../../menu/components/Menu";
import { Profile } from "../../profile/components/Profile";
import { AppDispatch, RootState } from "../../../shared/Store";
import { setSearchText } from "../../../shared/reducers/HeaderSearchReducer";
import { useTranslation } from "react-i18next";
import { NotificationContext } from "../../notification/contexts/NotificationContext";

export const Header = () => {
  const { pathname } = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [isHintOpen, setIsHintOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const searchText = useSelector((state: RootState) => state.headerSearch.text);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { haveUnreadNotifications } = useContext(NotificationContext);
  const getSearchPlaceholder = (): string => {
    let placeholderText = t("opva.search");
    if (pathname && pathname === "/instrukt") {
      placeholderText += t("opva.workflow"); 
    }
    return placeholderText;
  };

  const showSearchHintText = (el: any) => {
    setIsHintOpen(true);
    setAnchorEl(el);
    setTimeout(() => {
      setIsHintOpen(false);
      setAnchorEl(null);
    }, 1500);
  };

  const updateSearchText = (value: string) => {
    dispatch(setSearchText(value));
  };

  const handleSearchDebounce = debounce(updateSearchText, 500);

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    if (!value) {
      handleSearchDebounce(event.target.value);
    } else if (value && value.length > 2) {
      handleSearchDebounce(event.target.value);
    } else if (value && value.length <= 2) {
      showSearchHintText(event.currentTarget);
    }
  };

  const handleSearchKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateSearchText((event.target as HTMLInputElement).value);
    }
  };

  const handleClearSearch = () => {
    setSearchValue("");
    updateSearchText("");
  };

  useMemo(() => {
    if (!searchText) setSearchValue(searchText);
  }, [searchText]);

  return (
    <Grid
      container
      sx={{
        alignItems: "center",
        paddingTop: 2,
      }}
    >
      <Grid item xs={9} md={9} className="d-flex">
        <img
          src="images/Logo.jpg"
          width={80}
          height={25}
          className="ms-3"
          title="Optival"
          alt="Optival Logo"
        ></img>
        <Menu />
      </Grid>
      <Grid
        item
        md={3}
        className="pb-1"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {pathname && pathname === "/instrukt" ? (
          <FormControl sx={{ marginRight: 2 }} variant="outlined">
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchValue ? (
                  <InputAdornment position="end">
                    <Link
                      component="button"
                      title={t("opva.clear")}
                      onClick={handleClearSearch}
                    >
                      <CloseIcon fontSize="small" />
                    </Link>
                  </InputAdornment>
                ) : null
              }
              size="small"
              placeholder={getSearchPlaceholder()}
              sx={{ paddingLeft: "10px" }}
              value={searchValue}
              onChange={handleSearchOnChange}
              onKeyDown={handleSearchKeyEnter}
            />
            <Popper
              id="search-hint-text"
              open={isHintOpen}
              anchorEl={anchorEl}
              placement="bottom-end"
              modifiers={[
                {
                  name: "arrow",
                  enabled: true,
                },
              ]}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper sx={{ p: 1 }}>
                    <Typography variant="labelSmall">
                      Type more than 2 letters to search.
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </FormControl>
        ) : null}
        <Badge
          invisible={!haveUnreadNotifications}
          variant="dot"
          color="primary"
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ marginRight: 2 }}
        >
          <NotificationsIcon
            sx={{ color: "#45545A" }}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/notifications")}
          />
        </Badge>
        {/* <HelpIcon sx={{ color: "#45545A", marginRight: 2 }} /> */}
        <Profile />
      </Grid>
    </Grid>
  );
};
