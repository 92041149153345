/* eslint-disable no-template-curly-in-string */
import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  Link,
  Chip,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import CloseIcon from "@mui/icons-material/Close";
import { CREATE_WF_CONSTANTS } from "../../../models/CreateWorkflowConstant";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MyButton } from "../../../../../shared/components/Buttons/Button";
import { useAPI } from "../../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import "./StepWorkflow.scss";
import { WorkflowConstants } from "../../../../workflow/models/Workflow";
import { updateURLParams } from "../../../../../shared/utils/Utils";
import { useTranslation } from "react-i18next";
export const StepWorkflow = (Props: any) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | any | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    null
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [fileType, setFileType] = useState<
    "image" | "video" | "pdf" | "other" | null
  >(null);
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const [isChecked, setIsChecked] = useState(true);
  const [editStepData, setEditStepData] = useState<any>({});
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { httpPost, httpPut, handleAlertBar } = useAPI();
  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file);

        const fileType = getFileType(file?.type);
        console.log("File MIME type:", file?.type);

        setFileType(fileType);
        const fileUrl = URL.createObjectURL(file);
        setPreviewUrl(fileUrl);
      }
    }
  };
  const getFileType = (fileType: string) => {
    if (fileType) {
      if (fileType.startsWith("image/")) {
        return "image";
      } else if (fileType.startsWith("video/")) {
        return "video";
      } else if (fileType === "application/pdf") {
        return "pdf";
      } else {
        return "other"; // or return null if you prefer
      }
    } else {
      return null;
    }
  };
  const handleUploadBoxDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleUploadBoxDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const dropFiles = e.dataTransfer.files[0];
    const fileType = getFileType(dropFiles?.type);
    setFileType(fileType);
    if (
      dropFiles.type.startsWith("image/") ||
      dropFiles.type.startsWith("video/") ||
      dropFiles.type === "application/pdf"
    ) {
      setSelectedFile(dropFiles);
      const fileUrl = URL.createObjectURL(dropFiles);
      setPreviewUrl(fileUrl);
      setErrorMsg("");
    } else {
      setErrorMsg(CREATE_WF_CONSTANTS.ERROR_MSG_UPLOAD_BOX_STEP);
      setSelectedFile(null);
      setPreviewUrl(null);
    }
  };

  const getFileTypeComponent = (fileType: string) => {
    switch (getFileType(fileType)) {
      case "image":
        return "img";
      case "video":
        return "video";
      case "pdf":
        return "iframe";
      default:
        return "div"; // Default to div or another suitable component
    }
  };

  const handleMandatoryChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const handleRemoveClick = () => {
    setSelectedFile(null);
  };
  const handleSaveClick = async () => {
    if (Props?.audioObj) {
      Props?.audioObj.pause();
    }
    const formData = new FormData();
    formData.append("stepTitle", title);
    formData.append("stepDescription", description);
    formData.append("optional", `${isChecked}`);
    let deleteMedia = true;
    if (editStepData?.workflowStepDto?.id || editStepData?.id) {
      if (selectedFile) {
        let mb =  500;
        if(process.env.REACT_APP_OPVA_ENV_SUFFIX=== "DEV") {
          mb = 10;
        }
        const MAX_VIDEO_SIZE = mb * 1024 * 1024;
        if (selectedFile.size >= MAX_VIDEO_SIZE) {
          handleAlertBar("error", `The file size should be less than ${mb}MB`);
        }
        if (selectedFile?.url || selectedFile?.titleUrl) {
          deleteMedia = false;
          formData.append(
            "multipartFile",
            new Blob([JSON.stringify(selectedFile)])
          );
        } else {
          deleteMedia = true;
          formData.append("multipartFile", selectedFile);
        }
      }
      const urlParams = {
        workflowId: Props?.workflowData?.id || 0,
        versionId: Props?.versionData?.id || 0,
        stepId: editStepData?.id || 0,
      };
      const queryParams = {
        deleteMedia: deleteMedia,
      };
      await httpPut(
        updateURLParams(OPTIVAL_CONSTANTS.API_URLS.UPDATE_STEP, urlParams),
        formData,
        queryParams
      ).then(
        (response) => {
          if (response && response.data && response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            console.log("edit step response-->", response.data);
            setUploadedData((prevData) => {
              const existingIndex = prevData.findIndex(
                (item) =>
                  (item?.workflowStepDto?.id || item?.id) ===
                  response?.data?.workflowStepDto?.id
              );
              if (existingIndex !== -1) {
                const newData = [...prevData];
                newData[existingIndex] = response.data?.workflowStepDto
                  ? response.data?.workflowStepDto
                  : response.data;
                return newData;
              } else {
                return [...prevData, response.data];
              }
            });
            setSelectedFile(null);
            setTitle("");
            setDescription("");
            setIsChecked(true);
            setEditStepData("");
            handleAlertBar("success", t("opva.stepUpdatedMsg"));
          }
        },
        (error) => {
          handleAlertBar(
            "error",
            error.message || t("opva.serviceUnavailable")
          );
        }
      );
    } else {
      if (selectedFile) {
        let mb =  500;
        console.log(process.env.REACT_APP_OPVA_ENV_SUFFIX)
        if(process.env.REACT_APP_OPVA_ENV_SUFFIX=== "DEV") {
          mb = 10;
        }
        const MAX_VIDEO_SIZE = mb * 1024 * 1024;
        if (selectedFile.size >= MAX_VIDEO_SIZE) {
          handleAlertBar("error", `The file size should be less than ${mb}MB`);
        }
        formData.append("multipartFile", selectedFile);
      }
      await httpPost(
        `${OPTIVAL_CONSTANTS.API_URLS.CREATE_WORKFLOW_STEP}/${Props?.workflowData?.id}/${Props?.versionData?.id}`,
        formData
      ).then(
        (response) => {
          if (response && response.data && response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            console.log("step response-->", response);
            setUploadedData((prevData) => [...prevData, response.data]);
            setSelectedFile(null);
            setTitle("");
            setDescription("");
            setIsChecked(true);
          }
        },
        (error) => {
          // handleAlertBar(
          //   "error",
          //   error.message || t("opva.serviceUnavailable")
          // );
        }
      );
    }
  };
  useEffect(() => {
    setUploadedData(Props.initialStepData);
  }, [Props.initialStepData]);

  useEffect(() => {
    if (Props?.editStepDetail?.title) {
      setEditStepData(Props?.editStepDetail);
      setSelectedFile(
        Props?.editStepDetail?.mediaItemDto
          ? Props?.editStepDetail?.mediaItemDto
          : Props?.editStepDetail?.mediaItems
          ? Props?.editStepDetail?.mediaItems[0]
          : Props?.editStepDetail?.titleUrl
          ? Props?.editStepDetail
          : null
      );
      setDescription(
        Props?.tabValue === CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
          ? Props?.editStepDetail?.description
          : Props?.editStepDetail?.translatedDescriptions &&
            Props?.editStepDetail.translatedDescriptions[Props?.tabValue]
          ? Props?.editStepDetail.translatedDescriptions[Props?.tabValue]
          : Props?.editStepDetail?.description
      );
      setTitle(
        Props?.tabValue === CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
          ? Props?.editStepDetail.title
          : Props?.editStepDetail?.translatedTitles &&
            Props?.editStepDetail.translatedTitles[Props?.tabValue]
          ? Props?.editStepDetail.translatedTitles[Props?.tabValue]
          : Props?.editStepDetail?.title
      );
      setIsChecked(Props.editStepDetail?.optional);
    }
    if (Props?.deleteStepDetail.length) {
      setUploadedData(Props?.deleteStepDetail);
    }
  }, [Props.editStepDetail, Props?.deleteStepDetail]);

  useEffect(() => {
    const stepDetailData = {
      uploadedData,
      selectedFile,
      title,
      description,
      isChecked,
    };
    Props.StepTwoData(stepDetailData);
  }, [uploadedData, selectedFile, title, description, isChecked]);

  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const renderCloseIcon = () => (
    <CloseIcon
      color="primary"
      onClick={() => setSelectedFile(null)}
      style={{
        position: "absolute",
        top: "5px",
        right: "5px",
        zIndex: "1",
      }}
    />
  );
  return (
    <div className="bodyContainer">
      {!Props.isUpdated ? (
        <Typography variant="body1">{CREATE_WF_CONSTANTS.ADD_STEPS}</Typography>
      ) : (
        <div></div>
      )}
      <div>
        <Grid container spacing={0}>
          {Props.isUpdated ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="UploadedContainer">
                    {!selectedFile && (
                      <div id="uplodedContent">
                        <div
                          className="uploadimageContainer"
                          style={{ zIndex: 0 }}
                        >
                          {" "}
                          <input
                            accept="image/*,application/pdf,video/*"
                            style={{ display: "none" }}
                            id="file-upload"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <label
                            htmlFor="file-upload"
                            className="file-upload-icon"
                          >
                            <FileUploadOutlinedIcon color="primary" />
                          </label>
                        </div>

                        <div style={{ zIndex: 2 }}>
                          {" "}
                          <input
                            accept="image/*,application/pdf,video/*"
                            style={{ display: "none" }}
                            id="file-upload"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <label htmlFor="file-upload">
                            <Link
                              variant="bodyNormal"
                              underline="none"
                              className="me-1"
                            >
                              <b>{t("opva.clickHere")}</b>
                            </Link>
                            <span>{t("opva.uploadDropMedia")}</span>
                          </label>
                        </div>
                      </div>
                    )}
                    {selectedFile && (
                      <div
                        id={
                          selectedFile?.type !== "application/pdf"
                            ? "uplodedContentWithImage"
                            : "uplodedContentForPdf"
                        }
                      >
                        {selectedFile?.type !== "application/pdf" &&
                          renderCloseIcon()}
                        {selectedFile?.type !== "application/pdf" && (
                          <>
                            <Card
                              sx={{
                                position: "absolute",
                                zIndex: 0,
                                width: "100%",
                                maxWidth: "354px",
                                height: "228px",
                              }}
                            >
                              <CardMedia
                                component={getFileTypeComponent(
                                  selectedFile.type
                                )}
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                image={
                                  selectedFile?.url
                                    ? selectedFile?.url
                                    : previewUrl
                                }
                              />
                            </Card>
                            <div
                              className="uploadimageContainer"
                              style={{ zIndex: 0 }}
                            >
                              <div>
                                {" "}
                                <input
                                  accept="image/*,application/pdf,video/*"
                                  style={{ display: "none" }}
                                  id="file-upload"
                                  type="file"
                                  onChange={handleFileChange}
                                />
                                <label
                                  htmlFor="file-upload"
                                  className="file-upload-icon"
                                >
                                  <FileUploadOutlinedIcon color="primary" />
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                        {selectedFile?.type === "application/pdf" && (
                          <Chip
                            color="primary"
                            sx={{ minWidth: "auto", maxWidth: "250px" }}
                            label={selectedFile.name}
                            title={selectedFile.name}
                            onDelete={handleRemoveClick}
                          />
                        )}
                        <div style={{ zIndex: 2 }}>
                          {" "}
                          <input
                            accept="image/*,application/pdf,video/*"
                            style={{ display: "none" }}
                            id="file-upload"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <label htmlFor="file-upload">
                            <Link
                              variant="bodyNormal"
                              underline="none"
                              className="me-1"
                            >
                              <b>{t("opva.clickHere")}</b>
                            </Link>
                            <span>{t("opva.uploadDropMedia")}</span>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ marginLeft: "25px" }}>
                    {!errorMsg && (
                      <Typography variant="bodyMedium">
                        {t("opva.uploadPdfImageVideo")}
                      </Typography>
                    )}
                    {errorMsg && (
                      <Typography variant="body1" color="error">
                        {`Error: ${errorMsg}`}
                      </Typography>
                    )}
                  </div>
                  {/* {!(isMediumScreen || isSmallScreen) && (
                    <div style={{ marginLeft: "25px", marginBottom: "25px" }}>
                      <MyButton
                        size="large"
                        variant="contained"
                        label={CREATE_WF_CONSTANTS.SAVE}
                        onClick={handleSaveClick}
                      />
                    </div>
                  )} */}
                </div>
              </div>
            </Grid>
          ) : (
            <div></div>
          )}

          {!Props.isUpdated ? (
            <Grid item xs={12} sm={12} md lg>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="UploadedContainer"
                    onDragOver={handleUploadBoxDragOver}
                    onDrop={handleUploadBoxDrop}
                  >
                    {!selectedFile && (
                      <div id="uplodedContent">
                        <div
                          className="uploadimageContainer"
                          style={{ zIndex: 0 }}
                        >
                          {" "}
                          <input
                            accept="image/*,application/pdf,video/*"
                            style={{ display: "none" }}
                            id="file-upload"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <label
                            htmlFor="file-upload"
                            className="file-upload-icon"
                          >
                            <FileUploadOutlinedIcon color="primary" />
                          </label>
                        </div>

                        <div style={{ zIndex: 2 }}>
                          {" "}
                          <input
                            accept="image/*,application/pdf,video/*"
                            style={{ display: "none" }}
                            id="file-upload"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <label htmlFor="file-upload">
                            <Link
                              variant="bodyNormal"
                              underline="none"
                              className="me-1"
                            >
                              <b>{t("opva.clickHere")}</b>
                            </Link>
                            <span>{t("opva.uploadDropMedia")}</span>
                          </label>
                        </div>
                      </div>
                    )}
                    {selectedFile && (
                      <div
                        id={
                          selectedFile?.type !== "application/pdf"
                            ? "uplodedContentWithImage"
                            : "uplodedContentForPdf"
                        }
                      >
                        {selectedFile?.type !== "application/pdf" &&
                          renderCloseIcon()}
                        {selectedFile?.type !== "application/pdf" && (
                          <>
                            <Card
                              sx={{
                                position: "absolute",
                                zIndex: 0,
                                width: "100%",
                                maxWidth: "354px",
                                height: "228px",
                              }}
                            >
                              <CardMedia
                                component={getFileTypeComponent(
                                  selectedFile.type
                                )}
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                image={
                                  selectedFile?.url
                                    ? selectedFile?.url
                                    : previewUrl
                                }
                              />
                            </Card>
                            <div
                              className="uploadimageContainer"
                              style={{ zIndex: 0 }}
                            >
                              <div>
                                {" "}
                                <input
                                  accept="image/*,application/pdf,video/*"
                                  style={{ display: "none" }}
                                  id="file-upload"
                                  type="file"
                                  onChange={handleFileChange}
                                />
                                <label
                                  htmlFor="file-upload"
                                  className="file-upload-icon"
                                >
                                  <FileUploadOutlinedIcon color="primary" />
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                        {selectedFile?.type === "application/pdf" && (
                          <Chip
                            color="primary"
                            sx={{ minWidth: "auto", maxWidth: "250px" }}
                            label={selectedFile.name}
                            title={selectedFile.name}
                            onDelete={handleRemoveClick}
                          />
                        )}
                        <div style={{ zIndex: 2 }}>
                          {" "}
                          <input
                            accept="image/*,application/pdf,video/*"
                            style={{ display: "none" }}
                            id="file-upload"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <label htmlFor="file-upload">
                            <Link
                              variant="bodyNormal"
                              underline="none"
                              className="me-1"
                            >
                              <b>{t("opva.clickHere")}</b>
                            </Link>
                            <span>{t("opva.uploadDropMedia")}</span>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ marginLeft: "25px" }}>
                    {!errorMsg && (
                      <Typography variant="bodyMedium">
                        {t("opva.uploadPdfImageVideo")}
                      </Typography>
                    )}
                    {errorMsg && (
                      <Typography variant="body1" color="error">
                        {`Error: ${errorMsg}`}
                      </Typography>
                    )}
                  </div>
                  {!(isMediumScreen || isSmallScreen) && (
                    <div className="text-center m-3">
                      <Button
                        variant="contained"
                        className="w-75 py-3"
                        onClick={handleSaveClick}
                        disabled={title === "" || description === ""}
                      >
                        {t("opva.save")}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          ) : (
            <div></div>
          )}
          {Props.isUpdated ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "25px",
                }}
              >
                <div>
                  <Box>
                    <Typography
                      variant="h6"
                      style={{ marginBottom: 12, marginTop: 20 }}
                    >
                      {t("opva.title")}
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <TextField
                        variant="outlined"
                        placeholder={t("opva.enterTitle")}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{
                          width: "320px",
                          "& .MuiInputBase-input": {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          } }}
                      />
                      <Link
                        component="button"
                        className="ms-2"
                        title="Play Audio"
                        onClick={(e) => Props?.getAudioPlayURL(title)}
                        disabled={!title}
                      >
                        <PlayCircleOutlineIcon
                          color={!title ? "disabled" : "primary"}
                        />
                      </Link>
                    </div>
                  </Box>
                </div>

                {(isMediumScreen || isSmallScreen) && (
                  <div className="text-center m-3">
                    <Button
                      variant="contained"
                      className="w-75 py-3"
                      onClick={handleSaveClick}
                      disabled={title === "" || description === ""}
                    >
                      {t("opva.save")}
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          ) : (
            <div></div>
          )}

          {Props.isUpdated ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box marginTop={"20px"} style={{ marginLeft: "25px" }}>
                <Typography variant="h6" style={{ marginBottom: 12 }}>
                  {t("opva.description")}
                </Typography>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder={t("opva.enterDescription")}
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    sx={{
                      width: "320px",
                      "& .MuiInputBase-input": {
                        // overflow: "hidden",
                        textOverflow: "ellipsis",
                        // whiteSpace: "nowrap"
                      } }}
                  />
                  <Link
                    component="button"
                    className="ms-2"
                    title="Play Audio"
                    onClick={(e) => Props?.getAudioPlayURL(description)}
                    disabled={!description}
                  >
                    <PlayCircleOutlineIcon
                      color={!description ? "disabled" : "primary"}
                    />
                  </Link>
                </div>
              </Box>
            </Grid>
          ) : (
            <div></div>
          )}
          {Props.isUpdated ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box style={{ marginLeft: "25px" }}>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  {t("opva.isMandatoryStep")}
                </Typography>
              </Box>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleMandatoryChange}
                        style={{ marginLeft: "25px" }}
                      />
                    }
                    label={t("opva.yes")}
                  />
                </FormGroup>
              </Box>{" "}
            </Grid>
          ) : (
            <div></div>
          )}

          {!Props.isUpdated ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <Grid item xs={12} sm={12} md lg>
                  <Box>
                    <Typography variant="h6" style={{ marginBottom: 12 }}>
                      {t("opva.title")}
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <TextField
                        variant="outlined"
                        placeholder={t("opva.enterTitle")}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{
                          width: "320px",
                          "& .MuiInputBase-input": {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          } }}
                      />
                      <Link
                        component="button"
                        className="ms-2"
                        title="Play Audio"
                        onClick={(e) => Props?.getAudioPlayURL(title)}
                        disabled={!title}
                      >
                        <PlayCircleOutlineIcon
                          color={!title ? "disabled" : "primary"}
                        />
                      </Link>
                    </div>
                  </Box>
                </Grid>
              </div>

              <Grid item xs={12} sm={12} md lg>
                <Box marginTop={"20px"}>
                  <Typography variant="h6" style={{ marginBottom: 12 }}>
                    {t("opva.description")}
                  </Typography>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder={t("opva.enterDescription")}
                      multiline
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      sx={{
                        width: "320px",
                        "& .MuiInputBase-input": {
                          // overflow: "hidden",
                          textOverflow: "ellipsis",
                          // whiteSpace: "nowrap",
                        } }}
                    />
                    <Link
                      component="button"
                      className="ms-2"
                      title="Play Audio"
                      onClick={(e) => Props?.getAudioPlayURL(description)}
                      disabled={!description}
                    >
                      <PlayCircleOutlineIcon
                        color={!description ? "disabled" : "primary"}
                      />
                    </Link>
                  </div>
                </Box>
              </Grid>
              <Box>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  {t("opva.isMandatoryStep")}
                </Typography>
              </Box>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleMandatoryChange}
                      />
                    }
                    label={t("opva.yes")}
                  />
                </FormGroup>
              </Box>
              {(isMediumScreen || isSmallScreen) && (
                <div className="text-center m-3">
                  <Button
                    variant="contained"
                    className="w-75 py-3"
                    onClick={handleSaveClick}
                    disabled={title === "" || description === ""}
                  >
                    {t("opva.save")}
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}

          {!Props.isUpdated ? (
            <Grid item xs={12} sm={12} md lg={0.3} className="ms-4">
              <div className="Dividers">
                <Divider variant="middle" />
              </div>
            </Grid>
          ) : (
            <div></div>
          )}

          {/* <Grid item xs={12} sm={0.3} md={0.3} lg={0.3}>
            <div className="Dividers">
              <Divider variant="middle" />
            </div>
          </Grid> */}
          {!Props.isUpdated ? (
            <Grid item xs={12} sm={12} md lg>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="bodyMedium" sx={{ marginBottom: 3 }}>
                  {t("opva.addedSteps")}
                </Typography>
                {uploadedData.length === 0 ? (
                  <Typography variant="bodyNormal">
                    {t("opva.startAddingStepsToWorkflow")}
                  </Typography>
                ) : (
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "380px",
                      minWidth: "500px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {uploadedData.map((data, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 20,
                          }}
                          className={`item ${
                            data === Props?.draggingItem ? "dragging" : ""
                          }`}
                          draggable="true"
                          onDragStart={(e) => Props?.handleDragStart(e, data)}
                          onDragEnd={Props?.handleDragEnd}
                          onDragOver={Props?.handleDragOver}
                          onDrop={(e) => Props?.handleDrop(e, data)}
                        >
                          <div className="video-image">
                            {Props.renderFilePreview(index)}
                          </div>

                          <div style={{ marginLeft: "20px" }}>
                            <Typography className="content-number">
                              {index + 1}
                            </Typography>
                          </div>
                          <div
                            style={{
                              marginLeft: "20px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              className="ellipsis-text"
                              style={{ width: "120px" }}
                            >
                              <Typography
                                variant="bodyNormal"
                                title={
                                  data.workflowStepDto?.title
                                    ? `${data.workflowStepDto?.title}`
                                    : data?.title
                                }
                              >
                                {data.workflowStepDto?.title
                                  ? `${data.workflowStepDto?.title}`
                                  : data?.title}
                              </Typography>
                            </div>
                          </div>

                          <div className="ms-3">
                            <Link
                              component="button"
                              title="Delete"
                              onClick={(e) => Props.deleteIconClick(index)}
                            >
                              <DeleteForeverIcon color="error" />
                            </Link>
                          </div>
                          <div className="ms-3">
                            <Link
                              component="button"
                              title="Edit"
                              onClick={(e) => Props.editIconClick(index)}
                            >
                              <EditIcon />
                            </Link>
                          </div>
                          <div className="ms-3">
                            <Link
                              component="button"
                              title="Sort"
                              onClick={(e) => Props.reorderIconClick(index)}
                            >
                              <MultipleStopIcon
                                sx={{ transform: "rotate(90deg)" }}
                              />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          ) : (
            <div></div>
          )}
        </Grid>
      </div>
    </div>
  );
};
