import { Button, Divider, Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./OnboardOrganisation.scss";
import { PlansBillingPriod } from "./plan-billing-period/PlansBillingPeriod";
import OrganizationForm from "../../../shared/components/organization-form/OrganizationForm";
import {
  OnBoardOrganisationFormData,
  PlanListInterface,
} from "../models/OnboardOrganisationData";
import { useAPI } from "../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { DeleteForever } from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useLocation, useNavigate } from "react-router";
import { ONBOARD_ORGANISATION_CONSTANTS } from "../models/OnboardOrganisationConstants";
import dayjs from "dayjs";
import { updateURLParams } from "../../../shared/utils/Utils";
import { useTranslation } from "react-i18next";

export const OnboardOrganisation = (Props: any) => {
  const { httpPost, httpPut, httpGet, handleAlertBar } = useAPI();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const onBoardOrganisationdata = location?.state?.rowData;
  const signupRequestId = queryParams.get("signupRequestId");
  const id = queryParams.get("id");
  const todayDate = new Date();
  const end = new Date();
  const endDate = end.setFullYear(end.getFullYear() + 1);
  const { t } = useTranslation();
  const [planList, setPlanList] = useState<[PlanListInterface]>([
    {
      id: 0,
      name: "",
      description: "",
      cost: 0,
    },
  ]);
  const [formData, setFormData] = useState<OnBoardOrganisationFormData>({
    organizationName: "",
    addressLine: "",
    addressLine2: "",
    state: "",
    country: "",
    zipCode: "",
    email: "",
    mobileNumber: "",
    industrySector: "",
    opva: "",
    plan: "",
    cost: "",
    numberOfUserSeats: "",
    startDate: dayjs(todayDate),
    endDate: dayjs(endDate),
    orgPreferredLanguages: [
      {
        actualDisplayName: "English",
        aws_language_code: "en",
        display_name: "English",
        gcp_language_code: "en-IN",
        id: 1,
        language_name: "ENGLISH",
      },
    ],
    errors: {
      organizationName: false,
      addressLine: false,
      addressLine2: false,
      state: false,
      country: false,
      zipCode: false,
      email: false,
      mobileNumber: false,
      industrySector: false,
      opva: false,
      plan: false,
      cost: false,
      numberOfUserSeats: false,
      startDate: false,
      endDate: false,
      orgPreferredLanguages: false,
    },
  });
  const isFormValid = () => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    const isMobileValid = /^((\+\d{1,3}-)?\d{3}-\d{3}-\d{4}|\d{10})$/.test(
      formData.mobileNumber
    );
    const isIndustrySectorValid = /^[A-Za-z]+$/.test(formData.industrySector);
    const isNumberOfUserSeatsValid = /^\d+$/.test(formData.numberOfUserSeats);
    const isCostValid = /^\d+$/.test(formData.cost);

    return (
      formData.organizationName.trim() !== "" &&
      formData.addressLine.trim() !== "" &&
      formData.plan &&
      isEmailValid &&
      isMobileValid &&
      isIndustrySectorValid &&
      isNumberOfUserSeatsValid &&
      isCostValid
    );
  };

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    let isError = false;

    if (name === "email") {
      isError = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    } else if (name === "mobileNumber") {
      isError =
        !/^(\+?\d{0,3})?\d{0,3}\d{0,4}$/.test(value) || value.length === 0;
    } else if (name === "industrySector") {
      isError = !/^[A-Za-z]+$/.test(value);
    } else if (name === "numberOfUserSeats") {
      isError = !/^\d+$/.test(value);
    } else if (name === "cost") {
      isError = !/^\d+$/.test(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      errors: { ...prevData.errors, [name]: isError },
    }));
  };

  const onFormSubmit = () => {
    if (isFormValid()) {
      setFormData((prevData) => ({ ...prevData, success: true }));
      if (Props?.isEdit) {
        updateOnboardorganisation(formData);
      } else {
        submitOnboardorganisation(formData);
      }
    } else {
      console.log("Form has errors. Please fill in all required fields.");
    }
  };

  const getOrganisationPlan = () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_ORGANISATION_PLAN).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          setPlanList(response.data);
        }
      },
      (error) => {
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };

  const submitOnboardorganisation = async (
    formData: OnBoardOrganisationFormData
  ) => {
    const languageLists = formData?.orgPreferredLanguages
      ?.map((e: any) => e.language_name)
      .filter((e: string) => e !== "ENGLISH");
    const payload = {
      name: formData?.organizationName,
      address: formData?.addressLine,
      address2: formData?.addressLine2,
      state: formData?.state,
      country: formData?.country,
      zipCode: formData?.zipCode,
      mail: formData?.email,
      phone: formData?.mobileNumber,
      industrySector: formData?.industrySector,
      purpose: formData?.opva,
      organizationPlans: {
        planId: formData?.plan,
        cost: +formData?.cost,
        numberOfUserSeats: +formData?.numberOfUserSeats,
        startDate: formData?.startDate?.toJSON(),
        endDate: formData?.endDate?.toJSON(),
      },
      languages: languageLists,
    };
    const queryParam = {
      signupRequestId: signupRequestId,
    };
    await httpPost(
      OPTIVAL_CONSTANTS.API_URLS.ONBOARD_ORGANISATION,
      payload,
      queryParam
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            navigate("/manage-organisation");
            handleAlertBar(
              "success",
              t("opva.onboardOrganisationSuccessMsg")
            );
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const updateOnboardorganisation = async (
    formData: OnBoardOrganisationFormData
  ) => {
    const languageLists = formData?.orgPreferredLanguages
      ?.map((e: any) => e.language_name)
      .filter((e: string) => e !== "ENGLISH");
    const payload = {
      name: formData?.organizationName,
      address: formData?.addressLine,
      address2: formData?.addressLine2,
      state: formData?.state,
      country: formData?.country,
      zipCode: formData?.zipCode,
      mail: formData?.email,
      phone: formData?.mobileNumber,
      industrySector: formData?.industrySector,
      purpose: formData?.opva,
      organizationPlans: {
        planId: formData?.plan,
        cost: +formData?.cost,
        numberOfUserSeats: +formData?.numberOfUserSeats,
        startDate: formData?.startDate?.toJSON(),
        endDate: formData?.endDate?.toJSON(),
      },
      languages: languageLists,
    };
    const queryParam = {
      organizationId: id,
    };
    const urlParams = {
      organizationId: id,
    };
    await httpPut(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.UPDATE_ORGANISATION,
        urlParams
      ),
      payload,
      queryParam
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            navigate("/manage-organisation");
            handleAlertBar(
              "success",
              t("opva.updateOrganisationSuccessMsg")
            );
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const backToManageOrganisation = () => {
    navigate("/manage-organisation");
  };

  useEffect(() => {
    getOrganisationPlan();
    if (onBoardOrganisationdata) {
      setFormData({
        organizationName: onBoardOrganisationdata?.name,
        addressLine: onBoardOrganisationdata?.address,
        addressLine2: onBoardOrganisationdata?.address2,
        state: onBoardOrganisationdata?.state,
        country: onBoardOrganisationdata?.country,
        zipCode: onBoardOrganisationdata?.zipCode,
        email: onBoardOrganisationdata?.mail,
        mobileNumber: onBoardOrganisationdata?.phone,
        industrySector: onBoardOrganisationdata?.industrySector,
        opva: onBoardOrganisationdata?.purpose,
        plan: onBoardOrganisationdata?.organizationPlans?.plans?.id,
        cost: onBoardOrganisationdata?.organizationPlans?.cost,
        numberOfUserSeats:
          onBoardOrganisationdata?.organizationPlans?.numberOfUserSeats,
        startDate: onBoardOrganisationdata?.organizationPlans?.startDate
          ? dayjs(onBoardOrganisationdata?.organizationPlans?.startDate)
          : dayjs(todayDate),
        endDate: onBoardOrganisationdata?.organizationPlans?.endDate
          ? dayjs(onBoardOrganisationdata?.organizationPlans?.endDate)
          : dayjs(endDate),
        orgPreferredLanguages: onBoardOrganisationdata?.orgPreferredLanguages,
        errors: {
          organizationName: false,
          addressLine: false,
          addressLine2: false,
          state: false,
          country: false,
          zipCode: false,
          email: false,
          mobileNumber: false,
          industrySector: false,
          opva: false,
          plan: false,
          cost: false,
          numberOfUserSeats: false,
          startDate: false,
          endDate: false,
          orgPreferredLanguages: false,
        },
      });
    } else {
      setFormData({
        organizationName: "",
        addressLine: "",
        addressLine2: "",
        state: "",
        country: "",
        zipCode: "",
        email: "",
        mobileNumber: "",
        industrySector: "",
        opva: "",
        plan: "",
        cost: "",
        numberOfUserSeats: "",
        startDate: dayjs(todayDate),
        endDate: dayjs(endDate),
        orgPreferredLanguages: [
          {
            actualDisplayName: "English",
            aws_language_code: "en",
            display_name: "English",
            gcp_language_code: "en-IN",
            id: 1,
            language_name: "ENGLISH",
          },
        ],
        errors: {
          organizationName: false,
          addressLine: false,
          addressLine2: false,
          state: false,
          country: false,
          zipCode: false,
          email: false,
          mobileNumber: false,
          industrySector: false,
          opva: false,
          plan: false,
          cost: false,
          numberOfUserSeats: false,
          startDate: false,
          endDate: false,
          orgPreferredLanguages: false,
        },
      });
    }
  }, [onBoardOrganisationdata]);

  const onFormReset = () => {
    setFormData({
      organizationName: "",
      addressLine: "",
      addressLine2: "",
      state: "",
      country: "",
      zipCode: "",
      email: "",
      mobileNumber: "",
      industrySector: "",
      opva: "",
      plan: "",
      cost: "",
      numberOfUserSeats: "",
      startDate: dayjs(todayDate),
      endDate: dayjs(endDate),
      orgPreferredLanguages: [
        {
          actualDisplayName: "English",
          aws_language_code: "en",
          display_name: "English",
          gcp_language_code: "en-IN",
          id: 1,
          language_name: "ENGLISH",
        },
      ],
      errors: {
        organizationName: false,
        addressLine: false,
        addressLine2: false,
        state: false,
        country: false,
        zipCode: false,
        email: false,
        mobileNumber: false,
        industrySector: false,
        opva: false,
        plan: false,
        cost: false,
        numberOfUserSeats: false,
        startDate: false,
        endDate: false,
        orgPreferredLanguages: false,
      },
    });
  };
  return (
    <div>
      <div className="onboard-organisation">
        <Grid container>
          <Grid item xs={6} md={12}>
            {Props?.isEdit ? (
              <Grid container>
                <Grid item xs={1} md={0.5}>
                  <Link
                    component="button"
                    title={t("opva.backToWorkflow")}
                    className="me-2"
                    onClick={backToManageOrganisation}
                  >
                    <KeyboardArrowLeftIcon fontSize="large" />
                  </Link>
                </Grid>
                <Grid item xs={6} md={10.5}>
                  <Typography variant="h3" className="mb-0">
                   {t("opva.editOrganisation")}
                  </Typography>
                </Grid>
                {/* <Grid item xs={6} md={1}>
                  <Link component="button" title="Delete">
                    <DeleteForever className="DeleteIcon" />
                  </Link>
                </Grid> */}
              </Grid>
            ) : (
              <Typography variant="h3" className="mb-0">
                {t("opva.onboardOrganisation")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="m-4 onboard-organisation-content">
        <Grid container spacing={6}>
          <Grid item xs={12} md={5}>
            <Typography variant="h6" className="mb-3">
              {t("opva.organisationDetails")}
            </Typography>
            <OrganizationForm
              handleSubmit={onFormSubmit}
              formData={formData}
              handleInputChange={handleFormChange}
              callFrom="onBoardOrganisation"
            />
          </Grid>
          <Grid item sm={12} xs={12} md={0.2} lg={0.2} className="m-2">
            <div className="divider">
              <Divider variant="middle" orientation="vertical" flexItem />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="mb-3">
              {t("opva.plans&BillingPeriod")}
            </Typography>
            <PlansBillingPriod
              formData={formData}
              handleFormChange={handleFormChange}
              planList={planList}
            />
          </Grid>
        </Grid>
      </div>
      <div className="m-4 onboard-organisation-content">
        <Grid container className="mt-4">
          <Grid item xs={6} md={2}>
            <Button variant="outlined" onClick={onFormReset}>
              {t("opva.reset")}
            </Button>
          </Grid>
          <Grid item xs md>
            {Props?.isEdit ? (
              <Button
                size="large"
                variant="contained"
                sx={{ color: "#FFFFFFCC" }}
                onClick={onFormSubmit}
                disabled={!isFormValid()}
              >
                {t("opva.updateAccount")}
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                sx={{ color: "#FFFFFFCC" }}
                onClick={onFormSubmit}
                disabled={!isFormValid()}
              >
                {t("opva.setupAccount")}
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
