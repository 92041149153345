import {
  Alert,
  AlertTitle,
  IconButton,
  Snackbar,
  SnackbarOrigin,
} from "@mui/material";
import { SyntheticEvent, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { AlertBarContext } from "../../AlertBarContext";

export const AlertBar = () => {
  const { alertBarState, closeAlertBar } = useContext(AlertBarContext);
  const snackBarPostion: SnackbarOrigin = {
    vertical: "top",
    horizontal: "right",
  };
  const { open, type, message, title } = alertBarState;

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeAlertBar();
  };

  const actionItems = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={snackBarPostion}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      action={actionItems}
    >
      <Alert severity={type} variant="filled" onClose={handleClose}>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {message}
      </Alert>
    </Snackbar>
  );
};
