import { Box, Grid, Typography } from "@mui/material";
import "./PlansAndBuilding.scss";
import { useAPI } from "../../../shared/services/api/API";
import { useEffect, useState } from "react";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { useTranslation } from "react-i18next";

export const PlansAndBilling = () => {
  const { httpGet } = useAPI();
  const { checkRole } = useAPI();
  const [planData, setPlanData] = useState<any>({});
  const { t } = useTranslation();

  const fetchOrganizationData = async () => {
    await httpGet(OPTIVAL_CONSTANTS.API_URLS.ORGANIZATION_DATA).then(
      (response) => {
        if (response) {
          setPlanData(response.data);
        }
      },
      (error) => {}
    );
  };

  const extractDate = (dateString: string) => {
    const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  useEffect(() => {
    fetchOrganizationData();
  }, []);

  return (
    <div className="plan-bill-container pt-2 pb-4 m-4">
      {checkRole([
        OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
      ]) && (
        <div className="pb-5">
          <div className="d-flex justify-content-between mb-4">
            <Typography variant="h3">{t("opva.plans&Billing")}</Typography>
          </div>
          <Grid container>
            <Grid item xs={12} sm={6} md={2}></Grid>
            <Grid item xs={12} sm={5} md={10}>
              <Grid container spacing={6} className="plan-details mx-3 ">
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="labelSmall"
                    className="label-grey fw-bold"
                  >
                    {t("opva.planName")}
                  </Typography>
                  <Typography variant="h6">
                    {planData?.organizationPlans?.plans?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="labelSmall"
                    className="label-grey fw-bold"
                  >
                   {t("opva.noOfUsers")}
                  </Typography>
                  <Typography variant="h6">
                    {planData?.organizationPlans?.numberOfUserSeats}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}></Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="labelSmall"
                    className="label-grey fw-bold"
                  >
                   {t("opva.startDate")}
                  </Typography>
                  <Typography variant="h6">
                    {extractDate(planData?.organizationPlans?.startDate)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="labelSmall"
                    className="label-grey fw-bold"
                  >
                    {t("opva.endDate")}
                  </Typography>
                  <Typography variant="h6">
                    {extractDate(planData?.organizationPlans?.endDate)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>

    // <div className="plans-billing-container">
    //   {/* <Box display="flex" alignItems="center" justifyContent="center">
    //     <Typography variant="bodyNormal">Pay Monthly</Typography>
    //     <CustomSwitch />
    //     <Typography variant="bodyNormal">Pay Yearly</Typography>
    //     <img
    //       src="images/Arrow_2.svg"
    //       alt="Arrow"
    //       style={{
    //         width: "91.874px",
    //         height: "55.337px",
    //       }}
    //     />
    //     <div className="text-custom">Save 25%</div>
    //   </Box>
    //   <Box className="plan-container">
    //     <Box className="plan-container-card1">
    //       <Box m="40px">
    //         <Typography variant="h4">Basic</Typography>
    //         <Typography variant="bodyNormal" pt="11px" mb="20px">
    //           Ideal for individuals who need quick access to basic features.
    //         </Typography>
    //         <Box mb="24px">
    //           <Typography variant="display1" className="header">
    //             $8
    //           </Typography>
    //         </Box>
    //         <MyButton
    //           size="large"
    //           variant="outlined"
    //           label="Downgrade Subscription"
    //           sx={{
    //             width: "100%",
    //           }}
    //         />
    //       </Box>
    //     </Box>
    //     <Box className="plan-container-card2">
    //       <Box m="40px">
    //         <Typography variant="h4" color="var(--White, #FFF)">
    //           Pro
    //         </Typography>
    //         <Typography
    //           variant="bodyNormal"
    //           pt="11px"
    //           mb="20px"
    //           color="var(--Harp, #E6EDEF);"
    //         >
    //           Ideal for individuals who who need advanced features and tools for
    //           client work.
    //         </Typography>
    //         <Box mb="24px">
    //           <Typography variant="display1" className="header">
    //             $14
    //           </Typography>
    //         </Box>
    //         <MyButton
    //           size="large"
    //           variant="outlined"
    //           label="Current Plan"
    //           sx={{
    //             background: "var(--White, #FFF)",
    //             width: "100%",
    //           }}
    //         />
    //       </Box>
    //     </Box>
    //     <Box className="plan-container-card1">
    //       <Box m="40px">
    //         <Typography variant="h4">Enterprise</Typography>
    //         <Typography variant="bodyNormal" pt="11px" mb="20px">
    //           Ideal for businesses who need personalized services and security
    //           for large teams.
    //         </Typography>
    //         <Box>
    //           <Typography variant="h4" mb="54px">
    //             Contact Sales
    //           </Typography>
    //         </Box>
    //         <MyButton
    //           size="large"
    //           variant="outlined"
    //           label="Contact Sales"
    //           sx={{
    //             width: "100%",
    //           }}
    //         />
    //       </Box>
    //     </Box>
    //   </Box> */}
    // </div>
  );
};
