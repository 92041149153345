import { createContext, useEffect, useMemo, useState } from "react";
import { NotificationContextModel } from "../models/NotificationContextModel";
import { useTranslation } from "react-i18next";
import { useAPI } from "../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";

export const NotificationContext = createContext(
  {} as NotificationContextModel
);

export const NotificationProvider = ({ children }: any) => {
  const [haveUnreadNotifications, setHaveUnreadNotifications] = useState<boolean>(false);
  const { httpGet, handleAlertBar } = useAPI();
  const { t } = useTranslation();

  const fetchNotificationData = async () => {
    const queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "sendAt",
      isDescending: true,
    };
    await httpGet(
      OPTIVAL_CONSTANTS.API_URLS.GET_NOTIFICATIONS,
      queryParams
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            setHaveUnreadNotifications(response?.data?.haveUnreadNotifications);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {
      }
    );
  };

  useEffect(() => {
    fetchNotificationData();
  }, [haveUnreadNotifications]);

  const updateHaveUnreadNotifications = (value: boolean) => {
    setHaveUnreadNotifications(value);
  };

  const notificationProviderValues = useMemo(
    () => ({ 
      haveUnreadNotifications , 
      updateHaveUnreadNotifications,
     }),
    [
      haveUnreadNotifications, 
    ]
  );

  return (
    <NotificationContext.Provider value={notificationProviderValues}>
      {children}
    </NotificationContext.Provider>
  );
};
