export const SharedApi = () => {
  const getColorForLevel = (level: string) => {
    switch (level?.toString()) {
      case "1":
        return "#FFE29D";
      case "2":
        return "#B1E3FF";
      case "3":
        return "#BAEDBD";
      case "4":
        return "#C6C7F8";
      default:
        return "inherit";
    }
  };

  return {
    getColorForLevel,
  };
};
