import { t } from "i18next";
import { TableHeadCell } from "../../../shared/models/TableModels";
import { useTranslation } from "react-i18next";

export const GroupManagementConstants = {
  GROUP_MANAGEMENT: "Group Management",
  ADD_NEW_GROUP: "Add New Group",
  NEW_GROUP: "New Group",
  ADD_GROUP: "Add Group",
  EDIT_GROUP: "Edit Group",
  UPDATE_GROUP: "Update Group",
  ADD_USERS: "Add Users",
  ADDED_USERS: "Added Users",
  GROUP_ADDED_SUCCESS_MSG: "New group has been created successfully.",
  GROUP_UPDATED_SUCCESS_MSG: "Group has been updated successfully.",
  NO_DATA_AVAILABLE: "No group(s) data available.",
  NO_SEARCH_DATA_AVAILABLE: "Search group data is not available.",
  NO_USER_DATA_AVAILABLE: "No user(s) data available.",
  NO_ADDER_USER_DATA_AVAILABLE: "No user added here.",
  BULK_USER_HEADER_LIST: [
    "First name*",
    "Last name*",
    "Employee ID",
    "Email*",
    "Phone number",
    "User type*",
    "Department",
    "Location",
    "Role",
  ],
  // GROUP_MGMT_TABLE_HEADER: [
  //   {
  //     id: "groupName",
  //     label: t("opva.groupName"),
  //     width: 20,
  //     sorting: true,
  //     sortingProperty: "name",
  //     position: "left",
  //   },
  //   {
  //     id: "description",
  //     label: t("opva.description"),
  //     width: 20,
  //     sorting: true,
  //     sortingProperty: "description",
  //     position: "left",
  //   },
  //   {
  //     id: "createdOn",
  //     label: t("opva.createdOn"),
  //     width: 20,
  //     sorting: true,
  //     sortingProperty: "createdAt",
  //     position: "left",
  //   },
  //   {
  //     id: "createdBy",
  //     label: t("opva.createdBy"),
  //     width: 20,
  //     sorting: true,
  //     sortingProperty: "createdBy",
  //     position: "left",
  //   },
  //   {
  //     id: "users",
  //     label: t("opva.users1"),
  //     width: 10,
  //     sorting: true,
  //     sortingProperty: "assignedEmployeesCount",
  //     position: "center",
  //   },
  //   {
  //     id: "actions",
  //     label: t("opva.Actions"),
  //     width: 10,
  //     sorting: false,
  //     sortingProperty: "",
  //     position: "center",
  //   },
  // ] as TableHeadCell[],
  // USERS_TABLE_HEADER: [
  //   {
  //     id: "name",
  //     label: t("opva.name"),
  //     width: 20,
  //     sorting: true,
  //     sortingProperty: "firstName",
  //     position: "left",
  //   },
  //   {
  //     id: "employeeID",
  //     label: t("opva.employeeId"),
  //     width: 20,
  //     sorting: true,
  //     sortingProperty: "employeeId",
  //     position: "left",
  //   },
  //   {
  //     id: "emailID",
  //     label: t("opva.emailId"),
  //     width: 20,
  //     sorting: true,
  //     sortingProperty: "mail",
  //     position: "left",
  //   },
  //   {
  //     id: "role",
  //     label: t("opva.roles"),
  //     width: 20,
  //     sorting: true,
  //     sortingProperty: "assignedRoles",
  //     position: "left",
  //   },
  //   {
  //     id: "status",
  //     label: t("opva.status"),
  //     width: 10,
  //     sorting: true,
  //     sortingProperty: "status",
  //     position: "left",
  //   },
  //   {
  //     id: "actions",
  //     label: t("opva.actions"),
  //     width: 10,
  //     sorting: false,
  //     sortingProperty: "",
  //     position: "center",
  //   },
  // ] as TableHeadCell[],
};

export const useGroupManagementUtils = () => {
  const { t } = useTranslation();
  const getGroupManagementHeaders = () => {
    return [
      {
        id: "groupName",
        label: t("opva.groupName"),
        width: 20,
        sorting: true,
        sortingProperty: "name",
        position: "left",
      },
      {
        id: "description",
        label: t("opva.description"),
        width: 20,
        sorting: true,
        sortingProperty: "description",
        position: "left",
      },
      {
        id: "createdOn",
        label: t("opva.createdOn"),
        width: 20,
        sorting: true,
        sortingProperty: "createdAt",
        position: "left",
      },
      {
        id: "createdBy",
        label: t("opva.createdBy"),
        width: 20,
        sorting: true,
        sortingProperty: "createdBy",
        position: "left",
      },
      {
        id: "users",
        label: t("opva.users1"),
        width: 10,
        sorting: true,
        sortingProperty: "assignedEmployeesCount",
        position: "center",
      },
      {
        id: "actions",
        label: t("opva.actions"),
        width: 10,
        sorting: false,
        sortingProperty: "",
        position: "center",
      },
    ] as TableHeadCell[];
  };
  const getUsersTableHeaders = () => {
    return [
      {
        id: "name",
        label: t("opva.name"),
        width: 20,
        sorting: true,
        sortingProperty: "firstName",
        position: "left",
      },
      {
        id: "employeeID",
        label: t("opva.employeeId"),
        width: 20,
        sorting: true,
        sortingProperty: "employeeId",
        position: "left",
      },
      {
        id: "emailID",
        label: t("opva.emailId"),
        width: 20,
        sorting: true,
        sortingProperty: "mail",
        position: "left",
      },
      {
        id: "role",
        label: t("opva.roles"),
        width: 20,
        sorting: true,
        sortingProperty: "assignedRoles",
        position: "left",
      },
      {
        id: "status",
        label: t("opva.status"),
        width: 10,
        sorting: true,
        sortingProperty: "status",
        position: "left",
      },
      {
        id: "actions",
        label: t("opva.actions"),
        width: 10,
        sorting: false,
        sortingProperty: "",
        position: "center",
      },
    ] as TableHeadCell[];
  };
  return { getGroupManagementHeaders, getUsersTableHeaders };
};
