import { createContext, useMemo, useState } from "react";
import { SpinnerContextModel } from "./models/SpinnerContextModel";

export const SpinnerContext = createContext({} as SpinnerContextModel);

export const SpinnerProvider = ({ children }: any) => {
  const [isSpinnerShow, setIsSpinnerShow] = useState(false);

  const toggleSpinner = (show: boolean) => {
    setIsSpinnerShow(show);
  };

  const spinnerProviderValues = useMemo(
    () => ({ isSpinnerShow, toggleSpinner }),
    [isSpinnerShow]
  );

  return (
    <SpinnerContext.Provider value={spinnerProviderValues}>
      {children}
    </SpinnerContext.Provider>
  );
};
