import { Divider, Grid, Typography } from "@mui/material";
import "./RequestDemo.scss";
import { InlineWidget } from "react-calendly";
import { SignupHereLink } from "../../../../shared/components/signup-here-link/SignupHereLink";
import { LoginHereLink } from "../../../../shared/components/login-here-link/LoginHereLink";

const RequestDemo = () => {
  const calendlyPageSettings = {
    hideLandingPageDetails: true,
    hideEventTypeDetails: true,
    hideGdprBanner: true,
  };
  const calendlyPrefillData = {
    date: new Date(),
  };

  return (
    <div className="request-up-container bg-dark-blue">
      <Grid container className="subContainer" sx={{ width: "90%" }}>
        <Grid item xs={12} sm={12} md={2} lg={5}>
          <div className="leftSideContainer">
            <img
              src="images/Logo.jpg"
              width={120}
              height={40}
              title="Optival"
              alt="Optival Logo"
              className="mb-3"
            />
            <Typography variant="bodySmall" className="mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Typography>
            <div className="d-flex justify-content-between">
              <SignupHereLink />
              <LoginHereLink />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={0.01} lg={0.01}>
          <div className="Dividers">
            <Divider variant="fullWidth" flexItem />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant="h3" className="text-center">
            Request a Demo
          </Typography>
          <InlineWidget
            url="https://calendly.com/d/34x-92d-y8f/request-a-demo"
            pageSettings={calendlyPageSettings}
            prefill={calendlyPrefillData}
            styles={{ width: 700, height: 500 }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default RequestDemo;
