import { useTranslation } from "react-i18next";
import { TableHeadCell } from "../../../../shared/models/TableModels";

export const TagManagementConstants = {
  NEW_TAG: "New Tag",
  UPDATE_TAG: "Update Tag",
  ALL_TAGS: "All Tags",
  ADD_TAGS: "Add Tag",
  ADD_BULK_Tags: "Add bulk Tags",
  ADD_BULK_USERS_DOWNLOAD_FORMAT:
    "To add bulk tags, download our format and fill and upload the excel",
  TAG: "Tag",
  DESCRIPTION: "Description",
  CLICK_HERE: "Click here",
  DELETE_TAG: "Delete Tag",
  CONFIRMATION_MSG_DELETE_TAG: "Are you sure want to delete this Tag?",
  UPLOAD_EXCEL_FILE: "to upload or drop excel file",
  DOWNLOAD_FORMAT_HERE: "You can download the format here",
  ADD_TAG_SUCCESS_MSG: "Tag created successfully",
  UPDATED_TAG_SUCCESS_MSG: "Tag details has been updated successfully.",
  DELETE_TAG_SUCCESS_MSG: "Tag details has been deleted successfully.",
  WORKFLOW_USED_OF_THIS_TAG: "Workflow Used in",
  NO_Workflow_Used_AVAILABLE: "No workflow(s) available for this tag.",
  NO_DATA_AVAILABLE: "No tag(s) data available.",
  NO_SEARCH_DATA_AVAILABLE: "Search tag data is not available.",
};
export const useTagManagamentUtil = () => {
  const { t } = useTranslation();
  const getTagManagementHeaders = () => {
    const TAG_MGMT_TABLE_HEADER = [
      {
        id: "name",
        label: t("opva.name"),
        width: 20,
        sorting: true,
        sortingProperty: "name",
        position: "left",
      },
      {
        id: "description",
        label: t("opva.description"),
        width: 20,
        sorting: true,
        sortingProperty: "description",
        position: "left",
      },
      {
        id: "createdAt",
        label: t("opva.createdOn"),
        width: 20,
        sorting: true,
        sortingProperty: "createdAt",
        position: "left",
      },
      {
        id: "createdBy",
        label: t("opva.createdBy"),
        width: 15,
        sorting: true,
        sortingProperty: "createdBy",
        position: "left",
      },
      {
        id: "worflowsUsedCount",
        label: t("opva.workflowsUsedIn"),
        width: 20,
        sorting: true,
        sortingProperty: "worflowsUsedCount",
        position: "center",
      },

      {
        id: "actions",
        label: t("opva.actions"),
        width: 10,
        sorting: false,
        sortingProperty: "actions",
        position: "center",
      },
    ] as TableHeadCell[];
    return TAG_MGMT_TABLE_HEADER;
  };
  return { getTagManagementHeaders };
};
