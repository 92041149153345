import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAPI } from "../../../../../shared/services/api/API";
import { SpinnerContext } from "../../../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import {
  Box,
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Order, TableHeadCell } from "../../../../../shared/models/TableModels";
import { TableLoading } from "../../../../../shared/components/skeleton/table-loading/TableLoading";
import { SharedApi } from "../../../../../shared/services/shared-api/SharedApi";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { useNavigate } from "react-router-dom";
import { SkillsContext } from "../../../contexts/SkillsContext";
import { tableSorting } from "../../../../../shared/utils/Utils";
import RemoveIcon from '@mui/icons-material/Remove';

export const SkillsMatrixList = (Props?: any) => {
  const { t } = useTranslation();
  const { httpPost, handleAlertBar } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const { getColorForLevel } = SharedApi();
  const [displayTableData, setDisplayTableData] = useState<any[]>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const navigate = useNavigate();
  const { updateSkillsFilter } = useContext(SkillsContext);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    Props?.setPage(newPage);
    const startIndex = newPage * Props?.rowsPerPage;
    setDisplayTableData([
      ...Props?.tableData.slice(startIndex, startIndex + Props?.rowsPerPage),
    ]);
  };

  useMemo(() => {
    const propertyType = orderBy === "usersAssigned" ? "number" : "string";
    const sortedList = tableSorting(Props?.tableData, order, orderBy, propertyType);
    Props?.setTableData(sortedList);
    setDisplayTableData(
      sortedList.slice(Props?.page * Props?.rowsPerPage, Props?.page * Props?.rowsPerPage + Props?.rowsPerPage)
    );
  }, [order, orderBy, Props?.page, Props?.tableData]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    Props?.setRowsPerPage(parseInt(event.target.value, 10));
    Props?.setPage(0);
    setDisplayTableData(Props?.tableData.slice(0, parseInt(event.target.value, 10)));
  };

  useEffect(() => {
    Props?.fetchSkillsMatrixData([]);
  }, []);

  const goToWorkflow = (row: any) => {
    navigate('../instrukt', {state: {row}})
  }

  const handleRowRemove = (row?: any) => {
    const remainingSkillsId = Props?.tableData.filter((skill: any) => skill?.id !== row?.id).map((skill: any) => skill?.id);
    Props?.handleRemoveSkillAlertDialogOpen(remainingSkillsId);
  }

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TableContainer className="table-container">
          <Table stickyHeader size="medium" aria-label="skills table">
            <TableHead>
              <TableRow>
                {Props?.skillsMatrixHeader.map((headCell: TableHeadCell) =>
                  headCell.label !== "" ? (
                    <React.Fragment key={headCell.id}>
                      <TableCell
                        colSpan={2}
                        key={headCell.id}
                        align={"center"}
                        sx={{
                          backgroundColor: getColorForLevel(headCell.id),
                          width: "20%",
                        }}
                      >
                        {t(headCell.id)}
                      </TableCell>
                    </React.Fragment>
                  ) : (
                    <TableCell sx={{ width: "20%" }}>
                      {t(headCell.label)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                {Props?.skillsMatrixHeader.map((headCell: TableHeadCell) =>
                  headCell.label !== "" ? (
                    <React.Fragment key={headCell.id}>
                      <TableCell align={"center"} sx={{ width: "8%" }}>
                        {t("opva.total")}
                      </TableCell>
                      <TableCell align={"center"} sx={{ width: "8%" }}>
                        {t("opva.action")}
                      </TableCell>
                    </React.Fragment>
                  ) : (
                    <TableCell sx={{ width: "8%" }}>{t("opva.skillsName")}</TableCell>
                  )
                )}
                <TableCell align="center" sx={{ width: "8%" }}>{t("opva.remove")}</TableCell>
              </TableRow>
            </TableHead>
            {isSpinnerShow ? (
              <TableLoading column={4} />
            ) : (
              <TableBody>
                {displayTableData && displayTableData?.length ? (
                  displayTableData.map((row: any, index: number) => (
                    <TableRow key={row?.id}>
                      <TableCell>{row.name}</TableCell>
                      {Props?.skillsLevels.map((level: any) => (
                        <React.Fragment key={level.id}>
                          <TableCell align={"center"}>
                            {row.levels[level.id]}
                          </TableCell>
                          <TableCell align={"center"}>
                            <Link component="button" title={t("opva.action")}>
                            <img src={"images/" + 'doubleArrowUp.svg'} alt="Profile" width={'16px'} height={'14px'} onClick={() => goToWorkflow(row)} />
                              {/* <KeyboardDoubleArrowUpIcon color="primary" onClick={() => goToWorkflow(row)}/> */}
                            </Link>
                          </TableCell>
                        </React.Fragment>
                      ))}
                      <TableCell align="center">
                        <RemoveIcon
                                  sx={{ cursor: "pointer" }}
                                  color="error"
                                  onClick={() => {
                                    handleRowRemove(row);
                                  }}
                                />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={{height: '400px'}}>
                    <TableCell colSpan={12} align="center">
                      <div className="d-flex align-items-center flex-column" >
                      <Typography variant="bodyLarge" className="p-2">
                      {t("opva.selectSkillsMatrix")}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={Props?.handleSelectSkills} sx={{textTransform: 'none'}}>
                      {t("opva.selectSkill")}
                    </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          className="pagination-container mb-4"
          component="div"
          count={Props?.tableData?.length}
          page={Props?.page}
          onPageChange={handleChangePage}
          rowsPerPage={Props?.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Grid
        container
        display={"flex"}
        justifyContent={"flex-end"}
        className="pb-2"
        spacing={2}
        direction={"row"}
      >
        {Props?.skillsLevels.length > 0 &&
          Props?.skillsLevels.map((level: any, index: number) => (
            <Grid
              item
              key={index}
              display={"flex"}
              style={{ flexDirection: "row" }}
              alignItems={'center'}
            >
              <Typography
                variant="h6"
                width={'32px'}
                height={'32px'}
                textAlign={'center'}
                sx={{ backgroundColor: getColorForLevel(level?.id), borderRadius:"4px", paddingTop:'6px'}}
              >
                {level?.id}
              </Typography>
              <Typography variant="h6" className="p-2">
              {t(`opva.${level?.name}`)}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
