import { createTheme } from "@mui/material/styles";
import { CSSProperties } from "react";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    display1: CSSProperties;
    display2: CSSProperties;
    leadParagraph: CSSProperties;
    bodyLarge: CSSProperties;
    bodyMedium: CSSProperties;
    bodyNormal: CSSProperties;
    bodySmall: CSSProperties;
    labelExtraSmall: CSSProperties;
    labelSmall: CSSProperties;
    labelNormal: CSSProperties;
    labelLarge: CSSProperties;
    labelExtraLarge: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display1?: CSSProperties;
    display2: CSSProperties;
    leadParagraph: CSSProperties;
    bodyLarge: CSSProperties;
    bodyMedium: CSSProperties;
    bodyNormal: CSSProperties;
    bodySmall: CSSProperties;
    labelExtraSmall: CSSProperties;
    labelSmall: CSSProperties;
    labelNormal: CSSProperties;
    labelLarge: CSSProperties;
    labelExtraLarge: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2: true;
    leadParagraph: true;
    bodyLarge: true;
    bodyMedium: true;
    bodyNormal: true;
    bodySmall: true;
    labelExtraSmall: true;
    labelSmall: true;
    labelNormal: true;
    labelLarge: true;
    labelExtraLarge: true;
  }
}

const OPTIVAL_APP_THEME = createTheme({
  palette: {
    primary: {
      main: "#0084F4",
    },
    error: {
      main: "#FF647C",
    },
  },
  typography: {
    fontFamily: "Inter, Mulish",
    display1: {
      fontFamily: "Inter",
      fontWeight: 200,
      fontSize: "48px",
      lineHeight: 1.3 * 48 + "px",
    },
    display2: {
      fontFamily: "Inter",
      fontWeight: 200,
      fontSize: "40px",
      lineHeight: 1.3 * 40 + "px",
    },
    h1: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "44px",
      lineHeight: 1.3 * 44 + "px",
    },
    h2: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: 1.3 * 36 + "px",
    },
    h3: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: 1.3 * 28 + "px",
    },
    h4: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: 1.3 * 24 + "px",
    },
    h5: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: 1.3 * 20 + "px",
    },
    h6: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: 1.3 * 16 + "px",
    },
    leadParagraph: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: 1.3 * 22 + "px",
    },
    bodyLarge: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: 1.7 * 20 + "px",
    },
    bodyMedium: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: 1.7 * 18 + "px",
    },
    bodyNormal: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 1.7 * 16 + "px",
    },
    bodySmall: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.7 * 14 + "px",
    },
    labelExtraSmall: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: 1.5 * 10 + "px",
    },
    labelSmall: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: 1.5 * 12 + "px",
    },
    labelNormal: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.5 * 14 + "px",
    },
    labelLarge: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 1.5 * 16 + "px",
    },
    labelExtraLarge: {
      fontFamily: "Mulish",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: 1.5 * 18 + "px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0.3rem",
          textTransform: "uppercase",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          marginTop: "1rem",
        },
        displayedRows: {
          marginTop: "1rem",
        },
      },
    },
  },
});

export default OPTIVAL_APP_THEME;
