import { createContext, useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";
import {
  AuthUserDetailsModel,
  AuthenticationContextModel,
} from "./models/ContextModels";
import { OPTIVAL_CONSTANTS } from "./Constants";

export const AuthenticationContext = createContext(
  {} as AuthenticationContextModel
);

export const AuthenticationProvider = ({ children }: any) => {
  const [token, setToken] = useState<string>(
    localStorage.getItem("jwtToken") || ""
  );
  const [userRole, setUserRole] = useState<string[]>([]);
  const [authUserDetails, setAuthUserDetails] = useState<AuthUserDetailsModel>(
    {} as AuthUserDetailsModel
  );

  function decodeJWTToken() {
    if (token) {
      const jwtTokenDecoded = jwtDecode<AuthUserDetailsModel>(token);
      setAuthUserDetails(jwtTokenDecoded);
      if (
        jwtTokenDecoded &&
        jwtTokenDecoded.employeeRoles &&
        jwtTokenDecoded.employeeRoles.length
      ) {
        setUserRole(jwtTokenDecoded.employeeRoles);
      }
    } else {
      setUserRole([]);
    }
  }

  const updateToken = (value: string) => {
    setToken(value);
  };
  const updateUserRole = (value: string[]) => {
    setUserRole(value);
  };

  const authProviderValues = useMemo(
    () => ({ token, userRole, authUserDetails, updateToken, updateUserRole }),
    [token, userRole, authUserDetails]
  );

  useEffect(() => {
    decodeJWTToken();
  }, [token]);

  return (
    <AuthenticationContext.Provider value={authProviderValues}>
      {children}
    </AuthenticationContext.Provider>
  );
};
