import { Button } from "@mui/material";
import ExcelJS from "exceljs";
import { useTranslation } from "react-i18next";
export const DownloadExcelButton: any = (Props: any) => {
  const { t } = useTranslation();
  const generateExcelFile = () => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    // Add headers
    worksheet.addRow(Props.header);
    let dropdownList = '"' + Props?.options?.join(",") + '"';
    if (Props.options) {
      for (let i = 2; i < Props.listLength; i++) {
        worksheet.getCell(Props.optionCell + i).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [dropdownList],
        };
      }
    }
    let secondDropdownList = '"' + Props?.secondOptions?.join(",") + '"';
    if (Props.secondOptions) {
      for (let i = 2; i < Props.listLength; i++) {
        worksheet.getCell(Props.secondOptionCell + i).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [secondDropdownList],
        };
      }
    }
    //For assigning default values to the rows
    for (let key in Props?.defaultValues) {
      for (let i = 2; i < Props.listLength; i++) {
        worksheet.getCell(key + i).value = Props.defaultValues[key];
      }
    }
    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = Props?.fileName;
      a.click();
    });
  };
  return (
    <Button
      size="large"
      variant="contained"
      sx={{ color: "#FFFFFFCC", textTransform: 'none' }}
      onClick={generateExcelFile}
    >
      {t("opva.downloadFormat")}
    </Button>
  );
};
