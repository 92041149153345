import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserMatrixList } from "./user-matrix-list/UserMatrixList";
import { SkillsMatrixList } from "./skills-matrix-list/SkillsMatrixList";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useAPI } from "../../../../shared/services/api/API";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { SkillsContext } from "../../contexts/SkillsContext";
import { SelectUsersDialog } from "./select-users/SelectUsersDialog";
import { SelectSkillDialog } from "./select-skills/SelectSkillsDialog";
import { dummySkillsLevels, ISkillsLevel } from "../../models/SkillsData";
import { AlertDialog } from "../../../../shared/components/alert-dialog/AlertDialog";

export const SkillsMatrix = (Props: any) => {
  const { t } = useTranslation();
  const { handleAlertBar, httpPost, httpDelete } = useAPI();
  const [openSkillDialog, setOpenSkillDialog] = useState(false);
  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  const { updateSkillsBarChartLessMore } = useContext(SkillsContext);
  const [selectSkillsList, setSelectSkillsList]: any[] = useState([]);
  const [selectUserSkillsList, setSelectUserSkillsList]: any[] = useState([]);
  const [selectGroupsList, setSelectGroupsList]: any[] = useState([]);
  const [selectUsersList, setSelectUsersList]: any[] = useState([]);
  const [skillsMatrixHeader, setSkillsMatrixHeader] = useState<any>([]);
  const [skillsMatrixUsersHeader, setSkillsMatrixUsersHeader] = useState<any>(
    []
  );
  const [skillsLevels, setSkillsLevels] = useState<ISkillsLevel[]>(dummySkillsLevels);
  const [searchSkillName, setSearchSkillName] = useState<string>("");
  const [skillsTableData, setSkillsTableData] = useState([]);
  const [usersTableData, setUsersTableData] = useState([]);
  const [isRemoveSkillAlertDialog, setIsRemoveSkillAlertDialog] = useState(false);
  const [isRemoveUserAlertDialog, setIsRemoveUserAlertDialog] = useState(false);
  const [clearAllSkillAlertDialog, setClearAllSkillAlertDialog] = useState(false);
  const [clearAllUserAlertDialog, setClearAllUserAlertDialog] = useState(false);
  const [remainingSkillId, setRemainingSkillId] = useState<any>([]);
  const [skillsPage, setSkillsPage] = useState(0);
  const [skillsRowsPerPage, setSkillsRowsPerPage] = useState(10);
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);


  const handleCloseDialog = () => {
    setOpenSkillDialog(false);
    setSearchSkillName("");
    setOpenUsersDialog(false);
  };

  const handleSkillsEmptyPage = (data: any) => {
    const sliceArr = data.slice(
      skillsPage * skillsRowsPerPage,
      skillsPage * skillsRowsPerPage + skillsRowsPerPage
    );
    if (!sliceArr.length) {
      setSkillsPage(skillsPage === 0 ? 0 : skillsPage - 1);
    }
  };

  const handleUserEmptyPage = (data: any) => {
    const sliceArr = data.slice(
      userPage * userRowsPerPage,
      userPage * userRowsPerPage + userRowsPerPage
    );
    if (!sliceArr.length) {
      setUserPage(userPage === 0 ? 0 : userPage - 1);
    }
  };

  const selectSkills = async () => {
    if(Props?.byUser) {
      const skillIds = selectUserSkillsList?.map((items: any) => items.skillId);
      const existPayload = sessionStorage.getItem("userPayload");
      let formatExistPayload;
      if(existPayload) {
        formatExistPayload = JSON.parse(existPayload);
      }
      const groupsIds = selectGroupsList?.map((items: any) => items?.groupId);
      const employeeIds = selectUsersList?.map((items: any) => items?.id);
      const reqBody = {
        "employeeIds": formatExistPayload?.employeeIds ? formatExistPayload?.employeeIds : employeeIds,
        "groupIds": formatExistPayload?.groupIds ? formatExistPayload?.groupIds : groupsIds,
        "skillIds": skillIds
      }
      sessionStorage.setItem('userPayload',JSON.stringify(reqBody))
      getSkillsMatrixUsers(reqBody)

    } else {
      const skillIds = selectSkillsList?.map((items: any) => items.skillId);
      const existPayload = sessionStorage.getItem("skillsPayload");
      let formatExistPayload;
      if(existPayload) {
        formatExistPayload = JSON.parse(existPayload);
      }
      const reqBody = {
        "skillIds": skillIds
      }
      sessionStorage.setItem("skillsPayload",JSON.stringify(reqBody));
      fetchSkillsMatrixData(reqBody?.skillIds)
    }
    handleCloseDialog();
  };

  const fetchSkillsMatrixData = (skillIds?: any, callFrom?: string ) => {
    const skillsPayload = sessionStorage.getItem("skillsPayload");
    let payload : any;

    if(skillsPayload) {
      payload = JSON.parse(skillsPayload);
    } else {
      payload = {
        "skillIds": payload?.skillIds ? payload?.skillIds : skillIds
      }
    }
    const quaryParams = { page: 0, pageSize: 1000 };
    if(payload?.skillIds?.length === 0 && callFrom === 'remove') {
      clearAllSkills();
      handleRemoveSkillAlertDialogClose();
    } else {
      httpPost(OPTIVAL_CONSTANTS.API_URLS.GET_SKILL_MATRIX,payload, quaryParams).then(
        (response) => {
          if (response && response.data && response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            // Extract levels and skill matrix
            const levels = response?.data?.levels.length ? response?.data?.levels : dummySkillsLevels;
            const skillsMatrix = response?.data?.skillMatrix;
  
            // Generate table header
            const tableHeader = [{ id: '', label: "" }];
            levels.forEach((level: any) => {
              tableHeader.push({ id: level.id, label: level.name });
            });
            setSkillsLevels(levels);
            setSkillsMatrixHeader(tableHeader);
            //Generate table data
            setSkillsTableData(skillsMatrix);
            handleSkillsEmptyPage(skillsMatrix);
            if(callFrom === 'remove') {
              const filteredSelectedSkills = selectSkillsList?.filter((item: any) => remainingSkillId.includes(item?.skillId));
              setSelectSkillsList(filteredSelectedSkills);
              handleRemoveSkillAlertDialogClose();
              handleAlertBar("success", t("opva.skillRemoveSuccessMsg"));
            }
            if(skillsMatrix?.length > 0) {
              const skillIds = skillsMatrix.map((skill: any) => skill?.id );
              const reqBody = {
                "skillIds": skillIds
              }
              const selectedSkillsList = skillsMatrix?.map((skill: any) =>{ return {
                skillId: skill?.id,
                name: skill?.name,
              }});
              setSelectSkillsList(selectedSkillsList);
              sessionStorage.setItem("skillsPayload",JSON.stringify(reqBody));
            }
          }
        },
        (error) => {
          handleAlertBar("error", error.message || t("opva.serviceUnavailable"));
        }
      );

    }
  };

  const selectUsers = async () => {
    const existPayload = sessionStorage.getItem("userPayload");
      let formatExistPayload;
      if(existPayload) {
        formatExistPayload = JSON.parse(existPayload);
      }
    const groupsIds = selectGroupsList?.map((items: any) => items?.groupId);
    const employeeIds = selectUsersList?.map((items: any) => items?.id);
    const skillIds = selectUserSkillsList?.map((items: any) => items.skillId);
    const reqBody = {
      "employeeIds": formatExistPayload?.employeeIds ? [...formatExistPayload?.employeeIds, ...employeeIds]  : employeeIds,
      "groupIds": formatExistPayload?.groupIds ? [...formatExistPayload?.groupIds, ...groupsIds] : groupsIds,
      "skillIds": formatExistPayload?.skillIds ? formatExistPayload?.skillIds : skillIds
    }
    sessionStorage.setItem('userPayload',JSON.stringify(reqBody));
    getSkillsMatrixUsers(reqBody);
    handleCloseDialog();
  };

  const getSkillsMatrixUsers = async (reqBody?: any, callFrom?: string) => {
    const userPayload = sessionStorage.getItem("userPayload");
    let payload : any;

    if(userPayload) {
      payload = JSON.parse(userPayload);
    } else {
      payload = {
        "employeeIds": reqBody?.employeeIds ? reqBody?.employeeIds : [],
        "groupIds": reqBody?.groupIds ? reqBody?.groupIds : [],
        "skillIds": reqBody?.skillIds ? reqBody?.skillIds : []
      }
    }
    const queryParams = {
      page: 0,
      size: 1000,
    };
    if(payload?.employeeIds?.length === 0 && callFrom === 'remove') {
      clearAllUsers();
      handleRemoveUserAlertDialogClose();
    } else {
      httpPost(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_MATRIX_USERS, payload, queryParams).then(
        (response) => {
          if (response && response.data && response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            // Map levels to a dictionary for easy access
            const levelsMap = response?.data?.levels.reduce(
              (acc: any, level: any) => {
                acc[level.id] = level.id;
                return acc;
              },
              {}
            );
            // Step 2: Extract employee names and skills
            const employees = response?.data?.employees;
            if(response?.data?.levels?.length) {
              setSkillsLevels(response?.data?.levels);
            }
            const skills = response?.data?.skills;
            // Step 3: Generate table header
            const tableHeader = [{ id: "Users/Groups", label: "Users/Groups" }];
            skills.forEach((skill: any) => {
              tableHeader.push({ id: skill.id, label: skill.name });
            });
            setSkillsMatrixUsersHeader(tableHeader);
            // Step 4: Generate table data
            const tableData = employees.map((employee: any) => {
              const rowData: any = { "Users/Groups":`${employee.firstName} ${employee.lastName}` };
              skills.forEach((skill: any) => {
                const skillLevelId = response.data.matrix[employee.id][skill.id];
                rowData[skill.name] = skillLevelId
                  ? levelsMap[skillLevelId]
                  : null;
              });
              rowData["empId"] = employee?.id;
              return rowData;
            });
            setUsersTableData(tableData);
            handleUserEmptyPage(tableData);
            if(callFrom === 'remove') {
              const filteredSelectedSkills = selectSkillsList?.filter((item: any) => remainingSkillId.includes(item?.skillId));
              handleRemoveUserAlertDialogClose();
              handleAlertBar("success", t("opva.userRemoveSuccessMsg"));
            }
            const empIds = employees.map((emp: any) => emp?.id);
            const skillIds = skills.map((skill: any) => skill?.id );
            const selectedSkillsList = skills?.map((skill: any) =>{ return {
              skillId: skill?.id,
              name: skill?.name,
            }})
            const selectedEmpsList = employees?.map((employee: any) =>{ return {
              id: employee?.id,
              firstName: employee?.firstName,
            }})
            const reqBody = {
              "employeeIds": empIds,
              "groupIds": [],
              "skillIds": skillIds
            }
            sessionStorage.setItem('userPayload',JSON.stringify(reqBody))
            setSelectUserSkillsList(selectedSkillsList);
            setSelectUsersList(selectedEmpsList);
          }
        },
        (error) => {
          handleAlertBar("error", error.message || t("opva.serviceUnavailable"));
        }
      ); 
    }
  };

  const clearSkillMatrix = async (filterConfigType: string) => {
    const queryParams = {
      filterConfigType  : filterConfigType
    }
    httpDelete(OPTIVAL_CONSTANTS.API_URLS.CLEAR_FILTER_SKILLS_MATRIX, null,queryParams).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          if(filterConfigType === 'SKILL_MATRIX_BY_USER_GROUP') {
            setClearAllUserAlertDialog(false);
            setSelectGroupsList([]);
            setSelectUsersList([]);
            setSelectUserSkillsList([]);
            const reqBody = {
              "employeeIds": [],
              "groupIds": [],
              "skillIds": []
            }
            getSkillsMatrixUsers(reqBody);
          } else if (filterConfigType === 'SKILL_MATRIX_BY_SKILL') {
            setClearAllSkillAlertDialog(false);
            setSelectSkillsList([]);
            fetchSkillsMatrixData([]);
          }
          handleAlertBar("success", t("opva.filterConfigClearSuccessMsg"))

        }
      },
      (error) => {
        handleAlertBar("error", error.message || t("opva.serviceUnavailable"));
      }
    );
  };

  const handleSelectSkills = async () => {
    if(Props?.byUser) {
      getSkillsMatrixUsers();
    } else {
      fetchSkillsMatrixData();
    }
    setSearchSkillName('');
    setOpenSkillDialog(true);
  };

  const handleSelectUsers = async () => {
    // setSelectGroupsList([]);
    // setSelectUsersList([]);
    setOpenUsersDialog(true);
  };

  const clearAllSkills = async () => {
    setClearAllSkillAlertDialog(true);
  };

  const clearAllUsers = async () => {
    setClearAllUserAlertDialog(true);
  }

  const handleRemoveSkillAlertDialogClose = () => {
    setIsRemoveSkillAlertDialog(false);
  };

  const handleClearAllSkillAlertDialogClose = () => {
    setClearAllSkillAlertDialog(false);
  };

  const handleClearAllUserAlertDialogClose = () => {
    setClearAllUserAlertDialog(false);
  };

  const handleRemoveSkillAlertDialogOpen = (skillId: any) => {
    setRemainingSkillId(skillId);
    const reqBody = {
      skillIds: skillId
    }
    sessionStorage.setItem("skillsPayload",JSON.stringify(reqBody));
    setIsRemoveSkillAlertDialog(true);
  };

  const handleRemoveUserAlertDialogClose = () => {
    setIsRemoveUserAlertDialog(false);
  };

  const handleRemoveUserAlertDialogOpen = (reqBody: any) => {
    sessionStorage.setItem("userPayload",JSON.stringify(reqBody));
    setIsRemoveUserAlertDialog(true);
  };

  return (
    <div className="mt-4 p-3" style={{backgroundColor: "#fafbfc", borderRadius: '1rem'}} >
    <Paper className="m-2 p-3" elevation={2}>
      <Grid
        container
        display={"flex"}
        justifyContent={"flex-end"}
        className="p-2"
        spacing={1}
      >
        {Props?.byUser ? (
          <>
          <Grid item>
            <Button variant="outlined" color="error" sx={{textTransform: 'none'}} disabled={usersTableData.length === 0} onClick={() => clearAllUsers()}>
              {t("opva.clearAllUsers")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" sx={{textTransform: 'none'}} onClick={handleSelectUsers}>
              {t("opva.selectUsers")}
            </Button>
          </Grid>
          <Grid item>
          <Button variant="contained" color="primary" onClick={handleSelectSkills} sx={{textTransform: 'none'}}>
            {t("opva.selectSkill")}
          </Button>
        </Grid>
          </>
        ) : (
          <><Grid item>
          <Button variant="outlined" color="error" sx={{textTransform: 'none'}} onClick={() => clearAllSkills()} disabled={skillsTableData?.length === 0}>
            {t("opva.clearAllSkills")}
          </Button>
        </Grid>
        <Grid item>
            <Button variant="outlined" color="primary" sx={{textTransform: 'none'}} onClick={() => updateSkillsBarChartLessMore(true)}>
              {t("opva.setTargets")}
            </Button>
          </Grid>
          <Grid item>
          <Button variant="contained" color="primary" onClick={handleSelectSkills} sx={{textTransform: 'none'}}>
            {t("opva.selectSkill")}
          </Button>
        </Grid>
        </>
        )}
      </Grid>
      <Box sx={{ width: "100%", typography: "body1" }}>
        { Props?.byUser ? 
        <UserMatrixList 
          getSkillsMatrixUsers={getSkillsMatrixUsers} 
          tableData={usersTableData} 
          skillsLevels={skillsLevels} 
          skillsMatrixUsersHeader={skillsMatrixUsersHeader} 
          handleSelectUsers={handleSelectUsers}
          handleRemoveUserAlertDialogOpen={handleRemoveUserAlertDialogOpen}
          page={userPage}
          setPage={setUserPage}
          rowsPerPage={userRowsPerPage}
          setRowsPerPage={setUserRowsPerPage}
          setTableData={setUsersTableData} />
        : <SkillsMatrixList 
            fetchSkillsMatrixData={fetchSkillsMatrixData} 
            tableData={skillsTableData} 
            skillsLevels={skillsLevels} 
            skillsMatrixHeader={skillsMatrixHeader} 
            handleSelectSkills={handleSelectSkills}
            handleRemoveSkillAlertDialogOpen={handleRemoveSkillAlertDialogOpen}
            page={skillsPage}
            setPage={setSkillsPage}
            rowsPerPage={skillsRowsPerPage}
            setRowsPerPage={setSkillsRowsPerPage}
            setTableData={setSkillsTableData}/>
        }
      </Box>
      <SelectSkillDialog 
        openDialog={openSkillDialog}
        handleCloseDialog={handleCloseDialog}
        selectSkills={selectSkills}
        setSelectSkillsList={setSelectSkillsList}
        selectSkillsList={selectSkillsList}
        setSelectUserSkillsList={setSelectUserSkillsList}
        selectUserSkillsList={selectUserSkillsList}
        setSearchSkillName={setSearchSkillName}
        searchSkillName={searchSkillName}
        byUser={Props?.byUser}
      />
      <SelectUsersDialog 
        openDialog={openUsersDialog}
        handleCloseDialog={handleCloseDialog}
        selectUsers={selectUsers}
        setSelectGroupsList={setSelectGroupsList}
        selectGroupsList={selectGroupsList}
        setSelectUsersList={setSelectUsersList}
        selectUsersList={selectUsersList}
      />
    </Paper>
        <AlertDialog
          open={isRemoveSkillAlertDialog}
          title={t("opva.deleteSkil")}
          description={t("opva.wantToDeleteSkill")}
          submitBtnText={t("opva.delete")}
          handleSubmit={() =>fetchSkillsMatrixData(remainingSkillId, 'remove')}
          handleClose={handleRemoveSkillAlertDialogClose}
        ></AlertDialog>
        <AlertDialog
          open={isRemoveUserAlertDialog}
          title={t("opva.deleteUser")}
          description={t("opva.confirmationMsgDeleteUser")}
          submitBtnText={t("opva.delete")}
          handleSubmit={() =>getSkillsMatrixUsers({}, 'remove')}
          handleClose={handleRemoveUserAlertDialogClose}
        ></AlertDialog>
        <AlertDialog
          open={clearAllSkillAlertDialog}
          title={t("opva.clearAllSkills")}
          description={t("opva.confirmationMsgClearAllSkill")}
          submitBtnText={t("opva.clear")}
          handleSubmit={() => {
            sessionStorage.removeItem("skillsPayload");
            clearSkillMatrix('SKILL_MATRIX_BY_SKILL');
          }}
          handleClose={handleClearAllSkillAlertDialogClose}
        ></AlertDialog>
        <AlertDialog
          open={clearAllUserAlertDialog}
          title={t("opva.clearAllUsers")}
          description={t("opva.confirmationMsgClearAllUser")}
          submitBtnText={t("opva.clear")}
          handleSubmit={() => {
            sessionStorage.removeItem("userPayload");
            clearSkillMatrix('SKILL_MATRIX_BY_USER_GROUP');
          }}
          handleClose={handleClearAllUserAlertDialogClose}
        ></AlertDialog>
    </div>
  );
};
