import "./SignUpRequest.scss";
import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useMemo,
} from "react";
import {
  Grid,
  Input,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TableSortLabel,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import {
  SignUpRequestConstants,
  useSignUpRequestUtil,
} from "../../constants/SignUpRequestConstant";
import { Order, TableHeadCell } from "../../../../shared/models/TableModels";
import { TableLoading } from "../../../../shared/components/skeleton/table-loading/TableLoading";
import { SpinnerContext } from "../../../../shared/SpinnerContext";
import { visuallyHidden } from "@mui/utils";
import { tableSorting } from "../../../../shared/utils/Utils";
import { useTranslation } from "react-i18next";

export interface TableDataActionsModel {
  page: number;
  perPageRows: number;
  order: Order;
  orderBy: string;
  searchText: string;
}

export interface SignUpRequestListModel {
  id: number;
  name: string;
  mail: string;
  phone: string;
  createdAt: string;
}
export const SignupRequest = () => {
  const navigate = useNavigate();
  const { httpGet, handleAlertBar } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [searchOrganisationText, setSearchOrganisationText] = useState("");
  const [signUpRequestList, setSignUpRequestList] = useState<
    SignUpRequestListModel[]
  >([]);
  const [filteredSignUpRequestData, setFilteredSignUpRequestData] = useState<
    SignUpRequestListModel[]
  >([]);
  const { getSignUpReq } = useSignUpRequestUtil();
  const { t } = useTranslation();
  const [tableDataActions, setTableDataActions] = useState({
    page: 0,
    perPageRows: 10,
    order: "desc",
    orderBy: "createdAt",
    searchText: "",
  } as TableDataActionsModel);

  useEffect(() => {
    fetchSignUpRequestData();
  }, []);

  useEffect(() => {
    handleSearchByName(searchOrganisationText);
  }, [searchOrganisationText]);

  useMemo(() => {
    const propertyType =
      tableDataActions.orderBy === "skillNames" ? "number" : "string";
    const sortedList = tableSorting(
      signUpRequestList,
      tableDataActions.order,
      tableDataActions.orderBy,
      propertyType
    );
    setSignUpRequestList(sortedList);
    setFilteredSignUpRequestData(
      sortedList.slice(
        tableDataActions.page * tableDataActions.perPageRows,
        tableDataActions.page * tableDataActions.perPageRows +
          tableDataActions.perPageRows
      )
    );
  }, [tableDataActions, signUpRequestList]);

  const fetchSignUpRequestData = async () => {
    const queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "createdAt",
      isDescending: true,
      searchString: "",
    };
    httpGet(
      OPTIVAL_CONSTANTS.API_URLS.GET_SIGNUP_REQUEST_DATA,
      queryParams
    ).then(
      (response: any) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            console.log("employees detail response-->", response.data);
            setSignUpRequestList(response.data);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error: any) => {
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updateProperty = {
      perPageRows: parseInt(event.target.value, 10),
      page: 0,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handleSearchByName = (value: string) => {
    if (!value) {
      setSignUpRequestList([...signUpRequestList]);
    } else {
      const filteredData = signUpRequestList.filter(
        (signUpRequestData: SignUpRequestListModel) =>
          signUpRequestData.name.toLowerCase().includes(value.toLowerCase()) ||
          signUpRequestData.mail.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSignUpRequestData(
        filteredData.slice(
          tableDataActions.page * tableDataActions.perPageRows,
          tableDataActions.page * tableDataActions.perPageRows +
            tableDataActions.perPageRows
        )
      );
    }
  };

  const handlePaginationChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setTableDataActions((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handleSorting = (property: string) => {
    const isAsc =
      tableDataActions.orderBy === property && tableDataActions.order === "asc";
    const updateProperty = {
      order: isAsc ? "desc" : ("asc" as Order),
      orderBy: property,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };
  const handleOnBoardOrganisation = (rowData: any) => {
    navigate(`/onboard-organisation?signupRequestId=${rowData.id}`, {
      state: { rowData },
    });
  };

  return (
    <div>
      <div className="signup-request px-5">
        <Grid container alignItems="center" className="pt-5 pb-5">
          <Grid item xs md lg={6} className="d-flex align-items-center">
            <Typography variant="h3" className="mb-0">
              {t("opva.signupReqOrg")}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            md
            lg={6}
            className="d-flex align-items-center justify-content-end"
          >
            <Input
              id="search"
              type="text"
              placeholder={t("opva.searchForOrganisation")}
              value={searchOrganisationText}
              style={{ minWidth: "400px", height: "32px" }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              }
              endAdornment={
                searchOrganisationText && (
                  <InputAdornment position="end">
                    <Link component="button" title={t("opva.clear")}>
                      <CloseIcon
                        fontSize="small"
                        onClick={() => setSearchOrganisationText("")}
                      />
                    </Link>
                  </InputAdornment>
                )
              }
              aria-describedby="standard-weight-helper-text"
              onChange={(e) => setSearchOrganisationText(e.target.value)}
            />
          </Grid>
        </Grid>
      </div>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="signup-request-content px-5"
      >
        <TableContainer className="table-container">
          <Table
            stickyHeader
            sx={{ width: "100%" }}
            size="medium"
            aria-label="Sign Up Request table"
          >
            <TableHead>
              <TableRow key="sign-up-request-table-header">
                {getSignUpReq().map((headCell: TableHeadCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      tableDataActions.orderBy === headCell.sortingProperty
                        ? tableDataActions.order
                        : false
                    }
                    align={headCell.position}
                    // className="px-2"
                    sx={{
                      width: headCell.width + "%",
                    }}
                  >
                    {headCell.sorting ? (
                      <TableSortLabel
                        active={
                          tableDataActions.orderBy === headCell.sortingProperty
                        }
                        direction={
                          tableDataActions.orderBy === headCell.sortingProperty
                            ? tableDataActions.order
                            : "asc"
                        }
                        onClick={(event) =>
                          handleSorting(headCell.sortingProperty)
                        }
                      >
                        {headCell.label}
                        {tableDataActions.orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {tableDataActions.order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isSpinnerShow ? (
              <TableLoading column={getSignUpReq().length} />
            ) : (
              <TableBody>
                {filteredSignUpRequestData &&
                filteredSignUpRequestData.length > 0 ? (
                  filteredSignUpRequestData.map(
                    (data: SignUpRequestListModel, index: number) => (
                      <Fragment key={index}>
                        <TableRow>
                          <TableCell>{data?.name}</TableCell>
                          <TableCell>{data?.mail}</TableCell>
                          <TableCell>{data?.phone}</TableCell>
                          <TableCell>
                            {data.createdAt &&
                              new Date(data.createdAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="center">
                            <Link
                              component="button"
                              title="Edit"
                              onClick={(e) => handleOnBoardOrganisation(data)}
                            >
                              <EditOutlinedIcon />
                            </Link>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={getSignUpReq().length}>
                      {searchOrganisationText
                        ? t("opva.noSearchSignUpRequestDataAvailable")
                        : t("opva.noSignUpRequestDataAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
      <div className="d-flex align-items-center justify-content-end px-5">
        <TablePagination
          component="div"
          className="pagination-container mb-4"
          count={signUpRequestList.length}
          page={
            !signUpRequestList.length || signUpRequestList.length <= 0
              ? 0
              : tableDataActions.page
          }
          rowsPerPage={tableDataActions.perPageRows}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};
