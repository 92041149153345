import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

interface MyButtonProps extends ButtonProps {
  label: string;
  variant: 'text' | 'outlined' | 'contained' | undefined;
}

export const MyButton: React.FC<MyButtonProps> = ({ label, variant, ...props }) => {
  const buttonStyles = {
    alignItems: 'center',
    marginTop: 2,
    background: variant !== 'outlined' ? '#25BCCA' : 'initial',
    border: variant === 'outlined' ? '0.2rem solid #25BCCA' : 'initial',
    color: variant === 'outlined' ? '#25BCCA' : '#FFFFFFCC',
    '&:hover': {
      background: variant !== 'outlined' ? '#1C2E36' : 'initial',
      color: variant === 'outlined' ? '#1C2E36' : '#FFFFFFCC',
      border: variant === 'outlined' ? '0.2rem solid #25BCCA' : 'initial',
    },
  };

  return (
    <Button
      variant={variant}
      sx={{
        ...buttonStyles,
        display: 'flex',
        width: '20rem',
        height: '3.4375rem',
        justifyContent: 'center',
        flexShrink: 0,
      }}
      {...props}
    >
      {label}
    </Button>
  );
};

// export default MyButton;
