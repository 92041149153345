import { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import "./NewUser.scss";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { DownloadExcelButton } from "../../../../shared/components/Download/DownloadExcelButton";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import {
  UserDetailInterface,
  UserTypeListInterface,
} from "../../models/UserManagement";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useAPI } from "../../../../shared/services/api/API";
import { updateURLParams } from "../../../../shared/utils/Utils";
import { AuthenticationContext } from "../../../../shared/Contexts";
import { UserManagementConstants } from "../../constants/UserManagementConstant";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";

export const NewUser = (Props: any) => {
  const { t } = useTranslation();
  const { authUserDetails } = useContext(AuthenticationContext);
  const { httpPost, httpGet, httpPut, handleAlertBar } = useAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");
  const userData = location?.state?.userData;
  const [role, setRole] = useState([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [formData, setFormData] = useState<UserDetailInterface>({
    firstName: "",
    lastName: "",
    employeeId: "",
    mail: "",
    phone: "",
    roles: "",
    departmentName: "",
    location: "",
    position: "",
    errors: {
      firstName: false,
      lastName: false,
      employeeId: false,
      mail: false,
      phone: false,
      roles: false,
      departmentName: false,
      location: false,
      position: false,
    },
  });
  const [userTypeList, setuserTypeList] = useState<[UserTypeListInterface]>([
    {
      id: 0,
      name: "",
      description: "",
    },
  ]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [selectedUserType, setSelectedUserType] = useState<any>([]);

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    let isError = false;
    if (name !== "roles") {
      if (value.trim() === "") {
        isError = true;
      } else if (name === "mail") {
        isError = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      } else if (name === "phone") {
        isError = !/^\d{10}$/.test(value);
      }
    } else if (name === "roles") {
      setRole(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      errors: { ...prevData.errors, [name]: isError },
    }));
  };

  const isFormValid = () => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.mail);
    const isMobileValid = /^\d{10}$/.test(formData.phone);

    return (
      formData?.firstName?.trim() !== "" &&
      formData?.lastName?.trim() !== "" &&
      selectedUserType?.length > 0 &&
      //formData?.position?.trim() !== "" &&
      isEmailValid
      // && isMobileValid
    );
  };

  const handleUploadBoxDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleUploadBoxDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const dropFiles = e.dataTransfer.files[0];
    if (dropFiles.type.includes("sheet")) {
      setSelectedFile(dropFiles);
      setErrorMsg("");
    } else {
      setErrorMsg("Please Upload an excel sheet");
      setSelectedFile(null);
    }
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
  };

  const previousButton = () => {
    navigate("/newmanagement");
  };
  const handleRemoveClick = () => {
    setSelectedFile(null);
  };

  const createUser = async (param: any) => {
    const urlParams = {
      organizationId: authUserDetails.organizationId,
    };
    await httpPost(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.ADD_EMPLOYEE_USER, urlParams),
      param
    ).then(
      (response) => {
        if (response && response?.data) {
          if (response?.data?.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            handleAlertBar(
              "success",
              t("opva.newUserCreatedSuccessfully")
            );
            previousButton();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const updateUser = async (param: any) => {
    param["language"] = userData?.employeePreferredLanguage;
    param["languagesKnown"] = userData?.languagesKnown?.map((lan: any)=> lan?.language_name);
    const urlParams = {
      organizationId: authUserDetails.organizationId,
      employeeId: userId,
    };
    await httpPut(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.UPDATE_EMPLOYEE_USER,
        urlParams
      ),
      param
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            handleAlertBar("success", t("opva.updatedSuccessMsg"));
            previousButton();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };

  const handleBulkUser = async () => {
    const urlParams = {
      organizationId: authUserDetails.organizationId,
    };
    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile);
    }
    await httpPost(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.ADD_BULK_EMPLOYEES, urlParams),
      formData
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            handleAlertBar("success", t("opva.newUserCreatedSuccessfully"));
            previousButton();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleCreateUpdateUser = (e: { preventDefault: () => void }) => {
    if (isFormValid()) {
      const param = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        employeeId: formData.employeeId,
        mail: formData.mail,
        phone: formData.phone,
        roles: role ? role : [],
        departmentName: formData.departmentName ? formData.departmentName : "",
        location: formData.location,
        position: formData.position,
      };
      setFormData((prevData) => ({ ...prevData }));
      if (Props?.isEdit) {
        updateUser(param);
      } else {
        createUser(param);
      }
    } else {
      console.log("Form has errors. Please fill in all required fields.");
    }
  };

  const getRoles = () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_ROLES).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            console.log("roles plan list response-->", response.data);
            const roleList = response?.data?.filter(
              (e: any) => !e.applicationRole
            );
            setuserTypeList(roleList);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };

  const handleChange = (event: any, newValue: any) => {
    setSelectedUserType(newValue);
    const roleId = newValue.map((role: any) => role.id);
    setRole(roleId);
  };

  useEffect(() => {
    getRoles();
    if (userData) {
      for (const key in userData) {
        if (userData.hasOwnProperty(key) && userData[key] === null) {
          userData[key] = "";
        }
      }
      userData["roles"] = userData?.assignedRoles;
      const roleId = userData?.roles?.map((e: any) => e.id);
      userData["roles"] = roleId;
      userData["errors"] = {
        firstName: false,
        lastName: false,
        employeeId: false,
        mail: false,
        phone: false,
        roles: false,
        departmentName: false,
        location: false,
        position: false,
      };
      setRole(roleId);
      setFormData(userData);
      setSelectedUserType(userData.assignedRoles);
    }
  }, []);

  let roleList = [
    OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
    OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
    OPTIVAL_CONSTANTS.CONTENT_ADMIN,
    OPTIVAL_CONSTANTS.CONTENT_EDITOR,
    OPTIVAL_CONSTANTS.SUPERVISOR,
    OPTIVAL_CONSTANTS.EMPLOYEE,
  ];
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="user-management px-5 pt-2 mb-3">
        <Breadcrumb
          pathList={[
            { name: t("opva.management"), path: "management" },
            { name: t("opva.userManagement"), path: "newmanagement" },
          ]}
        />
        <Grid container alignItems="center" className="pt-2 pb-4">
          <Grid item xs md lg={6} className="d-flex align-items-center">
            <Link
              component="button"
              title={t("opva.backToUserManagement")}
              className="me-2"
              onClick={previousButton}
            >
              <KeyboardArrowLeftIcon fontSize="large" />
            </Link>
            <Typography variant="h3" className="mb-0">
              {Props.isEdit ? t("opva.updateUser") : t("opva.newUser")}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="user-management-content px-4">
        <Grid container>
          <Grid item xs={12} sm={12} md={7} lg={7} className="m-4">
            <form onSubmit={(e) => e.preventDefault()} noValidate>
              <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
                <Typography variant="bodyMedium" className="fw-bold mb-2">
                  {Props.isEdit ? t("opva.updateUser") : t("opva.addUser")}
                </Typography>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                spacing={2}
                className="mb-4"
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="bodySmall">
                    {t("opva.firstName")}
                    <span className="asterisk">*</span>
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      required
                      fullWidth
                      placeholder={t("opva.enterFirstName")}
                      variant="standard"
                      name="firstName"
                      value={formData?.firstName}
                      onChange={handleFormChange}
                      error={formData?.errors?.firstName}
                      helperText={
                        formData.errors.firstName &&
                        t("opva.enterFirstNameHelperText")
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="bodySmall">
                    {t("opva.lastName")}
                    <span className="asterisk">*</span>
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      required
                      fullWidth
                      placeholder={t("opva.enterLastName")}
                      variant="standard"
                      name="lastName"
                      value={formData?.lastName}
                      onChange={handleFormChange}
                      error={formData?.errors?.lastName}
                      helperText={
                        formData.errors.lastName &&
                        t("opva.enterLastNameHelperText")
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="bodySmall">
                    {t("opva.employeeId")}
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      required
                      fullWidth
                      placeholder={t("opva.enterEmpId")}
                      variant="standard"
                      name="employeeId"
                      value={formData?.employeeId}
                      onChange={handleFormChange}
                      error={formData?.errors?.employeeId}
                      // helperText={
                      //   formData.errors.employeeId &&
                      //   t("opva.enterEmpIdHelperText")
                      // }
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                spacing={2}
                className="mb-4"
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="bodySmall">
                    {t("opva.email")}
                    <span className="asterisk">*</span>
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      required
                      fullWidth
                      placeholder={t("opva.enterEmailPlaceholder")}
                      variant="standard"
                      name="mail"
                      value={formData?.mail}
                      onChange={handleFormChange}
                      error={formData?.errors?.mail}
                      helperText={
                        formData?.errors?.mail &&
                        t("opva.enterValidEmailHelperText")
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="bodySmall">
                    {t("opva.phoneNumber")}
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      required
                      fullWidth
                      placeholder={t("opva.enterPhoneNumber")}
                      variant="standard"
                      name="phone"
                      value={formData?.phone}
                      onChange={handleFormChange}
                      error={formData?.errors?.phone}
                      // helperText={
                      //   formData?.errors.phone &&
                      //   t("opva.enterPhoneNumberHelperText")
                      // }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="bodySmall">
                    {t("opva.userType")}
                    <span className="asterisk">*</span>
                  </Typography>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    fullWidth
                    limitTags={1}
                    className={
                      selectedUserType.length > 0 ? "auto-complete-chip" : ""
                    }
                    value={selectedUserType}
                    onChange={handleChange}
                    options={userTypeList}
                    disableCloseOnSelect
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    getOptionLabel={(option: any) => option?.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          disabled={option.id === 0}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={t("opva.selectUserType")}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                spacing={2}
                className="mb-4"
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="bodySmall">
                    {t("opva.department")}
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      placeholder={t("opva.enterDeparment")}
                      variant="standard"
                      name="departmentName"
                      value={formData?.departmentName}
                      onChange={handleFormChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="bodySmall">
                    {t("opva.location")}
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      placeholder={t("opva.enterLocation")}
                      variant="standard"
                      name="location"
                      value={formData?.location}
                      onChange={handleFormChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="bodySmall">{t("opva.role")}</Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      required
                      fullWidth
                      placeholder={t("opva.enterRole")}
                      variant="standard"
                      name="position"
                      value={formData?.position}
                      onChange={handleFormChange}
                      error={formData?.errors?.position}
                      // helperText={
                      //   formData?.errors?.position &&
                      //   t("opva.enterPositionHelperText")
                      // }
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                spacing={2}
                className="mb-4"
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Button
                    size="large"
                    variant="contained"
                    sx={{ color: "#FFFFFFCC", textTransform: 'none' }}
                    onClick={handleCreateUpdateUser}
                    disabled={!isFormValid()}
                  >
                    {Props.isEdit ? t("opva.updateUser") : t("opva.addUser")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          {Props.isEdit ? (
            <></>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={0.2} lg={0.2} className="m-2">
                <div className="user-dividers">
                  <Divider variant="middle" orientation="vertical" flexItem />
                </div>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className="mt-4"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
                  <Typography variant="bodyMedium" className="fw-bold mb-2">
                    {t("opva.addBulkUsers")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
                  <Typography variant="bodyMedium" sx={{ marginBottom: 3 }}>
                  {t("opva.step1")}: {t("opva.downloadFormatForBulkUsers")}
                  </Typography>
                </Grid>
                <Grid item>
                    <DownloadExcelButton
                      header={UserManagementConstants.BULK_USER_HEADER_LIST}
                      options={roleList}
                      optionCell={"F"}
                      listLength={50}
                      fileName={"BulkDatatemplate_users.xlsx"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}> <div className="bulkuser-dividers m-3">
                  <Divider variant="middle" orientation="horizontal" flexItem />
                </div></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="bodyMedium" sx={{ marginBottom: 3 }}>
                  {`${t("opva.step2")}: ${t("opva.fillUploadExcel")}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    className="upload-box-container"
                    onDragOver={handleUploadBoxDragOver}
                    onDrop={handleUploadBoxDrop}
                  >
                    <input
                      type="file"
                      accept=".xlsx"
                      hidden
                      id="user-file-upload"
                      onChange={handleFileChange}
                    />
                    <div className="d-flex align-self-center mb-2">
                      {!selectedFile && (
                        <label
                          htmlFor="user-file-upload"
                          className="file-upload-icon"
                        >
                          <FileUploadOutlinedIcon color="primary" />
                        </label>
                      )}
                      {selectedFile && (
                        <Chip
                          color="primary"
                          sx={{ minWidth: "auto", maxWidth: "250px" }}
                          label={selectedFile.name}
                          title={selectedFile.name}
                          onDelete={handleRemoveClick}
                        />
                      )}
                    </div>
                    <div className="d-flex align-self-center mb-2">
                      <label htmlFor="user-file-upload">
                        <Link
                          variant="bodyNormal"
                          underline="none"
                          className="me-1"
                        >
                          <b>{t("opva.clickHere")}</b>
                        </Link>
                        <Typography variant="bodyNormal">
                          {t("opva.uploadOrDropExcelFile")}
                        </Typography>
                      </label>
                    </div>
                  </div>
                  <div style={{ marginLeft: "25px" }}>
                    {errorMsg && (
                      <Typography variant="body1" color="error">
                        {`Error: ${errorMsg}`}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item className="mb-4">
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ color: "#FFFFFFCC", textTransform: 'none' }}
                      onClick={handleBulkUser}
                      disabled={!selectedFile}
                    >
                      {t("opva.confirmUpload")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};
