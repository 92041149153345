import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select,
  Chip,
  TextField,
  Link,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import "./personalAccount.scss";
import { useContext, useEffect, useState } from "react";
import { MyButton } from "../../../shared/components/Buttons/Button";
import { useAPI } from "../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { Breadcrumb } from "../../breadcrumb/components/Breadcrumb";
import { PersonalAccountConstants } from "./constants/PersonalAccountConstants";
import { updateURLParams } from "../../../shared/utils/Utils";
import { AuthenticationContext } from "../../../shared/Contexts";

export const ProfileAccount = () => {
  const { t, i18n } = useTranslation();
  const { authUserDetails } = useContext(AuthenticationContext);
  const { httpGet, httpPost, httpPut, handleAlertBar } = useAPI();
  const { checkRole } = useAPI();
  // const [selectedlanguage, setSelectedLanguages] = useState("");
  const [languagesKnown, setlanguagesKnown] = useState<any>("");
  const [languageList, setLanguageList] = useState<any>([]);
  const [languageKnownList, setlanguageKnownList]: any[] = useState([]);
  const [isEdit, SetIsEdit] = useState(false);
  const [isValidate, SetIsValidate] = useState(false);
  const [data, setData] = useState<any>({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    organizationName: "",
    employeePreferredLanguage: "",
    knownLanguages: [],
    mail: "",
    location: "",
    role: "",
    phone: "",
    errors: {
      firstName: false,
      lastName: false,
      organizationName: false,
      employeePreferredLanguage: false,
      knownLanguages: false,
      mail: false,
      location: false,
      role: false,
      phone: false,
    },
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [profileImgUrl, setProfileImgUrl] = useState(
    "images/default-picture.svg"
  );
  const [uploadedProfileDetail, setUploadedProfileDetail] = useState<any>(null);

  const isFormValid = () => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.mail);
    const isMobileValid = /^((\+\d{1,3}-)?\d{3}-\d{3}-\d{4}|\d{10})$/.test(
      formData.phone
    );

    return (
      formData.firstName.trim() !== "" &&
      formData.organizationName.trim() !== "" &&
      formData.role &&
      isEmailValid &&
      isMobileValid
    );
  };

  const handleChangeLanguage = (languageCode: string) => {
    sessionStorage.setItem(
      OPTIVAL_CONSTANTS.LANGUAGE_CODE_SESSION_KEY,
      languageCode
    );
    i18n.changeLanguage(languageCode);
  };

  const handleFormChange = (e: any) => {
    let { name, value } = e.target;
    let isError = false;
    if (name === "mail") {
      isError = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    } else if (name === "phone") {
      isError = !/^((\+\d{1,3}-)?\d{3}-\d{3}-\d{4}|\d{10})$/.test(value);
    } else if (name === "knownLanguages") {
      handleAddLanguage(value);
    } else if (name === "employeePreferredLanguage") {
      if (value === "") {
        value = "ENGLISH";
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      errors: { ...prevData.errors, [name]: isError },
    }));
  };
  const onFormSubmit = () => {
    if(uploadedProfileDetail) {
      updateProfileImg();
    }
    const rolesId =
      data.assignedRoles?.length > 0
        ? data.assignedRoles.map((e: any) => e.id)
        : [];
    const languageLists = languageKnownList?.map((e: any) => e.language_name);
    const payload = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      mail: formData?.mail,
      phone: formData?.phone,
      employeeId: authUserDetails?.employeeId,
      roles: rolesId,
      departmentName: data?.departmentName ? data?.departmentName : "",
      location: formData.location ? formData?.location : "",
      position: data?.position,
      language: formData?.employeePreferredLanguage,
      languagesKnown: ["ENGLISH", ...languageLists],
    };
    setFormData((prevData) => ({ ...prevData, success: true }));
    updateProfileData(payload);
  };

  const cancelButton = () => {
    SetIsEdit(false);
    fetchProfileData();
    setErrorMsg("");
  };

  const editProfile = () => {
    SetIsEdit(true);
    const roles =
      data.assignedRoles?.length > 0
        ? data.assignedRoles.map((e: any) => e.name).join(" , ")
        : "-";
    setFormData({
      firstName: data?.firstName,
      lastName: data?.lastName,
      organizationName: data?.organizationName,
      employeePreferredLanguage: data?.employeePreferredLanguage,
      knownLanguages: [],
      mail: data?.mail,
      location: data?.location,
      role: roles,
      phone: data?.phone,
      errors: {
        firstName: false,
        lastName: false,
        organizationName: false,
        employeePreferredLanguage: false,
        knownLanguages: false,
        mail: false,
        location: false,
        role: false,
        phone: false,
      },
    });
  };
  const validateProfile = () => {
    SetIsValidate(true);
  };
  const handleAddLanguage = async (lang: string) => {
    const addPrepreferredLanguageList = languageList?.filter(
      (items: any) => items.language_name === lang
    );
    const selectedLanguageIndex = languageKnownList.findIndex(
      (language: any) =>
        language.language_name === addPrepreferredLanguageList[0].language_name
    );
    if (selectedLanguageIndex === -1) {
      const list = [...languageKnownList, ...addPrepreferredLanguageList];
      setlanguageKnownList(list);
    }
  };

  const handleRemoveLanguage = async (lang: string) => {
    const removePrepreferredLanguageList = languageKnownList?.filter(
      (items: any) => items.language_name != lang
    );
    setlanguageKnownList(removePrepreferredLanguageList);
    if (formData.employeePreferredLanguage === lang) {
      formData.employeePreferredLanguage = "ENGLISH";
    }
  };

  const updateProfileData = async (param: any) => {
    const urlParams = {
      organizationId: authUserDetails.organizationId,
      employeeId: authUserDetails.employeeId,
    };
    await httpPut(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.UPDATE_EMPLOYEE_USER,
        urlParams
      ),
      param
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            setData(response.data);
            if (response?.data?.employeePreferredLanguage !== "ENGLISH") {
              const languageCode = languageList.filter(
                (e: any) =>
                  e.language_name === response?.data?.employeePreferredLanguage
              );
              handleChangeLanguage(languageCode[0].aws_language_code);
            } else {
              handleChangeLanguage(OPTIVAL_CONSTANTS.DEFAULT_LANGUAGE_CODE);
            }
            setErrorMsg("");
            cancelButton();
            handleAlertBar("success", t("opva.profileUpdateMsg"));
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };

  const updateProfileImg = () => {
    if(uploadedProfileDetail) {
      const formData = new FormData();
      formData.append("file", uploadedProfileDetail);
      httpPost(
        OPTIVAL_CONSTANTS.API_URLS.UPLOAD_PROFILE_PICTURE,
        formData
      ).then((response) => {
        if (response) {
          console.log("Image", response);
          setProfileImgUrl(URL.createObjectURL(uploadedProfileDetail));
          setErrorMsg("");
        }
      });
    }
  }

  const fetchProfileData = async () => {
    await httpGet(OPTIVAL_CONSTANTS.API_URLS.PROFILE_DATA).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            setData(response.data);
            const languagesKnownListDetail =
              response?.data?.languagesKnown?.filter(
                (e: any) => e.display_name !== "English"
              );
            setlanguageKnownList(languagesKnownListDetail);
            setProfileImgUrl(
              response.data.url
                ? response.data.url
                : "images/default-picture.svg"
            );
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };
  const fetchLanguageList = async () => {
    await httpGet(OPTIVAL_CONSTANTS.API_URLS.ORGANIZATION_LANGUAGE).then(
      (response) => {
        if (response && response?.data) {
          const languageListDetail = response?.data?.filter(
            (e: any) => e.display_name !== "English"
          );
          setLanguageList(languageListDetail);
        }
      },
      (error) => {}
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.target?.files?.[0];
    if (file?.type === "image/jpeg" || file?.type === "image/png") {
      setUploadedProfileDetail(file);
      const fileUrl = URL.createObjectURL(file);
      setProfileImgUrl(fileUrl);
      setErrorMsg("");
    } else {
      setErrorMsg(t("opva.errorMsgUploadImg"));
      setUploadedProfileDetail(null);
      setProfileImgUrl("images/default-picture.svg");
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    fetchLanguageList();
  }, []);

  return (
    <div className="personal-account-container">
      <div className="bg-light-blue px-5 pt-2 mb-3">
        <Breadcrumb pathList={[{ name: t("opva.account"), path: "profile" }]} />
        <Grid container className="d-flex justify-content-between pt-1 pb-3">
          <Grid item xs md lg={9}>
            <Typography variant="h3" className="pt-2">
              {t("opva.personalAccount")}
            </Typography>
          </Grid>
          <Grid item xs md lg={3} className="d-flex justify-content-end">
            {
            // checkRole([
            //   OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
            //   OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
            //   OPTIVAL_CONSTANTS.CONTENT_ADMIN,
            //   OPTIVAL_CONSTANTS.CONTENT_EDITOR,
            //   OPTIVAL_CONSTANTS.SUPERVISOR,
            // ]) &&
              (isEdit ? (
                <div>
                  <Button
                    variant="outlined"
                    className="m-2"
                    onClick={cancelButton}
                  >
                    {t("opva.cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    className="m-2"
                    onClick={onFormSubmit}
                  >
                    {t("opva.save")}
                  </Button>
                </div>
              ) : (
                <Link component="button" title="Edit" onClick={editProfile}>
                  <EditIcon />
                </Link>
              ))}
          </Grid>
        </Grid>
      </div>
      <div className="px-5">
        <Grid container>
          <Grid item xs={12} sm={6} md={1.5}>
            <Box className="image-section-container">
              <div className="image-container">
                <img src={profileImgUrl} alt="profile img" className="image" />
              </div>

              <input
                accept="image/*"
                id="file-input"
                type="file"
                className="file-input"
                onChange={handleFileChange}
              />
              {isEdit && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => document.getElementById("file-input")?.click()}
                  style={{ margin: "8px" }}
                >
                  {t("opva.changeImage")}
                </Button>
              )}
            </Box>
            <div>
              {errorMsg && (
                <Typography variant="labelSmall" color="error">
                  {`Error: ${errorMsg}`}
                </Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={10.5} className="right-section">
            <Grid container spacing={1} ml={1}>
              <Grid item xs={12} sm={6} md={4} className="pt-1 mb-3">
                <Typography variant="labelSmall" className="subtitle">
                  {t("opva.firstName")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterFirstName")}
                      variant="standard"
                      name="firstName"
                      value={formData?.firstName}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.firstName}
                      helperText={
                        formData.errors.firstName &&
                        t("opva.enterFirstNameHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data.firstName}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="pt-1 mb-3">
                <Typography variant="labelSmall" className="subtitle">
                  {t("opva.lastName")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterLastName")}
                      variant="standard"
                      name="lastName"
                      value={formData?.lastName}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.lastName}
                      helperText={
                        formData.errors.lastName &&
                        t("opva.enterLastNameHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data.lastName || "-"}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>
              {/* {isEdit ? (
              <Grid item xs={8} display={"flex"} container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="organization-details"
                >
                  <Typography variant="labelSmall" className="subtitle">
                    Last Name
                  </Typography>
                  {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder="Enter Organisation Name"
                      variant="standard"
                      name="name"
                      value={formData?.name}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.name}
                      helperText={
                        formData.errors.name &&
                        "Please enter the Organisation Name"
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data.lastName || "-"}</Typography>
                )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="organization-details"
                >
                  <Typography variant="labelSmall" className="subtitle">
                    Organization
                  </Typography>
                  <Typography variant="h6">
                    {data?.organizationName || "-"}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={5} className="organization-details">
                <Typography variant="labelSmall" className="subtitle">
                  Last Name
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder="Enter Organisation Name"
                      variant="standard"
                      name="name"
                      value={formData?.name}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.name}
                      helperText={
                        formData.errors.name &&
                        "Please enter the Organisation Name"
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data.lastName || "-"}</Typography>
                )}
              </Grid>
            )} */}
              <Grid item xs={12} sm={6} md={4} className="mt-3">
                <Typography variant="labelSmall" className="subtitle">
                  {t("opva.languagesKnown")}
                </Typography>
                {isEdit ? (
                  <>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <TextField
                        style={{ width: "60%" }}
                        select
                        placeholder={t("opva.selectLanguage")}
                        variant="standard"
                        name="knownLanguages"
                        value={languagesKnown || " "}
                        onChange={handleFormChange}
                        disabled={true}
                      >
                        <MenuItem value=" ">
                          {t("opva.selectLanguage")}
                        </MenuItem>
                        {languageList?.map((option: any, index: any) => (
                          <MenuItem key={index} value={option.language_name}>
                            {option.display_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="mt-1">
                      <Chip
                        label="English"
                        color="default"
                        size="medium"
                        variant="filled"
                      />
                      {languageKnownList?.map((language: any, index: any) => (
                        <Chip
                          color="default"
                          size="medium"
                          variant="filled"
                          className="m-2"
                          key={index}
                          label={language?.display_name}
                          onDelete={() =>
                            handleRemoveLanguage(language.language_name)
                          }
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <Typography variant="h6">
                    {data?.languagesKnown
                      ?.map((e: any) => e.display_name)
                      .join(" , ") || "English"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="mt-3">
                <Typography variant="labelSmall" className="subtitle">
                  {t("opva.languagesPreferred")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      select
                      placeholder={t("opva.selectPreferedLan")}
                      variant="standard"
                      name="employeePreferredLanguage"
                      value={formData?.employeePreferredLanguage || "ENGLISH"}
                      onChange={handleFormChange}
                    >
                      <MenuItem
                        value="ENGLISH"
                        // onClick={(e) =>
                        //   handleChangeLanguage(
                        //     OPTIVAL_CONSTANTS.DEFAULT_LANGUAGE_CODE
                        //   )
                        // }
                      >
                        English
                      </MenuItem>
                      {languageList.map((option: any, index: number) => (
                        <MenuItem
                          key={index}
                          value={option.language_name}
                          // onClick={(e) =>
                          //   handleChangeLanguage(option.aws_language_code)
                          // }
                        >
                          {option.display_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                ) : (
                  <Typography variant="h6">
                    {data?.employeePreferredLanguage || "-"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="mt-3">
                <Typography variant="labelSmall" className="subtitle">
                  {t("opva.organization")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterOrganizationName")}
                      variant="standard"
                      name="organizationName"
                      value={formData.organizationName}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.organizationName}
                      helperText={
                        formData?.errors?.organizationName &&
                        t("opva.enterOrgNameHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">
                    {data?.organizationName || "-"}
                  </Typography>
                )}
              </Grid>
              {/* {isEdit ? (
                <Grid item display={"flex"} container spacing={1}>
                  {languageKnown.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className="organization-details"
                    >
                      <Typography variant="h6">
                        Languages Known
                      </Typography>
                      {languageKnown.map((language: any, index: any) => (
                        <Chip
                          style={{ width: "100px", background: "#d5e9fa" }}
                          className="blue"
                          key={index}
                          label={language}
                          onDelete={() => handleRemoveLanguage(language)}
                        />
                      ))}
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className="organization-details"
                  >
                    <Typography variant="h6">
                      Languages Preferred
                    </Typography>
                    <FormControl style={{ width: "60%" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedlanguage}
                        onChange={(e) => setSelectedLanguages(e.target.value)}
                      >
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Hindi">Hindi</MenuItem>
                        <MenuItem value="Tamil">Tamil</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  display={"flex"}
                  container
                  spacing={1}
                  style={{ marginBottom: 12 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className="organization-details"
                  >
                    <Typography variant="labelSmall" className="subtitle">
                      Languages Known
                    </Typography>
                    <Typography variant="h6">
                      English,Kannada,Tamil
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className="organization-details"
                  >
                    <Typography variant="labelSmall" className="subtitle">
                      Languages Preferred
                    </Typography>
                    <Typography variant="h6">
                      {data?.employeePreferredLanguage || "-"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className="organization-details"
                  >
                    <Typography variant="labelSmall" className="subtitle">
                      Organization
                    </Typography>
                    <Typography variant="h6">
                      {data?.organizationName || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              )} */}
            </Grid>
            <Grid container spacing={1} className="mt-3" ml={1}>
              <Grid item xs={12} sm={6} md={4} className="mt-3">
                {/* { isEdit ? (<Typography variant="body1" variant="h6">
                Email
              </Typography>) : <Typography variant="labelSmall" className="subtitle">
                Email
              </Typography>} */}
                <Typography variant="labelSmall" className="subtitle">
                  {t("opva.email")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterEmailPlaceholder")}
                      variant="standard"
                      name="mail"
                      value={formData?.mail}
                      onChange={handleFormChange}
                      error={formData.errors.mail}
                      disabled={true}
                      helperText={
                        formData.errors.mail && t("opva.emailHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data.mail || "-"}</Typography>
                )}
                {/* { isEdit ? (
              <TextField style={{width: '60%'}} id="outlined-basic" label="jonh.doe@gmail.com" variant="outlined" />) : 
              <Typography variant="body1" variant="h6">
              John.doe@gmail.com
              </Typography> } */}
              </Grid>

              {/* { isEdit ? (
              <Grid item xs={8} display={"flex"} container spacing={1}>
                <Grid item xs={12} sm={6} md={6} className="organization-details">
                  <Typography variant="body1" variant="h6">
                    Password
                  </Typography>
                  <TextField style={{width: '60%'}} id="outlined-basic" label="*********" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="organization-details">
                  <Typography variant="body1" className="subtitle">
                    Phone Number
                  </Typography>
                  <TextField style={{width: '60%'}} id="outlined-basic" label="+91-99999-9999" variant="outlined" />
                </Grid>

              </Grid>
              ) : 
              <Grid item xs={12} sm={6} md={4} className="organization-details">
                <Typography variant="labelSmall" className="subtitle">
                  Phone Number
                </Typography>
                <Typography variant="body1" variant="h6">
                  +91-99999-99999
                </Typography>
              </Grid>
             } */}
              <Grid item xs={12} sm={6} md={4} className="mt-3">
                <Typography variant="labelSmall" className="subtitle">
                  {t("opva.phoneNumber")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterPhoneNumber")}
                      variant="standard"
                      name="phone"
                      value={formData.phone}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.phone}
                      helperText={
                        formData?.errors?.phone && t("opva.enterPhNoHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data.phone || "-"}</Typography>
                )}
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} className="organization-details">
              <Typography variant="labelSmall" className="subtitle">
                Password
              </Typography>
              <Typography variant="body1" variant="h6">
                ********
              </Typography>
            </Grid> */}
            </Grid>
            <Grid container spacing={1} ml={1}>
              <Grid item xs={12} sm={12} md={12} className="mt-5">
                <Typography variant="h3">{t("opva.otherDetails")}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="mt-3">
                <Typography variant="labelSmall" className="subtitle">
                  {t("opva.location")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterLocation")}
                      variant="standard"
                      name="location"
                      value={formData?.location}
                      onChange={handleFormChange}
                      error={formData?.errors?.location}
                      disabled={true}
                      helperText={
                        formData?.errors?.location &&
                        t("opva.enterLocationHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data?.location || "-"}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} className="mt-3">
                <Typography variant="labelSmall" className="subtitle">
                  {t("opva.role")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterRole")}
                      variant="standard"
                      name="role"
                      value={formData?.role}
                      onChange={handleFormChange}
                      error={formData.errors.role}
                      disabled={true}
                      helperText={
                        formData.errors.role && t("opva.enterRoleHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">
                    {data.assignedRoles?.length > 0
                      ? data.assignedRoles.map((e: any) => e.name).join(" , ")
                      : "-"}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {isValidate ? (
              <Grid container direction={"column"} spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className="organization-details"
                >
                  <Typography variant="h6">{t("opva.otp")}</Typography>
                  <TextField
                    style={{ width: "19.8%" }}
                    id="outlined-basic"
                    label={t("opva.enterOTPHere")}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className="organization-details"
                >
                  <MyButton
                    style={{ width: "19.8%", marginLeft: "1px" }}
                    className="custom-div"
                    label="Validate"
                    variant="contained"
                  />
                </Grid>
              </Grid>
            ) : (
              <div></div>
            )}
            {/* {isEdit || isValidate ? (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className="organization-details"
                >
                  <MyButton
                    style={{ width: "60%", marginLeft: "1px" }}
                    className="custom-div"
                    label="Save"
                    variant="contained"
                  />
                </Grid>
              </Grid>
            ) : (
              <div></div>
            )} */}
          </Grid>
        </Grid>
        {/* {isEdit ? (
          <div></div>
        ) : (
          <div className="ps-4">
            <Box
              display="flex"
              alignItems="center"
              marginBottom={3}
              marginTop={4}
            >
              <Typography variant="h3">Other Details</Typography>
              <Box flex="1" />
            </Box>
            <div style={{ display: "flex" }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className="organization-details"
                >
                  <Typography variant="labelSmall" className="subtitle">
                    Location
                  </Typography>
                  <Typography variant="h6">
                    {data?.location || "-"}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className="organization-details"
                >
                  <Typography variant="labelSmall" className="subtitle">
                    Role
                  </Typography>
                  <Typography variant="h6">
                    {data.assignedRoles?.length > 0
                      ? data.assignedRoles.map((e: any) => e.name).join(" , ")
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};
