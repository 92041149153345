import {
  Button,
  Card,
  CardMedia,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CREATE_WF_CONSTANTS } from "../../../models/CreateWorkflowConstant";
import "./IntroWorkflow.scss";
import { useTranslation } from "react-i18next";

export const IntroWorkflow = (Props: any) => {
  const { t } = useTranslation();
  const TAGSMenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  const [tag, setTag] = useState("");
  const [workflowSelectedFile, setWorkflowSelectedFile] = useState<
    File | any | null
  >(null);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    null
  );
  const [workflowTitle, setEnglishTitle] = useState("");
  const [workflowDescription, setEnglishDescription] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const handleUploadBoxDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleUploadBoxDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const dropFiles = e.dataTransfer.files[0];
    if (dropFiles.type === "image/jpeg" || dropFiles.type === "image/png") {
      setWorkflowSelectedFile(dropFiles);
      const fileUrl = URL.createObjectURL(dropFiles);
      setPreviewUrl(fileUrl);
      setErrorMsg("");
    } else {
      setErrorMsg(t("opva.errorMsgUploadImg"));
      setWorkflowSelectedFile(null);
      setPreviewUrl(null);
    }
  };

  const renderCloseIcon = () => (
    <CloseIcon
      color="primary"
      onClick={() => setWorkflowSelectedFile(null)}
      style={{
        position: "absolute",
        top: "5px",
        right: "5px",
        zIndex: "1",
      }}
    />
  );

  const handleWorkflowFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        if (file.type === "image/jpeg" || file.type === "image/png") {
          setWorkflowSelectedFile(file);
          const fileUrl = URL.createObjectURL(file);
          setPreviewUrl(fileUrl);
          setErrorMsg("");
        } else {
          setErrorMsg(t("opva.errorMsgUploadImg"));
          setWorkflowSelectedFile(null);
          setPreviewUrl(null);
        }
      }
    }
  };

  const handleWorkflowRemoveClick = () => {
    setWorkflowSelectedFile(null);
    setPreviewUrl(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setTag(event.target.value as string);
  };
  useEffect(() => {
    if (Props?.initialWorkflowData?.workflowTitle) {
      setEnglishDescription(
        Props?.tabValue === CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
          ? Props?.initialWorkflowData?.workflowDescription
          : Props?.initialWorkflowData?.translatedDescriptions &&
            Props?.initialWorkflowData.translatedDescriptions[Props?.tabValue]
          ? Props?.initialWorkflowData.translatedDescriptions[Props?.tabValue]
          : Props?.initialWorkflowData?.workflowDescription
      );
      setEnglishTitle(
        Props?.tabValue === CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
          ? Props?.initialWorkflowData.workflowTitle
          : Props?.initialWorkflowData?.translatedNames &&
            Props?.initialWorkflowData.translatedNames[Props?.tabValue]
          ? Props?.initialWorkflowData.translatedNames[Props?.tabValue]
          : Props?.initialWorkflowData?.workflowTitle
      );
      setWorkflowSelectedFile(Props?.initialWorkflowData?.workflowSelectedFile);
      const tagObj = Props?.tagList.find(
        (item: any) => +item.id === +Props?.initialWorkflowData?.tag
      );
      if (tagObj) {
        setTag(tagObj.id);
      } else {
        setTag("");
      }
    }
  }, [Props.initialWorkflowData, Props.tagList]);

  useEffect(() => {
    const introWorkflowData = {
      workflowTitle,
      workflowDescription,
      tag,
      workflowSelectedFile,
    };
    Props.workflowStepData(introWorkflowData);
  }, [workflowTitle, workflowDescription, tag, workflowSelectedFile]);
  return (
    <div className="intro-workflow-container bodyContainer">
      {!Props?.isUpdated ? (
        <Typography variant="bodyMedium">
          {t("opva.workflowDetails")}
        </Typography>
      ) : (
        <div></div>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          "& .MuiTextField-root": { m: 1, width: "320px" },
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg>
          <div
            className="UploadedContainer"
            onDragOver={handleUploadBoxDragOver}
            onDrop={handleUploadBoxDrop}
          >
            {!workflowSelectedFile && (
              <div id="uplodedContent">
                <div className="uploadimageContainer" style={{ zIndex: 0 }}>
                  {" "}
                  <input
                    accept="image/*,application/pdf,video/*"
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    onChange={handleWorkflowFileChange}
                  />
                  <label htmlFor="file-upload" className="file-upload-icon">
                    <FileUploadOutlinedIcon color="primary" />
                  </label>
                </div>

                <div style={{ zIndex: 2 }}>
                  {" "}
                  <input
                    accept="image/*,application/pdf,video/*"
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    onChange={handleWorkflowFileChange}
                  />
                  <label htmlFor="file-upload">
                    <Link
                      variant="bodyNormal"
                      underline="none"
                      className="me-1"
                    >
                      <b>{t("opva.clickHere")}</b>
                    </Link>
                    <span>{t("opva.uploadDropMedia")}</span>
                  </label>
                </div>
              </div>
            )}
            {workflowSelectedFile && (
              <div id="uplodedContentWithImage">
                {renderCloseIcon()}
                <Card
                  sx={{
                    position: "absolute",
                    zIndex: 0,
                    width: "354px",
                    height: "228px",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="100%"
                    image={previewUrl ? previewUrl : workflowSelectedFile}
                  />
                </Card>
                <div className="uploadimageContainer" style={{ zIndex: 0 }}>
                  {" "}
                  <input
                    accept="image/*,application/pdf,video/*"
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    onChange={handleWorkflowFileChange}
                  />
                  <label htmlFor="file-upload" className="file-upload-icon">
                    <FileUploadOutlinedIcon color="primary" />
                  </label>
                </div>

                <div style={{ zIndex: 2 }}>
                  {" "}
                  <input
                    accept="image/*,application/pdf,video/*"
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    onChange={handleWorkflowFileChange}
                  />
                  <label htmlFor="file-upload">
                    <Link
                      variant="bodyNormal"
                      underline="none"
                      className="me-1"
                    >
                      <b>{t("opva.clickHere")}</b>
                    </Link>
                    <span>{t("opva.uploadDropMedia")}</span>
                  </label>
                </div>
              </div>
            )}
          </div>
          <div style={{ marginLeft: "25px" }}>
            {!errorMsg && (
              <Typography variant="bodyMedium">
                {t("opva.uploadNewImage")}
              </Typography>
            )}
            {errorMsg && (
              <Typography variant="body1" color="error">
                {`Error: ${errorMsg}`}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid container item xs sm md={6} lg={8} spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="title-text">
              <Typography variant="h6">{t("opva.title")}</Typography>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  variant="outlined"
                  placeholder={t("opva.enterWorkflowTitle")}
                  sx={{
                    width: "320px",
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  } }}
                  value={workflowTitle}
                  onChange={(e) => {
                    setEnglishTitle(e.target.value);
                  }}
                />
                <Link
                  component="button"
                  className="ms-2"
                  title="Play Audio"
                  onClick={(e) => Props?.getAudioPlayURL(workflowTitle)}
                  disabled={!workflowTitle}
                >
                  <PlayCircleOutlineIcon
                    color={!workflowTitle ? "disabled" : "primary"}
                  />
                </Link>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md lg>
            <div className="title-text">
              <Typography variant="h6">{t("opva.description")}</Typography>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  variant="outlined"
                  placeholder={t("opva.enterDescription")}
                  value={workflowDescription}
                  onChange={(e) => {
                    setEnglishDescription(e.target.value);
                  }}
                  sx={{
                    width: "320px",
                    "& .MuiInputBase-input": {
                      // overflow: "hidden",
                      textOverflow: "ellipsis",
                      // whiteSpace: "nowrap",
                    } }}
                  multiline
                  rows={4}
                />
                <Link
                  component="button"
                  className="ms-2"
                  title="Play Audio"
                  onClick={(e) => Props?.getAudioPlayURL(workflowDescription)}
                  disabled={!workflowDescription}
                >
                  <PlayCircleOutlineIcon
                    color={!workflowDescription ? "disabled" : "primary"}
                  />
                </Link>
              </div>
            </div>
          </Grid>
          {Props?.tabValue === CREATE_WF_CONSTANTS.ENGLISH.toUpperCase() ? (
            <Grid
              item
              xs={12}
              sm={12}
              md
              lg
              sx={{
                "& .MuiGrid-item": {
                  paddingTop: "0px",
                },
              }}
            >
              <div className="title-text">
                <Typography variant="h6">{t("opva.tags")}</Typography>
                <div className="select-field">
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      placeholder={t("opva.selectTags")}
                      onChange={handleChange}
                      IconComponent={KeyboardArrowDownIcon}
                      value={tag}
                      MenuProps={TAGSMenuProps}
                    >
                      <MenuItem value="">{t("opva.selectTags")}</MenuItem>
                      {Props.tagList.map((item: any, index: number) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
