import { Grid, Skeleton } from "@mui/material";

export const OrganisationGroupsLoading = () => {
  const loadContentList: number[] = Array.from({ length: 4 });
  return (
    <>
      {loadContentList.map((data, index: number) => (
        <Grid item xs={3} md={3} key={index}>
          <section className="organisation-management">
            <Skeleton
              variant="rectangular"
              height={20}
              width={"50%"}
              className="mb-3"
            />
            <Skeleton
              variant="rectangular"
              height={15}
              width={"75%"}
              className="mb-3"
            />
            <div className="d-flex align-items-center">
              <Skeleton variant="circular" width={40} height={40} />
            </div>
          </section>
        </Grid>
      ))}
    </>
  );
};
