import { configureStore } from "@reduxjs/toolkit";
import headerSearchReducer from "./reducers/HeaderSearchReducer";
import UserSelectedReducer from "./reducers/UserSelectedReducer";
import PassDataReducer from "./reducers/PassDataReducer";

const store = configureStore({
  reducer: {
    passData: PassDataReducer,
    headerSearch: headerSearchReducer,
    userSelect: UserSelectedReducer,
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
