import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import "./Notification.scss";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useAPI } from "../../../../shared/services/api/API";
import { useContext, useEffect, useState } from "react";
import { NotificationModel } from "../../models/Notification";
import { updateURLParams } from "../../../../shared/utils/Utils";
import { useTranslation } from "react-i18next";
import { NotificationContext } from "../../contexts/NotificationContext";

export const Notification = () => {
  const { t } = useTranslation();
  const { httpGet, httpPut, handleAlertBar } = useAPI();

  const [notificationData, setNotificationData] = useState<NotificationModel[]>(
    []
  );
  const { updateHaveUnreadNotifications } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(true);

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: "sendAt",
    isDescending: true,
  };

  const fetchNotificationData = async () => {
    setIsLoading(true);
    await httpGet(
      OPTIVAL_CONSTANTS.API_URLS.GET_NOTIFICATIONS,
      queryParams
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
            setIsLoading(false);
          } else {
            const unreadNotification = response?.data?.haveUnreadNotifications;
            setNotificationData(response.data.notifications);
            updateHaveUnreadNotifications(unreadNotification);
            setIsLoading(false);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
          setIsLoading(false);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  const handleClick = async (notificationId: number) => {
    setIsLoading(true);
    const urlParams = {
      notificationId: notificationId,
    };
    await httpPut(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.MARK_AS_READ_NOTIFICATION,
        urlParams
      ),
      null
    ).then(
      (response) => {
        if ((response && response.data) || response.status) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
            setIsLoading(false);
          } else {
            console.log(response.data);
            fetchNotificationData();
            setIsLoading(false);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
          setIsLoading(false);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  const extractDate = (dateString: string) => {
    const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  useEffect(() => {
    fetchNotificationData();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <h3 className="heading">{t("opva.notification")}</h3>
      </div>

      <div className="notication-container">
        {isLoading ? (
          <Skeleton />
        ) : (
          <Grid>
            {notificationData && notificationData.length ? (
              notificationData.map((notification: NotificationModel) => (
                <div onClick={(e) => handleClick(notification.id)}>
                  <Grid item xs={12} mb={3} key={notification.id}>
                    <div className="notification-sub-container mt-2">
                      <div style={{ alignItems: "left" }}>
                        <Typography display="inline" variant="h6">
                          {notification.title}
                        </Typography>
                        {notification.status === "unread" ? (
                          <Chip
                            label={notification.status}
                            color="default"
                            size="medium"
                            variant="filled"
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <Typography variant="caption" align="right">
                        {extractDate(notification.sendAt)}
                      </Typography>
                    </div>
                    <Box mt={2} mb={1}>
                      <Typography variant="subtitle2">
                        {notification.message}
                      </Typography>
                    </Box>
                    {/* <Box m={2}>
                <Button variant="contained">CALL TO ACTION</Button>
              </Box> */}
                    <div className="notification-dividers">
                      <Divider
                        variant="middle"
                        flexItem
                        orientation="horizontal"
                      />
                    </div>
                  </Grid>
                </div>
              ))
            ) : (
              <div className="no-notification">
                <h6>{t("opva.noNotification")}</h6>
              </div>
            )}
          </Grid>
        )}
      </div>
    </>
  );
};
