import { Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const LoginHereLink = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <div
      className="login-here-container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Typography variant="bodySmall">
        {t("opva.alreadyHaveAccount")}
      </Typography>
      <Typography variant="bodySmall">
        {t("opva.login")} {" "}
        <Link variant="h6" onClick={handleLogin}>
          {t("opva.here")}
        </Link>
      </Typography>
    </div>
  );
};
