import { Grid, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./SkillsDetail.scss";
import { CollapseRowProps } from "../../models/UserManagement";
import { UserManagementConstants } from "../../constants/UserManagementConstant";
import { useTranslation } from "react-i18next";

export const SkillsDetail = (props: CollapseRowProps) => {
  const { t } = useTranslation();
  return (
    <div className="skills-container px-5 py-4">
      <Grid container>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className="mb-3">
            {t("opva.skillsOfThisUser")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Link
            component="button"
            className="float-end"
            title="Close"
            onClick={props.collapseClose}
          >
            <CloseIcon />
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="bodyNormal" className="mb-3">
            {props.skillNames?.length > 0
              ? props.skillNames.join(", ")
              : t("opva.noSkillsAvailableUser")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
