import { TableBody, TableRow, TableCell, Skeleton } from "@mui/material";
import { TableLoadingModel } from "../../../models/TableLoadingModel";

export const TableLoading = (props: TableLoadingModel) => {
  const columnList: number[] = Array.from({ length: props.column });
  const rowList: number[] = Array.from({ length: props.row || 10 });
  return (
    <TableBody>
      {rowList.map((row: number, rowIndex: number) => (
        <TableRow key={rowIndex}>
          {columnList.map((column: number, columnIndex: number) => (
            <TableCell key={columnIndex}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};
