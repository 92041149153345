import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAPI } from '../../../../../shared/services/api/API';
import { SpinnerContext } from '../../../../../shared/SpinnerContext';
import { SharedApi } from '../../../../../shared/services/shared-api/SharedApi';
import { SkillsContext } from '../../../contexts/SkillsContext';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Button, Chip, debounce, Grid, InputAdornment, Link, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { OPTIVAL_CONSTANTS } from '../../../../../shared/Constants';
import { Order, TableHeadCell } from '../../../../../shared/models/TableModels';
import { TableLoading } from '../../../../../shared/components/skeleton/table-loading/TableLoading';
import SearchIcon from "@mui/icons-material/Search";
import { dummySkillsLevels, ISkillsLevel } from '../../../models/SkillsData';
import { tableSorting } from '../../../../../shared/utils/Utils';

export const GapAnalysisByGroup = () => {
    const { t } = useTranslation();
    const { httpGet, httpPost, handleAlertBar } = useAPI();
    const { isSpinnerShow } = useContext(SpinnerContext);
    const [skillsMatrixHeader, setSkillsMatrixHeader] = useState<any>([]);
    const [skillsLevels, setSkillsLevels] = useState<ISkillsLevel[]>(dummySkillsLevels);
    const [tableData, setTableData] = useState<any>([]);
    const { getColorForLevel } = SharedApi();
    const [groupsList, setGroupsList] = useState<any>([]);
    const [selectGroupsList, setSelectGroupsList]: any[] = useState([]);
    const [isSkillSearching, setIsSkillSearching] = useState(false);
    const [searchGroupName, setGroupName] = useState<string>("");
    const { updateSkillsFilter } = useContext(SkillsContext);
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [displayTableData, setDisplayTableData] = useState<any[]>([]);
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<string>("name");

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
      const startIndex = newPage * rowsPerPage;
      setDisplayTableData([
        tableData.slice(startIndex, startIndex + rowsPerPage),
      ]);
    };
  
    useMemo(() => {
      const propertyType = orderBy === "usersAssigned" ? "number" : "string";
      const sortedList = tableSorting(tableData, order, orderBy, propertyType);
      setTableData(sortedList);
      setDisplayTableData(
        sortedList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      );
    }, [order, orderBy, page, tableData]);
  
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      setDisplayTableData(tableData?.slice(0, parseInt(event.target.value, 10)));
    };

  const fetchSkillsGroupList = useMemo(
    () =>
      debounce((searchUser: string) => {
        setIsSkillSearching(true);
        const queryParams = {
          query: searchUser,
        };
        const alreadySelectedGroupId = selectGroupsList?.map((e: any) => e?.groupId);
        const requestBody = {
            groupIds: alreadySelectedGroupId
        }
        httpPost(OPTIVAL_CONSTANTS.API_URLS.SEARCH_GROUP,requestBody, queryParams).then(
            (response: any) => {
              setIsSkillSearching(false);
              if (response && response.data && !response.data?.error) {
                setGroupsList(response.data);
              } else {
                handleAlertBar(
                  "error",
                  response?.data?.message || t("opva.serviceUnavailable")
                );
              }
            },
            (error: any) => {
              setIsSkillSearching(false);
              handleAlertBar(
                "error",
                error.message || t("opva.serviceUnavailable")
              );
            }
          );
      }, 500),
    [selectGroupsList]
  );

  const fetchGroupsTarget = async (selectedGroupsList?: any) => {
    const groupId = selectedGroupsList?.map((group: any) => group?.groupId);
    let payload = {
        groupIds: groupId,
        page: 0,
        size: 10000
      }

    await httpPost(
     OPTIVAL_CONSTANTS.API_URLS.POST_TARGET_SKILL_BY_GROUP,
      payload
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
            // Extract levels , skill & targets
            const group = response?.data[0]?.group;
            const levels = response?.data[0]?.levels || dummySkillsLevels;
            const skillsMap = response?.data[0]?.skills?.reduce(
              (acc: any, skill: any) => {
                acc[skill.id] = skill.name;
                return acc;
              },
              {}
            );
            const targets = response?.data[0]?.targets;
            // Generate table header
            const tableHeader = [{ id: 0, label: "", subLabel: "" }];
            levels?.forEach((level: any) => {
              tableHeader.push({
                id: level.id,
                label: level.name,
                subLabel: level.description,
              });
            });
            setSkillsLevels(levels);
            setSkillsMatrixHeader(tableHeader);
            //Generate table data
            const tableData = targets?.map((row: any) => {
              const rowData: any = {};
              rowData["skillName"] = skillsMap[row.skillId];
              rowData["skillId"] = row.skillId;
              rowData["groupId"] = group?.id;
              row.levels?.map((level: any) => {
                rowData[level.id] = level;
              });
              return rowData;
            });
            setTableData(tableData);
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fetchGroupsTarget();
  }, []);

  useEffect(() => {
    fetchSkillsGroupList('');
  }, [selectGroupsList]);

  const skillsTargetSave = async () => {
    const payload = {
      groupSkillTargets: tableData.map((item: any) => ({
        groupId: item.groupId,
        skillId: item?.skillId,
          levels: skillsLevels.map((level: any) => ({
            skillLevelId: item[level.id]?.id,
            target: +item[level.id]?.target,
          }))
      })),
    };
    await httpPost(OPTIVAL_CONSTANTS.API_URLS.SAVE_TARGET_SKILL_BY_GROUP, payload).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", "Target is not save");
        } else {
          handleAlertBar("success", "Save Target Successfully");
          fetchGroupsTarget(selectGroupsList);
        }
      },
      (error) => {}
    );
  };

  const handleSelectGroups = (e: any) => {
    handleAddGroupTarget(e?.groupId);
  };

  const handleAddGroupTarget = async (groupId: number) => {
    const addGroupsTarget = groupsList?.filter((items: any) => items.groupId === groupId);
    setSelectGroupsList(addGroupsTarget);
    fetchGroupsTarget(addGroupsTarget);
  };

  const handleRemoveGroup = async (groupId: number) => {
    const removeSelectGroupsList = selectGroupsList?.filter(
      (items: any) => items.groupId != groupId
    );
    setSelectGroupsList(removeSelectGroupsList);
    fetchGroupsTarget(removeSelectGroupsList);
  };

  const itemChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    levelId: any,
    index: any,
    key: any
  ) => {
    const { value } = event.target;
    let target = parseInt(value, 10);
    if (isNaN(target) || target < 0) {target = 0};
    setTableData((prevData: any) => {
      const updatedTarget = [...prevData];
      updatedTarget[index] = {
        ...updatedTarget[index],
        [levelId]: { ...updatedTarget[index][levelId], [key]: target },
      };
      return updatedTarget;
    });
  };

  const goToWorkflow = (row: any) => {
    updateSkillsFilter({id: row?.skillId, label: row.skillName});
    navigate('../instrukt', {state: {row: {id: row?.skillId, label: row.skillName}}})
  }

  return (
    <React.Fragment>
      <Grid
        container
        display={"flex"}
        justifyContent={"space-between"}
        className="pb-2"
        spacing={1}
      >
        <Grid item>
          <Typography variant="h6">{`${t("opva.step1")}: ${t("opva.selectGroups")}`}</Typography>
        </Grid>
      </Grid>

      <Box className="mb-4">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Autocomplete
            id="search-by-skill"
            className="me-5 w-25"
            options={groupsList ? groupsList : []}
            autoComplete
            noOptionsText={
              !groupsList ? (
                <Typography variant="labelNormal">
                  {t("opva.type2Letters")}
                </Typography>
              ) : (
                <Typography variant="labelNormal">
                  {t("opva.NoGroupsDataAvailable")}
                </Typography>
              )
            }
            getOptionLabel={(option: any | string) => searchGroupName}
            renderOption={(props, option: any) => (
              <li {...props} key={option.groupId} value={option.groupId}>
                <Typography variant="labelLarge">{option.name}</Typography>
              </li>
            )}
            value={null}
            onChange={(event: any, value: any | null) => {
              if (value && typeof value === "object") {
                handleSelectGroups(value);
              }
            }}
            onInputChange={(event: any, newInputValue: string) => {
              setGroupName(newInputValue);
              // if (newInputValue && newInputValue.length > 2) {
                fetchSkillsGroupList(newInputValue);
              // }
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size="small"
                variant="standard"
                placeholder={t("opva.selectGroup")}
                className="search-name-text"
                InputProps={{
                  ...params.InputProps,
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <SearchIcon color="primary" />
                  //   </InputAdornment>
                  // ),
                }}
              />
            )}
          />
        </div>
        <div className="mt-1">
          {selectGroupsList?.map((group: any, index: any) => (
            <Chip
              color="default"
              size="medium"
              variant="filled"
              className="m-2"
              key={index}
              label={group?.name}
              onDelete={() => handleRemoveGroup(group.groupId)}
            />
          ))}
        </div>
      </Box>
      <Box className="mb-4">
        <Typography variant="h6">{`${t("opva.step2")}: ${t("opva.reviewCurrentTarget")}`}</Typography>
      </Box>


      <Box sx={{ width: "100%", typography: "body1" }}>
      <TableContainer className="table-container mb-4">
          <Table stickyHeader size="medium" aria-label="skills table">
          <TableHead>
              <TableRow>
                {skillsMatrixHeader.map((headCell: TableHeadCell) =>
                  headCell.label !== "" ? (
                    <React.Fragment key={headCell.id}>
                      <TableCell
                        colSpan={4}
                        key={headCell.id}
                        align={"center"}
                        sx={{
                          backgroundColor: getColorForLevel(headCell.id),
                        }}
                      >
                        {headCell.id
                          ? `${t(headCell.id)} - ${t(`opva.${headCell.label}`)} `
                          : `${t(headCell.label)}`}
                      </TableCell>
                    </React.Fragment>
                  ) : (
                    <TableCell key={headCell.id} sx={{ width: "30%", backgroundColor: '#fafbfc' }}>
                      {t(headCell.label)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                {skillsMatrixHeader.map((headCell: TableHeadCell) =>
                  headCell.label !== "" ? (
                    <React.Fragment key={headCell.id}>
                      <TableCell align={"center"} sx={{ backgroundColor: '#fafbfc' }}>{t("opva.current")}</TableCell>
                      <TableCell align={"center"}sx={{ backgroundColor: '#fafbfc' }}>{t("opva.target")}</TableCell>
                      <TableCell align={"center"}sx={{ backgroundColor: '#fafbfc' }}>{t("opva.progress")}</TableCell>
                      <TableCell align={"center"}sx={{ backgroundColor: '#fafbfc' }}>{t("opva.action")}</TableCell>
                    </React.Fragment>
                  ) : (
                    <TableCell key={headCell.id} sx={{ width: "30%",backgroundColor: '#fafbfc'}}>
                      {t("opva.skillsName")}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            {isSpinnerShow ? (
              <TableLoading column={skillsMatrixHeader.length} />
            ) : (
              <TableBody>
                {displayTableData && displayTableData?.length ? (
                  displayTableData.map((row: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ width: "30%" }}>
                        {row?.skillName}
                      </TableCell>
                      {skillsLevels?.map((level: any) => (
                        <React.Fragment key={level?.id}>
                          <TableCell align={"center"}>
                            {row?.[level.id]?.current}
                          </TableCell>
                          <TableCell align={"center"}>
                            <TextField
                              id='editInput'
                              variant="outlined"
                              sx={{height: '32px', marginTop:'4px', width: '100%'}}
                              value={row?.[level.id]?.target || 0}
                              onChange={(event: any) =>
                                itemChangeHandler(
                                  event,
                                  level?.id,
                                  index,
                                  "target"
                                )
                              }
                              inputProps={{
                                style: {
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: '6px'
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell align={"center"}>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 0C19.2073 3.8247e-08 22.3406 0.963929 24.9933 2.76671L22.745 6.07503C20.7555 4.72295 18.4055 4 16 4V0Z"
                                fill={+row?.[level.id]?.progress >= 10 ? "#0084F4" : "#B7CBCB"}
                              />
                              <path
                                d="M25.4061 3.05687C28.0007 4.9424 29.9687 7.56417 31.0547 10.582L27.2911 11.9365C26.4765 9.67313 25.0005 7.7068 23.0546 6.29265L25.4061 3.05687Z"
                                fill={+row?.[level.id]?.progress >= 20 ? "#0084F4" : "#B7CBCB"}
                              />
                              <path
                                d="M31.1934 10.984C32.1989 14.0296 32.2659 17.3072 31.3856 20.3913L27.5392 19.2935C28.1994 16.9804 28.1492 14.5222 27.3951 12.238L31.1934 10.984Z"
                                fill={+row?.[level.id]?.progress >= 30 ? "#0084F4" : "#B7CBCB"}
                              />
                              <path
                                d="M31.2457 20.8548C30.2725 23.9109 28.4033 26.604 25.8806 28.5846L23.4105 25.4385C25.3025 23.953 26.7044 21.9332 27.4343 19.6411L31.2457 20.8548Z"
                                fill={+row?.[level.id]?.progress >= 40 ? "#0084F4" : "#B7CBCB"}
                              />
                              <path
                                d="M25.4839 28.8863C22.9007 30.7874 19.8058 31.8683 16.6008 31.9887L16.4506 27.9915C18.8544 27.9012 21.1756 27.0905 23.1129 25.6647L25.4839 28.8863Z"
                                fill={+row?.[level.id]?.progress >= 50 ? "#0084F4" : "#B7CBCB"}
                              />
                              <path
                                d="M16 32C12.7927 32 9.65938 31.0361 7.00667 29.2333L9.255 25.925C11.2445 27.2771 13.5945 28 16 28V32Z"
                                fill={+row?.[level.id]?.progress >= 60 ? "#0084F4" : "#B7CBCB"}
                              />
                              <path
                                d="M6.67933 29.0048C4.07242 27.1364 2.0872 24.5277 0.981268 21.517L4.73595 20.1378C5.5654 22.3957 7.05431 24.3523 9.0095 25.7536L6.67933 29.0048Z"
                                fill={+row?.[level.id]?.progress >= 70 ? "#0084F4" : "#B7CBCB"}
                              />
                              <path
                                d="M0.816424 21.0457C-0.195024 18.002 -0.268385 14.7246 0.605859 11.6388L4.45439 12.7291C3.79871 15.0435 3.85373 17.5015 4.61232 19.7843L0.816424 21.0457Z"
                                fill={+row?.[level.id]?.progress >= 80 ? "#0084F4" : "#B7CBCB"}
                              />
                              <path
                                d="M0.742254 11.1832C1.70781 8.12471 3.57029 5.42695 6.08804 3.44003L8.56603 6.58002C6.67772 8.07022 5.28086 10.0935 4.55669 12.3874L0.742254 11.1832Z"
                                fill={+row?.[level.id]?.progress >= 90 ? "#0084F4" : "#B7CBCB"}
                              />
                              <path
                                d="M6.50628 3.12097C9.08798 1.21788 12.1821 0.134623 15.387 0.0117463L15.5403 4.00881C13.1365 4.10097 10.816 4.91341 8.87971 6.34073L6.50628 3.12097Z"
                                fill={+row?.[level.id]?.progress >= 100 ? "#0084F4" : "#B7CBCB"}
                              />
                              <text x="50%" y="50%" textAnchor="middle" dominantBaseline={"middle"} fontSize={+row?.[level.id]?.progress >= 100 ? "8" : "10"} fill="black">{`${row?.[level.id]?.progress}%`}</text>
                            </svg>
                          </TableCell>
                          <TableCell align={"center"}>
                            <Link component="button" title={t("opva.action")}>
                            <img src={"images/" + 'doubleArrowUp.svg'} alt="Profile" width={'16px'} height={'14px'} onClick={() => goToWorkflow(row)} />
                            </Link>
                          </TableCell>
                        </React.Fragment>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={18} align="center">
                        {t("opva.selectGroupSkillShowMsg")}
                    </TableCell>

                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          className="pagination-container mb-4"
          component="div"
          count={tableData?.length || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5,10,15]}
        />
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-start"}
          className="pt-2 pb-2"
          spacing={1}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={skillsTargetSave}
              sx={{textTransform: 'none'}}
              disabled={!tableData}
            >
              {t("opva.saveTarget")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}