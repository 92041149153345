import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import "./Share.scss";
import { CollapseRowProps, WorkflowConstants } from "../../models/Workflow";
import { useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { emailValidator } from "../../../../shared/utils/Utils";
import { AuthenticationContext } from "../../../../shared/Contexts";
import { useAPI } from "../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { find } from "lodash";
import { useTranslation } from "react-i18next";

export const Share = (props: CollapseRowProps) => {
  const { authUserDetails } = useContext(AuthenticationContext);
  const { httpPost, handleAlertBar } = useAPI();
  const [emailIDs, setEmailIDs] = useState<string>("");
  const [emailIdError, setEmailIdError] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const linkToCopy = "https://example.com";
  const { t } = useTranslation();

  const handleCopyClick = () => {
    // Logic to copy the link
    navigator.clipboard.writeText(linkToCopy);

    // Show the Snackbar
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getWorkflowVersionId = (): number => {
    let workflowVersionId = 0;
    if (props.workflowData && props.workflowData?.workflowVersions) {
      return (
        find(props.workflowData.workflowVersions, [
          "status",
          WorkflowConstants.PUBLISHED,
        ])?.id || 0
      );
    }
    return workflowVersionId;
  };

  const validateEmailID = (emailIds: string[]): boolean => {
    let emailError = false;
    for (const emailId of emailIds) {
      emailError = emailValidator(emailId.trim());
      if (emailError) break;
    }
    return emailError;
  };

  const handleShareWorkflow = () => {
    let emailIDList = emailIDs.split(",");
    if (validateEmailID(emailIDList)) {
      setEmailIdError(true);
      return;
    }
    emailIDList = emailIDList.map((emailId: string) => emailId.trim());
    if (emailIDList.length > 5) {
      handleAlertBar(
        "warning",
        t("opva.workflowShareEmailMaxLimitReached")
      );
      return;
    }
    const queryParams = {
      mails: emailIDList.join(","),
    };
    const requestBody = {
      organizationId: authUserDetails.organizationId,
      employeeId: authUserDetails.employeeId,
      workflowId: props.workflowId,
      groupId: 0,
      workflowVersionId: getWorkflowVersionId(),
      workflowStepId: 0,
    };
    httpPost(
      OPTIVAL_CONSTANTS.API_URLS.SHARE_WORKFLOW,
      requestBody,
      queryParams
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
        } else {
          setEmailIDs("");
          handleAlertBar(
            "success",
            t("opva.workflowSharedSuccessMsg")
          );
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  return (
    <div className="share-container px-5 py-4">
      <Grid container>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className="mb-3">
            {t("opva.share")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            style={{ position: "absolute", top: "-2px" }}
          >
            <Alert
              icon={false}
              onClose={handleClose}
              severity="success"
              color="info"
            >
             {t("opva.linkCopied")}
            </Alert>
          </Snackbar>
          <Link
            component="button"
            className="float-end"
            title="Close"
            onClick={props.collapseClose}
          >
            <CloseIcon />
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <section className="d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center">
              <FormControl variant="standard" error={emailIdError}>
                <TextField
                  id="standard-multiline-static"
                  aria-describedby="email-ids-helper-text"
                  error={emailIdError}
                  focused={emailIdError}
                  fullWidth
                  multiline
                  rows={4}
                  placeholder={t("opva.enterEmailIDs")}
                  variant="standard"
                  value={emailIDs}
                  onChange={(e) => setEmailIDs(e.target.value)}
                  onClick={(e) => {
                    if (emailIdError) setEmailIdError(false);
                  }}
                  sx={{ width: 500 }}
                />
                <FormHelperText id="email-ids-helper-text">
                  {emailIdError
                    ? t("opva.provideValidEmailId's")
                    : t("opva.addEmailId'sByComma")}
                </FormHelperText>
              </FormControl>
              <div className="ms-5">
                <Button
                  variant="contained"
                  size="medium"
                  className="px-5 py-2"
                  sx={{ width: 200 }}
                  disabled={!emailIDs}
                  onClick={handleShareWorkflow}
                >
                  {t("opva.share")}
                </Button>
              </div>
            </div>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};
