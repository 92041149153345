import { Divider, Grid, Typography } from "@mui/material";
import "./Sign.scss";
import { LoginHereLink } from "../../../../shared/components/login-here-link/LoginHereLink";
const Successful = () => {
  return (
    <Grid container spacing={0}>
      <div className="subContainer">
        <div className="signUpContainer">
          <Grid item xs={12} sm={12} md={5.3} lg>
            <div className="leftSideContainer">
              <Grid item xs={12} sm={12} md lg>
                <img
                  src="images/Logo.jpg"
                  alt="Company Logo"
                  className="logo-container"
                />
              </Grid>
              <Grid item xs={12} sm={12} md lg>
                <Typography variant="bodySmall" className="sub-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
              </Grid>
              <div
                className="mt-5"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={12} sm={12} md lg>
                  <LoginHereLink />
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={0.01} lg={0.01}>
            <div className="Dividers">
              <Divider variant="fullWidth" flexItem />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="successful-right-side-container">
              <Grid item xs={12} sm={12} md lg>
                <div className="successful-heading">
                  <Typography variant="h3" style={{ lineHeight: "36.4px" }}>
                    Submitted Successfully!
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md lg>
                <div>
                  <img
                    src="images/successfull.png"
                    alt="Company Logo"
                    className="company-logo-image-successful-container"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md lg mb={3}>
                <div className="heading6">
                  <Typography variant="h6" style={{ lineHeight: "20.8px" }}>
                    What’s next?
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md lg>
                <div className="successful-content">
                  <Typography
                    variant="bodySmall"
                    className="bodySmall-content-successful"
                    style={{ textAlign: "center" }}
                  >
                    Wait for our Sales team to contact you soon and set up the
                    account custom for your requirement
                  </Typography>
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default Successful;
