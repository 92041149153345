import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "@fontsource/inter/200.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/mulish/200.css";
import "@fontsource/mulish/400.css";
import "@fontsource/mulish/600.css";
import "@fontsource/mulish/700.css";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./shared/Store";
import { AuthenticationProvider } from "./shared/Contexts";
import { SpinnerProvider } from "./shared/SpinnerContext";
import { AlertBarProvider } from "./shared/AlertBarContext";
import "./shared/LanguageLocalization";
import { SkillsProvider } from "./modules/skills/contexts/SkillsContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthenticationProvider>
      <SpinnerProvider>
        <AlertBarProvider>
          <Provider store={store}>
            <SkillsProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
            </SkillsProvider>
          </Provider>
        </AlertBarProvider>
      </SpinnerProvider>
    </AuthenticationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
