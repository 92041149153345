import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import "./MapWorkFlow.scss";
import { Breadcrumb } from "../../../../../breadcrumb/components/Breadcrumb";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, KeyboardEvent, useMemo, useContext } from "react";
import { TableLoading } from "../../../../../../shared/components/skeleton/table-loading/TableLoading";
import { useLocation, useNavigate } from "react-router-dom";
import { OPTIVAL_CONSTANTS } from "../../../../../../shared/Constants";
import { tableSorting, updateURLParams } from "../../../../../../shared/utils/Utils";
import { useAPI } from "../../../../../../shared/services/api/API";
import { MapWorkFlowToUsers, SortObj } from "./models/MapWorkFlowToUsers";
import { visuallyHidden } from "@mui/utils";
import { TableHeadCell } from "../../../../../../shared/models/TableModels";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { SkillsConstants, useMapAndAssignUtils } from "../../../../models/SkillsConstants";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SkillsContext } from "../../../../contexts/SkillsContext";

export const MapWorkFlow = () => {
  const navigate = useNavigate();
  const { httpGet, httpPost, handleAlertBar, httpDelete } = useAPI();
  const [searchText, setSearchText] = useState("");
  const [isLoadingUnMapped, setIsLoadingUnMapped] = useState(true);
  const [unMappedWorkFlow, setUnMappedWorkFlow] = useState<
    MapWorkFlowToUsers[]
  >([]);
  const [displayUnMappedWorkFlow, setDisplayUnMappedWorkFlow] = useState<
    MapWorkFlowToUsers[]
  >([]);
  const [mappedWorkFlow, setMappedWorkFlow] = useState<MapWorkFlowToUsers[]>(
    []
  );
  const [isLoadingMapped, setIsLoadingMapped] = useState(true);
  const [displayMappedWorkFlow, setDisplayMappedWorkFlow] = useState<
    MapWorkFlowToUsers[]
  >([]);
  const [checkedUnMappedWorkFlow, setCheckedUnMappedWorkFlow] = useState<
    MapWorkFlowToUsers[]
  >([]);
  const [checkedMappedWorkFlow, setCheckedMappedWorkFlow] = useState<
    MapWorkFlowToUsers[]
  >([]);
  const [unMappedPagination, setUnMappedPagination] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [mappedPagination, setMappedPagination] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [unMapSort, setUnMapSort] = useState<SortObj>({
    order: "desc",
    orderBy: "name",
  });
  const [mapSort, setMapSort] = useState<SortObj>({
    order: "desc",
    orderBy: "name",
  });
  const { updateValidateLessMore } = useContext(SkillsContext);
  const [skillsLevel, setSkillsLevel] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { state } = useLocation();
  const { t } = useTranslation();
  const { getMapWorkFlowHeaders } = useMapAndAssignUtils();

  const backToSkillz = () => {
    navigate("/skillz");
    updateValidateLessMore(true);
  };

  const handleDelete = (data: MapWorkFlowToUsers) => {
    const index = checkedUnMappedWorkFlow.findIndex(
      (workflow: MapWorkFlowToUsers) => workflow.id === data.id
    );
    checkedUnMappedWorkFlow.splice(index, 1);
    setCheckedUnMappedWorkFlow([...checkedUnMappedWorkFlow]);
  };

  const handleSkillChange = (event: any) => {
    const skill = skillsLevel.find(
      (skill: any) => skill?.id === parseInt(event.target.value, 10)
    );
    setSelectedSkill(skill);
  };

  const handleSearchKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText.length > 3) {
      getUnMappedSkillsWorkFlow();
      getMappedSkillsWorkFlow();
    }
  };

  const handleSorting = (property: string, table: string) => {
    if (table === "unMappedTable") {
      const isAsc = unMapSort.orderBy === property && unMapSort.order === "asc";
      setUnMapSort({ order: isAsc ? "desc" : "asc", orderBy: property });
    } else if (table === "mappedTable") {
      const isAsc = mapSort.orderBy === property && mapSort.order === "asc";
      setMapSort({ order: isAsc ? "desc" : "asc", orderBy: property });
    }
  };

  const handleEmptyPage = (data: any, table: string) => {
    if (table === "mappedUser") {
      const sliceArr = data.slice(
        mappedPagination.page * mappedPagination.rowsPerPage,
        mappedPagination.page * mappedPagination.rowsPerPage +
          mappedPagination.rowsPerPage
      );
      if (!sliceArr.length) {
        setMappedPagination({
          page: mappedPagination.page === 0 ? 0 : mappedPagination.page - 1,
          rowsPerPage: mappedPagination.rowsPerPage,
        });
      }
    } else if (table === "unMappedUser") {
      const sliceArr = data.slice(
        unMappedPagination.page * unMappedPagination.rowsPerPage,
        unMappedPagination.page * unMappedPagination.rowsPerPage +
          unMappedPagination.rowsPerPage
      );
      if (!sliceArr.length) {
        setUnMappedPagination({
          page: unMappedPagination.page === 0 ? 0 : unMappedPagination.page - 1,
          rowsPerPage: unMappedPagination.rowsPerPage,
        });
      }
    }
  };

  const handleChangePageForUnMappedWorkFlow = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setUnMappedPagination({
      page: newPage,
      rowsPerPage: unMappedPagination.rowsPerPage,
    });
    const startIndex = newPage * unMappedPagination.rowsPerPage;
    setDisplayUnMappedWorkFlow([
      ...unMappedWorkFlow.slice(
        startIndex,
        startIndex + unMappedPagination.rowsPerPage
      ),
    ]);
  };

  const handleChangeRowsPerPageForUnMappedWorkFlow = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUnMappedPagination({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    setDisplayUnMappedWorkFlow(
      unMappedWorkFlow.slice(0, parseInt(event.target.value, 10))
    );
  };

  const handleChangePageForMappedWorkFlow = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setMappedPagination({
      page: newPage,
      rowsPerPage: mappedPagination.rowsPerPage,
    });
    const startIndex = newPage * mappedPagination.rowsPerPage;
    setDisplayMappedWorkFlow([
      ...mappedWorkFlow.slice(
        startIndex,
        startIndex + mappedPagination.rowsPerPage
      ),
    ]);
  };

  const handleChangeRowsPerPageForMappedWorkFlow = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMappedPagination({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    setDisplayMappedWorkFlow(
      mappedWorkFlow.slice(0, parseInt(event.target.value, 10))
    );
  };

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: "modifiedAt",
    isDescending: true,
    searchString: searchText,
  };

  const getUnMappedSkillsWorkFlow = async () => {
    setIsLoadingUnMapped(true);
    const urlParams = {
      skillId: state.skillId,
    };
    await httpGet(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.GET_UNMAPPED_SKILLS_WORKFLOW,
        urlParams
      ),
      queryParams
    ).then(
      (response: any) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
            setIsLoadingUnMapped(false);
          } else {
            setUnMappedWorkFlow(response.data);
            setDisplayUnMappedWorkFlow(
              response.data.slice(0, unMappedPagination.rowsPerPage)
            );
            setIsLoadingUnMapped(false);
            handleEmptyPage(response.data, "unMappedUser");
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
          setIsLoadingUnMapped(false);
        }
      },
      (error: any) => {
        setIsLoadingUnMapped(false);
      }
    );
  };

  const getMappedSkillsWorkFlow = async () => {
    setIsLoadingMapped(true);
    const urlParams = {
      skillId: state.skillId,
      //skillId: 23,
    };
    await httpGet(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.GET_MAPPED_SKILLS_WORKFLOW,
        urlParams
      ),
      queryParams
    ).then(
      (response: any) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
            setIsLoadingMapped(false);
          } else {
            setMappedWorkFlow(response.data);
            setDisplayMappedWorkFlow(
              response.data.slice(0, mappedPagination.rowsPerPage)
            );
            setIsLoadingMapped(false);
            handleEmptyPage(response.data, "mappedUser");
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
          setIsLoadingMapped(false);
        }
      },
      (error: any) => {
        setIsLoadingMapped(false);
      }
    );
  };

  const getSkillsLevels = async () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_LEVELS).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          setSkillsLevel(response.data);
          //setSelectedSkill(null);
        }
      },
      (error) => {
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };

  const handleMapWorkFlow = async () => {
    const arr = checkedUnMappedWorkFlow.map((workflow: any) => {
      return workflow.id;
    });
    const requestBody = {
      workflowIds: arr,
      skillId: state.skillId,
      //skillId: 23,
      skillLevelId: selectedSkill?.id,
    };
    await httpPost(
      OPTIVAL_CONSTANTS.API_URLS.MAP_SKILLS_TO_WORKFLOW,
      requestBody
    ).then(
      (response: any) => {
        if (response && response.status) {
          if (response.status === 200) {
            handleAlertBar(
              "success",
              t("opva.skillWorkflowMappedSuccessfully")
            );
            getUnMappedSkillsWorkFlow();
            getMappedSkillsWorkFlow();
            setCheckedUnMappedWorkFlow([]);
            setOpenDialog(false);
            setSelectedSkill(null);
          } else {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error: any) => {}
    );
  };

  const handleUnMapWorkFlow = async () => {
    const arr = checkedMappedWorkFlow.map((workflow: any) => {
      return workflow.id;
    });
    const requestBody = {
      workflowIds: arr,
      skillId: state.skillId,
      skillLevelId: 4,
    };
    await httpDelete(
      OPTIVAL_CONSTANTS.API_URLS.UNMAP_SKILLS_FROM_WORKFLOWS,
      requestBody
    ).then(
      (response: any) => {
        if (response && response.status) {
          if (response.status === 200) {
            handleAlertBar(
              "success",
              t("opva.skillWorkflowUnmappedSuccessfully")
            );
            getUnMappedSkillsWorkFlow();
            getMappedSkillsWorkFlow();
            setCheckedMappedWorkFlow([]);
          } else {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error: any) => {}
    );
  };

  const handleCheckBoxForUnMap = (event: any, data: any) => {
    if (event.target.checked) {
      setCheckedUnMappedWorkFlow([...checkedUnMappedWorkFlow, data]);
    } else {
      const index = checkedUnMappedWorkFlow.findIndex(
        (workflow: any) => workflow.id === data.id
      );
      checkedUnMappedWorkFlow.splice(index, 1);

      setCheckedUnMappedWorkFlow([...checkedUnMappedWorkFlow]);
    }
  };

  const handleAllCheckBoxForUnMapped = (event: any) => {
    if (event.target.checked) {
      const uniqueWorkFlows: MapWorkFlowToUsers[] = [];
      displayUnMappedWorkFlow.forEach((workflow: MapWorkFlowToUsers) => {
        const index = checkedUnMappedWorkFlow.findIndex(
          (checkedUnWorkFlow: MapWorkFlowToUsers) =>
            checkedUnWorkFlow.id === workflow.id
        );
        if (index === -1) {
          uniqueWorkFlows.push(workflow);
        }
      });
      setCheckedUnMappedWorkFlow([
        ...checkedUnMappedWorkFlow,
        ...uniqueWorkFlows,
      ]);
    } else {
      setCheckedUnMappedWorkFlow([]);
    }
  };

  const checkedUnMap = (data: any): boolean => {
    if (!checkedUnMappedWorkFlow.length) {
      return false;
    }
    const arr = checkedUnMappedWorkFlow.filter(
      (res: any) => res.id === data.id
    );
    return arr.length > 0 ? true : false;
  };

  const handleCheckBoxForMap = (event: any, data: any) => {
    if (event.target.checked) {
      setCheckedMappedWorkFlow([...checkedMappedWorkFlow, data]);
    } else {
      const index = checkedMappedWorkFlow.findIndex(
        (workflow: any) => workflow.id === data.id
      );
      checkedMappedWorkFlow.splice(index, 1);
      setCheckedMappedWorkFlow([...checkedMappedWorkFlow]);
    }
  };
  const handleAllCheckBoxForMapped = (event: any) => {
    if (event.target.checked) {
      const uniqueWorkFlows: MapWorkFlowToUsers[] = [];
      displayMappedWorkFlow.forEach((workflow: MapWorkFlowToUsers) => {
        const index = checkedMappedWorkFlow.findIndex(
          (checkedWorkFlow: MapWorkFlowToUsers) =>
            checkedWorkFlow.id === workflow.id
        );
        if (index === -1) {
          uniqueWorkFlows.push(workflow);
        }
      });
      setCheckedMappedWorkFlow([...checkedMappedWorkFlow, ...uniqueWorkFlows]);
    } else {
      setCheckedMappedWorkFlow([]);
    }
  };

  function areAllObjectsPresentForUnMap() {
    if (
      checkedUnMappedWorkFlow.length === 0 &&
      displayUnMappedWorkFlow.length === 0
    )
      return false;
    for (let workflow of displayUnMappedWorkFlow) {
      const isPresent = checkedUnMappedWorkFlow.some(
        (data: any) => data.id === workflow.id
      );

      if (!isPresent) {
        return false;
      }
    }
    return true;
  }

  const areAllObjectsPresentForMap = () => {
    if (
      checkedMappedWorkFlow.length === 0 &&
      displayMappedWorkFlow.length === 0
    )
      return false;
    for (let workflow of displayMappedWorkFlow) {
      const isPresent = checkedMappedWorkFlow.some(
        (data: any) => data.id === workflow.id
      );
      if (!isPresent) {
        return false;
      }
    }
    return true;
  };

  const checkedMap = (data: any): boolean => {
    if (!checkedMappedWorkFlow.length) {
      return false;
    }
    const arr = checkedMappedWorkFlow.filter((res: any) => res.id === data.id);
    return arr.length > 0 ? true : false;
  };

  useMemo(() => {
    const propertyType =
      unMapSort.orderBy === "employeeId" ? "number" : "string";
    const sortedList = tableSorting(
      unMappedWorkFlow,
      unMapSort.order,
      unMapSort.orderBy,
      propertyType
    );
    setUnMappedWorkFlow(sortedList);
    setDisplayUnMappedWorkFlow(
      sortedList.slice(
        unMappedPagination.page * unMappedPagination.rowsPerPage,
        unMappedPagination.page * unMappedPagination.rowsPerPage +
          unMappedPagination.rowsPerPage
      )
    );
  }, [unMapSort, unMappedPagination, unMappedWorkFlow]);

  useMemo(() => {
    const propertyType = mapSort.orderBy === "employeeId" ? "number" : "string";
    const sortedList = tableSorting(
      mappedWorkFlow,
      mapSort.order,
      mapSort.orderBy,
      propertyType
    );
    setMappedWorkFlow(sortedList);
    setDisplayMappedWorkFlow(
      sortedList.slice(
        mappedPagination.page * mappedPagination.rowsPerPage,
        mappedPagination.page * mappedPagination.rowsPerPage +
          mappedPagination.rowsPerPage
      )
    );
  }, [mapSort, mappedPagination, mappedWorkFlow]);

  useEffect(() => {
    let timeOutId: number | undefined = undefined;
    if (searchText.length >= 3) {
      timeOutId = window.setTimeout(() => {
        getUnMappedSkillsWorkFlow();
        getMappedSkillsWorkFlow();
      }, 1000);
    } else if (searchText.length === 0) {
      timeOutId = window.setTimeout(() => {
        queryParams.searchString = "";
        getUnMappedSkillsWorkFlow();
        getMappedSkillsWorkFlow();
      }, 1000);
    }
    return () => {
      window.clearTimeout(timeOutId);
    };
  }, [searchText]);

  return (
    <div className="map-workflow-container">
      <div className="bg-light-blue pt-2 mb-3">
        <div className="px-4">
          <Breadcrumb
            isFromOPVA={true}
            pathList={[
              { name: t("opva.skillz"), path: "skillz" },
              { name: t("opva.mapWorkflow"), path: "map-workflow" },
            ]}
          />
          <Grid container alignItems="center" className="pt-2 pb-4">
            <Grid item xs md lg={6} className="d-flex align-items-center">
              <Link
                component="button"
                title={t("opva.backToSkill")}
                className="me-2"
                onClick={backToSkillz}
              >
                <KeyboardArrowLeftIcon fontSize="large" />
              </Link>
              <Typography variant="h4" className="mb-0">
                {t("opva.mapWorkflow")}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              md
              lg={6}
              className="d-flex align-items-center justify-content-end"
            >
              <Input
                id="search"
                type="text"
                placeholder={t("opva.search")}
                value={searchText}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment position="end">
                      <Link component="button" title={t("opva.clear")}>
                        <CloseIcon
                          fontSize="small"
                          onClick={() => setSearchText("")}
                        />
                      </Link>
                    </InputAdornment>
                  )
                }
                size="small"
                sx={{ paddingLeft: "10px" }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleSearchKeyEnter}
              />
            </Grid>
          </Grid>
        </div>

        <Box className="unmappedusers-container bg-white px-4" mb={2}>
          <Typography variant="subtitle1" m={1}>
            {t("opva.availableWorkflow")}
          </Typography>

          <TableContainer className="table-container">
            <Table stickyHeader size="medium" aria-label="UnMapWorkflow table">
              <TableHead>
                <TableRow key="mapworkflow-table-header">
                  <TableCell size="small" padding="checkbox">
                    <Checkbox
                      checked={areAllObjectsPresentForUnMap()}
                      onChange={(e) => handleAllCheckBoxForUnMapped(e)}
                    />
                  </TableCell>

                  {getMapWorkFlowHeaders().map((headCell: TableHeadCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={
                        unMapSort.orderBy === headCell.id
                          ? unMapSort.order
                          : false
                      }
                      align={headCell.position}
                      sx={{
                        width: headCell.width + "%",
                      }}
                    >
                      {headCell?.sorting ? (
                        <TableSortLabel
                          active={
                            unMapSort.orderBy === headCell.sortingProperty
                          }
                          direction={
                            unMapSort.orderBy === headCell.sortingProperty
                              ? unMapSort.order
                              : "desc"
                          }
                          onClick={(event) =>
                            handleSorting(
                              headCell.sortingProperty,
                              "unMappedTable"
                            )
                          }
                        >
                          {headCell.label}
                          {unMapSort.orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {unMapSort.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                      {/* <Typography style={{ fontWeight: "500" }}>
                    {column.label}
                  </Typography> */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoadingUnMapped ? (
                <TableLoading column={getMapWorkFlowHeaders().length + 1} />
              ) : (
                <TableBody>
                  {unMappedWorkFlow && unMappedWorkFlow.length ? (
                    displayUnMappedWorkFlow.map((data: MapWorkFlowToUsers) => (
                      <TableRow key={data.id}>
                        <TableCell size="small" padding="checkbox">
                          <Checkbox
                            checked={checkedUnMap(data)}
                            onChange={(e) => handleCheckBoxForUnMap(e, data)}
                          />
                        </TableCell>
                        <TableCell align="left">{data.name}</TableCell>
                        <TableCell align="left">{data.createdBy}</TableCell>
                        <TableCell>
                          {data.assignedTo.length
                            ? data.assignedTo.length === 1
                              ? data.assignedTo.join("")
                              : data.assignedTo.join(", ")
                            : ""}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        {searchText
                          ? t("opva.searchUnmappedWorkflowDataNotAvailable")
                          : t("opva.noDataAvailable")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
            <div className="d-flex align-items-center justify-content-end">
              <TablePagination
                component="div"
                className="pagination-container mb-2"
                rowsPerPageOptions={[5, 10, 25]}
                count={unMappedWorkFlow.length}
                page={unMappedPagination.page}
                onPageChange={handleChangePageForUnMappedWorkFlow}
                rowsPerPage={unMappedPagination.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPageForUnMappedWorkFlow}
              />
            </div>
          </TableContainer>

          <div className="pb-4">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenDialog(true);
                getSkillsLevels();
              }}
              disabled={!checkedUnMappedWorkFlow.length}
            >
              {t("opva.mapWorkflowUpperCase")}
            </Button>
          </div>
        </Box>

        <Box className="mappedusers-container bg-white px-4">
          <Typography variant="subtitle1" m={1}>
            {t("opva.mappedWorkflow")}
          </Typography>

          <TableContainer>
            <Table stickyHeader size="medium" aria-label="MapWorkflow table">
              <TableHead>
                <TableRow>
                  <TableCell size="small" padding="checkbox">
                    <Checkbox
                      checked={areAllObjectsPresentForMap()}
                      onChange={(e) => handleAllCheckBoxForMapped(e)}
                    />
                  </TableCell>
                  {getMapWorkFlowHeaders().map(
                    (headCell: TableHeadCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={
                          mapSort.orderBy === headCell.id
                            ? mapSort.order
                            : false
                        }
                        align={headCell.position}
                        sx={{
                          width: headCell.width + "%",
                        }}
                      >
                        {headCell?.sorting ? (
                          <TableSortLabel
                            active={
                              mapSort.orderBy === headCell.sortingProperty
                            }
                            direction={
                              mapSort.orderBy === headCell.sortingProperty
                                ? mapSort.order
                                : "desc"
                            }
                            onClick={(event) =>
                              handleSorting(
                                headCell.sortingProperty,
                                "mappedTable"
                              )
                            }
                          >
                            {headCell.label}
                            {mapSort.orderBy === headCell.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {mapSort.order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              {isLoadingMapped ? (
                <TableLoading
                  column={getMapWorkFlowHeaders().length + 1}
                />
              ) : (
                <TableBody>
                  {mappedWorkFlow && mappedWorkFlow.length ? (
                    displayMappedWorkFlow.map((data: MapWorkFlowToUsers) => (
                      <TableRow key={data.id}>
                        <TableCell size="small" padding="checkbox">
                          <Checkbox
                            checked={checkedMap(data)}
                            onClick={(e) => handleCheckBoxForMap(e, data)}
                          />
                        </TableCell>
                        <TableCell align="left">{data.name}</TableCell>
                        <TableCell align="left">{data.createdBy}</TableCell>
                        <TableCell align="left">
                          {data.assignedTo.length
                            ? data.assignedTo.length === 1
                              ? data.assignedTo.join("")
                              : data.assignedTo.join(", ")
                            : ""}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        {searchText
                          ? t("opva.searchMappedWorkflowDataNotAvailable")
                          : t("opva.noDataAvailable")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
            <div className="d-flex align-items-center justify-content-end">
              <TablePagination
                component="div"
                className="pagination-container mb-2"
                rowsPerPageOptions={[5, 10, 25]}
                count={mappedWorkFlow.length}
                page={mappedPagination.page}
                onPageChange={handleChangePageForMappedWorkFlow}
                rowsPerPage={mappedPagination.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPageForMappedWorkFlow}
              />
            </div>
          </TableContainer>

          <div className="pb-4">
            <Button
              variant="contained"
              color="error"
              disabled={!checkedMappedWorkFlow.length}
              onClick={handleUnMapWorkFlow}
            >
              {t("opva.unmapWorkflowUpperCase")}
            </Button>
          </div>
        </Box>
      </div>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{t("opva.mapWorkflow")}</Typography>
            <IconButton aria-label="close" onClick={() => setOpenDialog(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography variant="body1" className="m-2">
            {t("opva.selectedWorkflow")}
          </Typography>
          <div className="scrollableContainer">
            {checkedUnMappedWorkFlow.map((workflow) => (
              <div>
                <Chip
                  key={workflow.id}
                  label={workflow.name}
                  color="primary"
                  onDelete={() => handleDelete(workflow)}
                  style={{ margin: "4px" }}
                />
              </div>
            ))}
          </div>
          <div>
            <Typography variant="body1" className="m-1">
            {t("opva.selectSkillLevel")}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                className="ms-1"
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="skill"
                value={selectedSkill?.id}
                onChange={handleSkillChange}
              >
                {skillsLevel.map((skill: any) => (
                  <FormControlLabel
                    key={skill.id}
                    value={skill.id}
                    control={<Radio checkedIcon={<CheckCircleIcon />} />}
                    label={t(`opva.${skill.name.toLowerCase()}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            color="primary"
            variant="contained"
            onClick={handleMapWorkFlow}
            sx={{textTransform: 'none'}}
            disabled={!checkedUnMappedWorkFlow.length || selectedSkill === null}
          >
            {t("opva.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
