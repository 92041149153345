export const CREATE_WF_CONSTANTS = {
  SELECT_THE_LANGUAGES_YOU_WANT:
    "Select the languages you want the workflow translated in",
  WORKFLOW_DETAILS: "Workflow Details",
  CLICK_HERE: "Click here",
  TO_UPLOAD_MEDIA_HERE: "to upload or drop media here",
  TITLE: "Title",
  DESCRIPTION: "Description",
  TAGS: "Tags",
  ADD_STEPS: "Steps",
  ENGLISH: "English",
  KANNADA: "Kannada",
  TAMIL: "Tamil",
  TELUGU: "Telugu",
  HINDI: "Hindi",
  ADDED_STEPS: "Added steps",
  YES: "Yes",
  UPLOADES_PDF_IMAGE_OR_VIDEO: "Upload PDF, Image or Video",
  UPLOADES_IMAGE_ONLY: "Upload a Image",
  ERROR_MSG_UPLOAD_IMG: "Please Upload a Image (JPEG or PNG image)",
  ERROR_MSG_UPLOAD_BOX_STEP: "Please Upload PDF, Image or Video",
  SAVE: "Save",
  IS_THIS_STEP_MANDATORY: "Is this step mandatory?",
  TILE_DES_FIELD_CANNOT_EMPTY_MSG: "Title & Description field cannot be empty",
  UPDATE_WORKFLOW_CREATION: "Update Workflow Creation",
  NEW_WORKFLOW_CREATION: "New Workflow Creation",
  PUBLISH_WORKFLOW_CREATION: "Publish Workflow Creation",
  PUBLISH: "Publish",
  SUBMIT: "Submit",
  NEXT: "Next",
  PREVIOUS: "Previous",
  START_ADDING_STEP_WORKFLOW: "Start adding steps to the workflow",
  READY_FOR_SUBMIT: "Ready for submission",
  CONFIRMATION_MSG_READY_FOR_SUBMIT:
    "Are you ready to submit this workflow for review?",
  SUBMIT_FOR_REVIEW: "Submit for review",
  WORKFLOW_ASSIGNED_EMPLOYEE_SUCCESS_MSG:
    "Employee assigned to workflow successfully.",
  REMOVE_WORKFLOW_ASSIGNED_EMPLOYEE_SUCCESS_MSG:
    "Employee removed from workflow successfully.",
  DELETE_STEP: "Delete Step",
  CONFIRMATION_MSG_DELETE_STEP:
    "Are you sure want to delete this step from workflow?",
};
