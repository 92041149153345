import { useTranslation } from "react-i18next";
import { useAPI } from "../../../../../shared/services/api/API";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { SkillsContext } from "../../../contexts/SkillsContext";
import { SpinnerContext } from "../../../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import { Grid, Link, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { TableLoading } from "../../../../../shared/components/skeleton/table-loading/TableLoading";
import "./TimeSpentLeaningSkills.scss";
import { Order, TableHeadCell } from "../../../../../shared/models/TableModels";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { tableSorting } from "../../../../../shared/utils/Utils";
import { dummySkillsLevels, ISkillsLevel } from "../../../models/SkillsData";
import { SharedApi } from "../../../../../shared/services/shared-api/SharedApi";

export const TimeSpentLeaningSkills = () => {
  const { t } = useTranslation();
  const { httpGet, handleAlertBar } = useAPI();
  const [skillsTimeSpentHeader, setSkillsTimeSpentHeader] = useState<any>([{id: '', label: ''}, {id: 1, label: 'Beginner'}, {id: 2, label: 'Intermediate'}, {id: 3, label: 'Advanced'}, {id: 4, label: 'Expert'}]);
  const { updateTimeSpentSkillLessMore } = useContext(SkillsContext);
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayTableData, setDisplayTableData] = useState<any[]>([]);
  const { getColorForLevel } = SharedApi();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [skillsLevels, setSkillsLevels] = useState<ISkillsLevel[]>(dummySkillsLevels);
  const [skillsTimeSpentTableData, setSkillsTimeSpentTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchTimeSpentData();
  }, []);

  const fetchTimeSpentData = () => {
    setIsLoading(true);
    const quaryParams = { pageSize: 1000 };
    httpGet(
      OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_TIME_SPENT,
      quaryParams,
      false
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
          setIsLoading(false);
        } else {
          // Extract levels and skill matrix
          const levels = response?.data?.levels.length ? response?.data?.levels : dummySkillsLevels;
          const skillsMatrix = response?.data?.skillMatrix;

          // Generate table header
          const tableHeader = [{ id: '', label: "" }];
          levels.forEach((level: any) => {
            tableHeader.push({ id: level.id, label: level.name });
          });
          setSkillsLevels(levels);
          setSkillsTimeSpentHeader(tableHeader);
          //Generate table data
          setSkillsTimeSpentTableData(skillsMatrix);
          setIsLoading(false);
        }
      },
      (error) => {
        handleAlertBar("error", error.message || t("opva.serviceUnavailable"));
      }
    );
  };

  useMemo(() => {
    const propertyType = orderBy === "usersAssigned" ? "number" : "string";
    const sortedList = tableSorting(skillsTimeSpentTableData, order, orderBy, propertyType);
    setSkillsTimeSpentTableData(sortedList);
    setDisplayTableData(
      sortedList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [order, orderBy, page, skillsTimeSpentTableData]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setDisplayTableData(skillsTimeSpentTableData.slice(0, parseInt(event.target.value, 10)));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const startIndex = newPage * rowsPerPage;
    setDisplayTableData([
      ...skillsTimeSpentTableData.slice(startIndex, startIndex + rowsPerPage),
    ]);
  };

  const timeDisplay = (totalSeconds: any) => {
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    return ( <Typography variant="bodySmall">{days}d{hours}hr{minutes}min</Typography>)
  }

  return (
    <div className="skills-time-spent-container  mt-4">
      <Grid container display={'flex'} justifyContent={'space-between'}>
      <Grid item xs={8} sm={8} md={10} lg={10}>
    <Typography variant="h5">
    {t("opva.timeSpentLearningSkill")}
              </Typography>
      </Grid>
      <Grid item xs={4} sm={4} md={1} lg={1} textAlign="right">
      <Link
          title={t("opva.backToOverview")}
          color={"#00000"}
          component="button"
          className="mb-2"
          onClick={() => updateTimeSpentSkillLessMore(false)}
        >
          <CloseFullscreenIcon fontSize="small" />
        </Link>
    </Grid>
      </Grid>

      <TableContainer className="skills-time-spent-table">
        <Table size="medium" aria-label="skills table">
        <TableHead>
              <TableRow>
                {skillsTimeSpentHeader.map((headCell: TableHeadCell) =>
                  headCell.label !== "" ? (
                    <Fragment key={headCell.id}>
                      <TableCell
                        key={headCell.id}
                        align={"center"}
                        sx={{
                          backgroundColor: getColorForLevel(headCell.id),
                          width: "17%",
                        }}
                      >
                        {headCell.id
                          ? `${t(headCell.id)} - ${t(`opva.${headCell.label}`)} `
                          : `${t(headCell.label)}`}
                      </TableCell>
                    </Fragment>
                  ) : (
                    <TableCell sx={{ width: "20%" }}>
                      {t(headCell.label)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                {skillsTimeSpentHeader.map((headCell: TableHeadCell) =>
                  headCell.label !== "" ? (
                      <TableCell align={"center"} className="fw-bold">
                        {t("opva.timeSpent")}
                      </TableCell>
                  ) : (
                    <TableCell className="fw-bold">{t("opva.skillName")}</TableCell>
                  )
                )}
                <TableCell align="center" className="fw-bold">{t("opva.totalTimeSpent")}</TableCell>
              </TableRow>
            </TableHead>
          {isSpinnerShow ? (
            <TableLoading column={4} />
          ) : (
            <TableBody>
            {displayTableData && displayTableData && displayTableData?.length ? (
              displayTableData.map((row: any, index: number) => (
                <TableRow key={row?.id}>
                  <TableCell>{row.name}</TableCell>
                  {skillsLevels.map((level: any) => (
                    <Fragment key={level.id}>
                      <TableCell align={"center"}>
                        {row.levels[level.id] ? timeDisplay(row.levels[level.id]) : '-'}
                      </TableCell>
                    </Fragment>
                  ))}
                  <TableCell align="center">{row?.totalTimeSpent ? timeDisplay(row?.totalTimeSpent) : '-'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow sx={{height: '400px'}}>
                <TableCell colSpan={12} align="center">
                <Typography variant="bodyLarge" className="p-2">
                  {t("opva.noDataAvailable")}
                </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
          className="pagination-container mb-4"
          component="div"
          count={skillsTimeSpentTableData?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  );
}