import { createSlice } from "@reduxjs/toolkit";

interface HeaderSearchState {
  text: string;
}

const initialState = { text: "" } as HeaderSearchState;

const headerSearchReducer = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.text = action.payload;
    },
  },
});

export const { setSearchText } = headerSearchReducer.actions;
export default headerSearchReducer.reducer;
