import { Grid, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { CollapseRowProps } from "../models/TagManagementModal";
import { TagManagementConstants } from "../constants/TagManagementConstants";
import "./WorkflowUsed.scss";
import { useTranslation } from "react-i18next";
export const WorkflowUsed = (props: CollapseRowProps) => {
  const { t } = useTranslation();
  return (
    <div className="skills-container px-5 py-4">
      <Grid container>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className="mb-3">
            {t("opva.workflowUsedIn")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Link
            component="button"
            className="float-end"
            title="Close"
            onClick={props.collapseClose}
          >
            <CloseIcon />
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="bodyNormal" className="mb-3">
            {props.workflowsUsed?.length > 0
              ? props.workflowsUsed.join(", ")
              : t("opva.noWorkflowAvailableForTag")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
