import { useTranslation } from "react-i18next";
import { TableHeadCell } from "../../../shared/models/TableModels";

export const UserManagementConstants = {
  USER_MANAGEMENT: "User Management",
  ADD_BULK_USER: "Add Bulk Users",
  ALL_USERS: "All Users",
  ADD_USER: "Add User",
  ADD_NEW_USER: "Add New User",
  EDIT_USER: "Edit User",
  UPDATE_USER: "Update User",
  NEW_USER: "New User",
  ADD_BULK_USERS: "Add bulk users",
  ADD_BULK_USERS_DOWNLOAD_FORMAT:
    "To add bulk users, download our format and fill and upload the excel",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  EMPLOYEE_ID: "Employee ID",
  EMAIL: "Email",
  PHONE_NUMBER: "Phone number",
  USER_TYPE: "User type",
  DEPARTMENT: "Department",
  LOCATION: "Location",
  ROLE: "Role",
  OPTIONAL: "optional",
  CLICK_HERE: "Click here",
  DELETE_USER: "Delete User",
  CONFIRMATION_MSG_DELETE_USER: "Are you sure want to delete this user?",
  UPLOAD_EXCEL_FILE: "to upload or drop excel file",
  DOWNLOAD_FORMAT_HERE: "You can download the format here",
  ADD_USER_SUCCESS_MSG: "New user has been created successfully",
  UPDATED_USER_SUCCESS_MSG: "User has been updated successfully",
  DELETE_USER_SUCCESS_MSG: "User has been deleted successfully.",
  NO_DATA_AVAILABLE: "No user(s) data available.",
  NO_SKILLS_AVAILABLE: "No skill(s) available for this user.",
  NO_SEARCH_DATA_AVAILABLE: "Search user data is not available.",
  SKILLS_OF_THIS_USER: "Skills of this user",
  BULK_USER_HEADER_LIST: [
    "First name*",
    "Last name*",
    "Employee ID",
    "Email*",
    "Phone number",
    "User type*",
    "Department",
    "Location",
    "Role",
  ],
};

export const useManagamentUtil = () => {
  const { t } = useTranslation();
  const getUserManagementHeaders = () => {
    const userManagementHeader = [
      {
        id: "firstName",
        label: t("opva.name"),
        width: 15,
        sorting: true,
        sortingProperty: "firstName",
        position: "left",
      },
      {
        id: "employeeId",
        label: t("opva.employeeId"),
        width: 15,
        sorting: true,
        sortingProperty: "employeeId",
        position: "left",
      },
      {
        id: "mail",
        label: t("opva.emailId"),
        width: 15,
        sorting: true,
        sortingProperty: "mail",
        position: "left",
      },
      {
        id: "role",
        label: t("opva.role"),
        width: 15,
        sorting: false,
        sortingProperty: "role",
        position: "left",
      },
      {
        id: "status",
        label: t("opva.status"),
        width: 10,
        sorting: true,
        sortingProperty: "status",
        position: "left",
      },
      {
        id: "skillCount",
        label: t("opva.skills"),
        width: 5,
        sorting: true,
        sortingProperty: "skillCount",
        position: "left",
      },
      {
        id: "actions",
        label: t("opva.actions"),
        width: 15,
        sorting: false,
        sortingProperty: "",
        position: "center",
      },
    ] as TableHeadCell[];
    return userManagementHeader;
  };
  return { getUserManagementHeaders };
};

// export const getUserManagementHeaders = () => {
//   const userManagementHeader = [
//     {
//       id: "firstName",
//       label: t("opva.name"),
//       width: 15,
//       sorting: true,
//       sortingProperty: "firstName",
//       position: "left",
//     },
//     {
//       id: "employeeId",
//       label: t("opva.employeeId"),
//       width: 15,
//       sorting: true,
//       sortingProperty: "employeeId",
//       position: "left",
//     },
//     {
//       id: "mail",
//       label: t("opva.emailId"),
//       width: 15,
//       sorting: true,
//       sortingProperty: "mail",
//       position: "left",
//     },
//     {
//       id: "role",
//       label: t("opva.role"),
//       width: 15,
//       sorting: false,
//       sortingProperty: "role",
//       position: "left",
//     },
//     {
//       id: "status",
//       label: t("opva.status"),
//       width: 10,
//       sorting: true,
//       sortingProperty: "status",
//       position: "left",
//     },
//     {
//       id: "skillCount",
//       label: t("opva.skills"),
//       width: 5,
//       sorting: true,
//       sortingProperty: "skillCount",
//       position: "left",
//     },
//     {
//       id: "actions",
//       label: t("opva.actions"),
//       width: 15,
//       sorting: false,
//       sortingProperty: "",
//       position: "center",
//     },
//   ] as TableHeadCell[];
//   return userManagementHeader;
// };
