import {
  Button,
  Chip,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import "./OrganisationDetails.scss";
import {
  OrganisationDetailModel,
  OrganisationDetailsProps,
} from "../../models/OrganisationModels";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useEffect, useState } from "react";
import { useAPI } from "../../../../shared/services/api/API";
import { updateURLParams } from "../../../../shared/utils/Utils";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import { useTranslation } from "react-i18next";

export const OrganisationDetails = (props: OrganisationDetailsProps) => {
  const { httpGet, httpPut, handleAlertBar } = useAPI();
  const { checkRole } = useAPI();
  const [data, setData] = useState<any>({});
  const [languagePreferred, setLanguagePreferred] = useState<any>("");
  const [preferredLanguageList, setPreferredLanguageList] = useState<any>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  const [isEdit, SetIsEdit] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState<OrganisationDetailModel>({
    name: "",
    address: "",
    orgPreferredLanguages: [],
    mail: "",
    secondaryMail: "",
    phone: "",
    errors: {
      name: false,
      address: false,
      orgPreferredLanguages: false,
      mail: false,
      secondaryMail: false,
      phone: false,
    },
  });
  const isFormValid = () => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.mail);
    const isMobileValid = /^\d{10}$/.test(formData.phone);

    return (
      formData.name.trim() !== "" &&
      formData.address.trim() !== "" &&
      formData.orgPreferredLanguages &&
      isEmailValid &&
      isMobileValid
    );
  };

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    let isError = false;
    if (name === "mail") {
      isError = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    } else if (name === "phone") {
      isError = !/^\d{10}$/.test(value) || value.length === 0;
    } else if (name === "orgPreferredLanguages") {
      addPreferredLanguages(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      errors: { ...prevData.errors, [name]: isError },
    }));
  };

  const handleKeyDown = (e: any) => {
    const isValidInput =
      !isNaN(e.key) || e.key === "Backspace" || e.key === "Delete";
    const isMaxLengthReached = e.target.value.length >= 10;
    if (
      (!isValidInput && e.key !== "Backspace" && e.key !== "Delete") ||
      (isMaxLengthReached && e.key !== "Backspace" && e.key !== "Delete")
    ) {
      e.preventDefault();
    }
  };

  const onFormSubmit = () => {
    if (isFormValid()) {
      const languageLists = preferredLanguageList?.map(
        (e: any) => e.language_name
      );
      const payload = {
        name: formData?.name,
        address: formData?.address,
        address2: data?.address2,
        state: data?.state,
        country: data?.country,
        zipCode: data?.zipCode,
        mail: formData?.mail,
        phone: formData?.phone,
        industrySector: data?.industrySector,
        purpose: data?.purpose,
        // organizationPlans: {
        //   planId: data?.organizationPlans?.plans?.id,
        //   cost: data?.organizationPlans?.cost,
        //   numberOfUserSeats: data?.organizationPlans?.numberOfUserSeats,
        //   startDate: data?.organizationPlans?.startDate,
        //   endDate: data?.organizationPlans?.endDate,
        // },
        languages: languageLists,
      };
      setFormData((prevData) => ({ ...prevData, success: true }));
      updateOrganisation(payload);
    } else {
      console.log("Form has errors. Please fill in all required fields.");
    }
  };
  const editOrganisationDetail = () => {
    SetIsEdit(true);
    setFormData({
      name: data?.name,
      address: data?.address,
      orgPreferredLanguages: data?.orgPreferredLanguages,
      mail: data?.mail,
      secondaryMail: data?.secondaryMail,
      phone: data?.phone,
      errors: {
        name: false,
        address: false,
        orgPreferredLanguages: false,
        mail: false,
        secondaryMail: false,
        phone: false,
      },
    });
    const orgPreferredLanguagesList = data?.orgPreferredLanguages?.filter(
      (e: any) => e.display_name !== "English"
    );
    setPreferredLanguageList(orgPreferredLanguagesList);
  };

  const cancelButton = () => {
    SetIsEdit(false);
  };

  const fetchOrganizationData = async () => {
    await httpGet(OPTIVAL_CONSTANTS.API_URLS.ORGANIZATION_DATA).then(
      (response) => {
        if (response) {
          const orgPreferredLanguagesList =
            response?.data?.orgPreferredLanguages?.filter(
              (e: any) => e.display_name !== "English"
            );
          setPreferredLanguageList(orgPreferredLanguagesList);
          setData(response.data);
        }
      },
      (error) => {}
    );
  };

  const fetchLanguageList = async () => {
    await httpGet(
      OPTIVAL_CONSTANTS.API_URLS.ORGANIZATION_LANGUAGE_DISABLE
    ).then(
      (response) => {
        if (response && response?.data) {
          const languageListDetail = response?.data?.filter(
            (e: any) => e.display_name !== "English"
          );
          setLanguageList(languageListDetail);
        }
      },
      (error) => {}
    );
  };

  const addPreferredLanguages = async (lang: string) => {
    const addPrepreferredLanguageList = languageList?.filter(
      (items: any) => items.language_name === lang
    );
    const selectedLanguageIndex = preferredLanguageList.findIndex(
      (language: any) =>
        language.language_name === addPrepreferredLanguageList[0].language_name
    );
    if (selectedLanguageIndex === -1) {
      const list = [...preferredLanguageList, ...addPrepreferredLanguageList];
      setPreferredLanguageList(list);
    }
  };

  const removePreferredLanguages = async (lang: string) => {
    const removePrepreferredLanguageList = preferredLanguageList?.filter(
      (items: any) => items.language_name !== lang
    );
    setPreferredLanguageList(removePrepreferredLanguageList);
  };

  const updateOrganisation = async (payload: any) => {
    const queryParam = {
      organizationId: data.id,
    };
    const urlParams = {
      organizationId: data.id,
    };
    await httpPut(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.UPDATE_ORGANISATION_DETAILS,
        urlParams
      ),
      payload,
      queryParam
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            SetIsEdit(false);
            setData(response.data);
            handleAlertBar("success", t("opva.organisationUpdateMsg"));
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fetchOrganizationData();
  }, []);
  useEffect(() => {
    fetchLanguageList();
  }, []);
  return (
    <div>
      <div className="bg-light-blue px-5 pt-2 mb-3">
        <Breadcrumb
          pathList={[
            {
              name: t("opva.organizationProfile"),
              path: "organization-profile",
            },
          ]}
        />
        <Grid container className=" d-flex justify-content-between pt-2 pb-4">
          <Grid item xs md lg={9} className="mb-2">
            <Typography variant="h3" className="mb-0">
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs md lg={3} className="d-flex justify-content-end mb-2">
            {checkRole([
              OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
              OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
            ]) &&
              (isEdit ? (
                <div>
                  <Button
                    variant="outlined"
                    className="m-2"
                    onClick={cancelButton}
                    // disabled={isSaveDisable}
                  >
                    {t("opva.cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    className="m-2"
                    onClick={onFormSubmit}
                    disabled={!isFormValid()}
                  >
                    {t("opva.save")}
                  </Button>
                </div>
              ) : (
                <Link
                  component="button"
                  title="Edit"
                  onClick={editOrganisationDetail}
                >
                  <EditIcon />
                </Link>
              ))}
          </Grid>
        </Grid>
      </div>
      <div className="px-5">
        <Grid container>
          <Grid item xs={12} sm={6} md={2}>
            <img src="images/CompanyLogo.jpg" alt="Company Logo" />
          </Grid>
          <Grid item xs={12} sm={5} md={10}>
            <Grid container spacing={6} className="organisation-details">
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="labelSmall" className="label-grey fw-bold">
                  {t("opva.name")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterOrganizationName")}
                      variant="standard"
                      name="name"
                      value={formData?.name}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.name}
                      helperText={
                        formData.errors.name && t("opva.enterOrgNameHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data.name}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="labelSmall" className="label-grey fw-bold">
                  {t("opva.address")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterAddress")}
                      variant="standard"
                      name="address"
                      value={formData?.address}
                      onChange={handleFormChange}
                      error={formData?.errors?.address}
                      helperText={
                        formData.errors.address &&
                        t("opva.enterAddressHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data.address}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="labelSmall" className="label-grey fw-bold">
                  {t("opva.primaryContact")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterPrimaryContactEmail")}
                      variant="standard"
                      name="primaryContact"
                      value={formData?.mail}
                      onChange={handleFormChange}
                      error={formData.errors.mail}
                      disabled={true}
                      helperText={
                        formData.errors.mail && t("opva.primaryEmailHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{data.mail || "-"}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="labelSmall" className="label-grey fw-bold">
                  {t("opva.secondaryContact")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterSeconderContactEmail")}
                      variant="standard"
                      name="SecondaryContact"
                      value={formData.secondaryMail}
                      onChange={handleFormChange}
                      error={formData.errors.secondaryMail}
                      disabled={true}
                      helperText={
                        formData.errors.secondaryMail &&
                        t("opva.secondaryEmailHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">{"-"}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="labelSmall" className="label-grey fw-bold">
                  {t("opva.languagesPreferred")}
                </Typography>
                {isEdit ? (
                  <>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <TextField
                        style={{ width: "60%" }}
                        select
                        placeholder={t("opva.selectLanguage")}
                        variant="standard"
                        name="orgPreferredLanguages"
                        value={languagePreferred || " "}
                        onChange={handleFormChange}
                      >
                        <MenuItem value=" ">
                          {t("opva.selectLanguage")}
                        </MenuItem>
                        {languageList?.map((option: any, index: any) => (
                          <MenuItem key={index} value={option.language_name}>
                            {option.display_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="mt-1">
                      <Chip
                        label="English"
                        color="default"
                        size="medium"
                        variant="filled"
                      />
                      {preferredLanguageList?.map(
                        (language: any, index: any) => (
                          <Chip
                            color="default"
                            size="medium"
                            variant="filled"
                            className="m-2"
                            key={index}
                            label={language?.display_name}
                            onDelete={() =>
                              removePreferredLanguages(language.language_name)
                            }
                          />
                        )
                      )}
                    </div>
                  </>
                ) : (
                  <Typography variant="h6">
                    {data?.orgPreferredLanguages
                      ?.map((e: any) => e.display_name)
                      .join(" , ") || "-"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="labelSmall" className="label-grey fw-bold">
                  {t("opva.phoneNumber")}
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder={t("opva.enterPhoneNumber")}
                      variant="standard"
                      name="phone"
                      value={formData.phone}
                      disabled={
                        !checkRole([OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN])
                      }
                      onChange={handleFormChange}
                      onKeyDown={handleKeyDown}
                      error={formData?.errors?.phone}
                      helperText={
                        formData?.errors?.phone &&
                        t("opva.enterPhoneNumberHelperText")
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="h6">
                    {!checkRole([OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN])
                      ? `********${data?.phone % 100}`
                      : data.phone || "-"}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
