import HighchartsReact from "highcharts-react-official";
import "./SkillsPieChart.scss";
import Highcharts from "highcharts";
import { useContext, useEffect, useState } from "react";
import { useAPI } from "../../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";
import { SkillsContext } from "../../../contexts/SkillsContext";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
export const SkillsPieChart = () => {
  const { httpGet, handleAlertBar } = useAPI();
  const [pieChartData, setPieChartData] = useState<any>([]);
  const [total, setTotal] = useState("");
  const { t } = useTranslation();
  const { updateSkillsDistributionPieChartLessMore } =
    useContext(SkillsContext);
  useEffect(() => {
    getSkillsDistributionChartData();
  }, []);

  const getSkillsDistributionChartData = () => {
    const queryParams = {
      expandCount: 1,
    };
    httpGet(
      OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_DISTRIBUTION,
      queryParams
    ).then((response) => {
      if (response && response.data) {
        if (response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          setTotal(response.data?.total);
          const distribution  = response.data.distribution;
          const remaining  = response.data.remaining;
          const pieChartData = [
            {
              name: `${t("opva.userWith1skill")}`,
              y: distribution['1'],
              color: { linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, '#1C1C1C'],
                [1, Highcharts.color('#1C1C1C').setOpacity(0.4).get()],
              ]},
            },
            {
              name: `${t("opva.userWith2orMore")}`,
              y: remaining,
              color: '#66B5F8',
            }
          ]
          setPieChartData(pieChartData);
        }
      } else {
        handleAlertBar("error", t("opva.serviceUnavailable"));
      }
    });
  };

  const options = {
    chart: {
      renderTo: "container",
      type: "pie",
      plotBackgroundColor: "#fafbfc",
      backgroundColor: "#fafbfc",
      marginLeft: 0,
      height:206,
    },
    title: {
      text: t("opva.skillDistributionByUser"),
      align: "left",
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 0,
      y: 60,
      labelFormat: `<span style="display: flex; justify-content: space-between; width: 200px">
                      <span>{name}</span>
                      <span>{y}</span>
                    </span>`,
      useHTML: true,
    },
    tooltip: {
      pointFormat: "{series.name}",
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: pieChartData,
        name: t("opva.skillDistribution"),
        size: "100%",
        innerSize: "47%",
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div className="pie-chart-container p-2">
      <div style={{ position: "absolute", right: 20, top: 12, zIndex: 1}}>
      <Link
                component="button"
                color={"#00000"}
                title={"expand"}
                onClick={() => updateSkillsDistributionPieChartLessMore(true)}
              >
                <OpenInFullIcon fontSize="small" />
              </Link>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "210px",
            position: "absolute",
            top: "-70px",
            right: "20px",
          }}
        >
          <span className="fw-bold">{t("opva.total")}</span>
          <span className="fw-bold">{total}</span>
        </span>
      </div>
    </div>
  );
};
