import {
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
import "./TopSkillsGroup.scss";
import { useTranslation } from "react-i18next";
import { useAPI } from "../../../../../shared/services/api/API";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import { TableLoading } from "../../../../../shared/components/skeleton/table-loading/TableLoading";
import { TableHeadCell } from '../../../../../shared/models/TableModels';
import { SkillsContext } from "../../../contexts/SkillsContext";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

export const TopSkillsGroup = () => {
    const { t } = useTranslation();
    const { httpGet, handleAlertBar } = useAPI();
    const { isSpinnerShow } = useContext(SpinnerContext);
    const [ tableData, setTableData ] = useState<any>([]);
    const { updateTargetGapLessMore } = useContext(SkillsContext);

    const tableHeadCells: readonly TableHeadCell[] = [
      { 
        id: 'sr',
        label: '',
        width: 1,
        disablePadding: true,
        position: "left",
        sorting: true,
        sortingProperty: "asc",

      },
      {
        id: "name",
        label: t("opva.group"),
        width: 50,
        disablePadding: true,
        position: "left",
        sorting: true,
        sortingProperty: "asc",
      },
      {
        id: "current",
        label: t("opva.current"),
        width: 22,
        disablePadding: true,
        position: "center",
        sorting: true,
        sortingProperty: "asc",
      },
      {
        id: "target",
        width: 22,
        disablePadding: true,
        label: t("opva.target"),
        position: "center",
        sorting: true,
        sortingProperty: "asc",
      },
      {
        id: "gap",
        width: 22,
        disablePadding: true,
        label: t("opva.gap"),
        position: "center",
        sorting: true,
        sortingProperty: "asc",
      },
  ];
  
    useEffect(() => {
      getSkillsWithTargetGapData();
    }, []);
  
    const getSkillsWithTargetGapData = () => {
      const queryParams = {
        pageSize: 4,
      }
      httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_TARGET_WITH_GAP, queryParams).then((response)=>{
          if(response && response.data) {
              if(response.data.error) {
                  handleAlertBar('error', response.data.message);
              } else {
                setTableData(response.data);
              }
          } else {
              handleAlertBar("error", t("opva.serviceUnavailable"));
            }
      })
    }
  
    return (
      <div className="skills-table-container" style={{filter: "drop-shadow(0.5px 3px 3px #00000040)"}}>
        <Grid container>
        <Grid item xs={8} sm={10} md={10} lg={11}>
          <h5 className="fw-bold">{t("opva.topTargetGroup")}</h5>
        </Grid>
        <Grid item xs={4} sm={2} md={2} lg={1}  textAlign="right">
        <Link
                component="button"
                color={"#00000"}
                title={"expand"}
                onClick={() => updateTargetGapLessMore(true)}
              >
                <OpenInFullIcon fontSize="small" />
              </Link>
        </Grid>
      </Grid>

        <TableContainer className="skills-group-table">
          <Table size="medium" aria-label="skills table">
            <TableHead>
              <TableRow>
                {tableHeadCells.map((headCell: TableHeadCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.position}
                    className="py-1"
                    sx={{
                      width: headCell.width + "%",
                      backgroundColor: headCell.background
                    }}
                  >
                    <Typography variant="body2">
                    {t(headCell.label)}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isSpinnerShow ? (
              <TableLoading column={4} />
            ) : (
              <TableBody>
                {tableData  && tableData.length ? (
                  tableData.map((row: any, index: number) => (
                    <TableRow key={index} sx={{
                        '& .css-q5kbv7-MuiTableCell-root': {
                            borderBottom: "none"
                        },
                        '& .css-1aewt8a-MuiTableCell-root': {
                            borderBottom: "none"
                        }
                    }}>
                      <TableCell className="pe-0">
                      <Typography
                            sx={{ width: "1px"}}
                            variant="bodySmall"
                            className="header"
                          >
                            {index + 1}
                          </Typography>
                      </TableCell>
                      <TableCell>
                        <div className="skills-name-text">
                          <img
                            src="images/User.svg"
                            alt="User"
                            className="me-2"
                          />
                          <Typography
                            sx={{ width: "300px" }}
                            variant="bodySmall"
                            className="header"
                          >
                            {row?.groupName}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="bodySmall" className="header">
                          {row.current}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="bodySmall" className="header">
                          {row.target}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="bodySmall" className="header" color={row.gap > 0 ?'#629B6A' : '#FF647C'}>
                          {row.gap > 0 ? `+${row.gap}` : `${row.gap}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      {t("opva.NoGroupsDataAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    );
}