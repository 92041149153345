import "./MobileApplicationInfo.scss";
import {
  Divider,
  Grid,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

export const MobileApplicationInfo = () => {
  const isLGScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.not("lg")
  );
  return (
    <div className="mobile-app-container bg-dark-blue">
      <Grid container className="mobile-app-grid-container w-75">
        <Grid item xs md lg={5} className="pe-4">
          <div className="d-flex flex-column">
            <img
              src="images/Logo.jpg"
              width={120}
              height={40}
              title="Optival"
              alt="Optival Logo"
              className="mb-3"
            ></img>
            <Typography variant="bodySmall" className="mb-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Typography>
          </div>
        </Grid>
        {!isLGScreen && (
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="divider-line mx-3"
          />
        )}
        {isLGScreen && (
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className="divider-line w-100 my-3"
          />
        )}
        <Grid item xs md lg={6} className={!isLGScreen ? "ps-5" : ""}>
          <Typography variant="h3" className="mb-4">
            Open your mobile application
          </Typography>
          <Typography variant="bodySmall" className="mb-4">
            Your organisation has setup the account for you to access in your
            mobile app. Download the app here
          </Typography>
          <div className="d-flex mt-5">
            <Link component="button" className="me-3">
              <img
                src="images/PlayStoreApp.svg"
                alt="Play Store"
                title="Play Store"
              />
            </Link>
            <Link component="button">
              <img
                src="images/AppleStoreApp.svg"
                alt="Apple Store"
                title="Apple Store"
              />
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
