import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { SkillsConstants } from "../../models/SkillsConstants";

export const AddSkillsDialog = (Props: any) => {
  const { t } = useTranslation();
  return (
    <Dialog open={Props?.openDialog} onClose={Props?.handleCloseDialog}>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>
            {Props?.mode === "add" ? t("opva.create") : t("opva.edit")}
            {t("opva.skill")}
          </h5>
          <IconButton aria-label="close" onClick={Props?.handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
        <TextField
          margin="dense"
          id="name"
          label={t("opva.name")}
          type="text"
          variant="standard"
          value={Props?.skillObj.skillName}
          required={Props?.skillObj.skillName.length === 0}
          fullWidth
          onChange={(e) =>
            Props?.setSkillObj({
              skillName: e.target.value,
              skillDescription: Props?.skillObj.skillDescription,
            })
          }
          error={
            Props?.skillObj.skillName.length >
            SkillsConstants.SKILLS_NAME_LENGTH
          }
          helperText={
            Props?.skillObj.skillName.length >
              SkillsConstants.SKILLS_NAME_LENGTH && t("opva.skillNameLength")
          }
        />
        <TextField
          margin="dense"
          id="description"
          label={t("opva.description")}
          type="text"
          variant="standard"
          value={Props?.skillObj.skillDescription}
          required={Props?.skillObj.skillDescription.length === 0}
          fullWidth
          multiline
          rows={3}
          onChange={(e) =>
            Props?.setSkillObj({
              skillName: Props?.skillObj.skillName,
              skillDescription: e.target.value,
            })
          }
        />
      </DialogContent>
      <DialogActions className="dialog-btn">
        <Button
          onClick={Props?.handleCreateUpdateSkills}
          color="primary"
          variant="contained"
          disabled={
            Props?.skillObj.skillName.length === 0 ||
            Props?.skillObj.skillDescription.length === 0 ||
            Props?.skillObj.skillName.trim() === "" ||
            Props?.skillObj.skillDescription.trim() === '' ||
            Props?.skillObj.skillName.length >
              SkillsConstants.SKILLS_NAME_LENGTH
          }
        >
          {Props?.mode === "add"
            ? t("opva.createSkill")
            : t("opva.saveChanges")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
