import { useContext } from "react";
import "./styles/common.scss";
import "./App.scss";
import { CssBaseline, Container, Box, Paper } from "@mui/material";
import { Header } from "./modules/header/components/Header";
import { Banner } from "./modules/banner/components/Banner";
import { Routers } from "./shared/Routers";
import { ThemeProvider } from "@mui/material/styles";
import OPTIVAL_APP_THEME from "./shared/Theme";
import { AuthenticationContext } from "./shared/Contexts";
import { SpinnerBackdrop } from "./shared/components/spinner-backdrop/SpinnerBackdrop";
import { AlertBar } from "./shared/components/alert-bar/AlertBar";
import { NotificationProvider } from "./modules/notification/contexts/NotificationContext";

function App() {
  const authenticate = useContext(AuthenticationContext);
  return (
    <ThemeProvider theme={OPTIVAL_APP_THEME}>
      <NotificationProvider>
      <div className="App">
        <CssBaseline />
        <Container maxWidth="xl" className="px-0">
          <Box sx={{ bgcolor: "#FDFFFE", width: "100%", height: "100vh" }}>
            {authenticate.token ? (
              <>
                <Header></Header>
                {/* <Banner></Banner> */}
              </>
            ) : null}
            <Routers />
            <SpinnerBackdrop />
            <AlertBar />
          </Box>
        </Container>
      </div>
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;
