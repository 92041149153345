import { createSlice } from "@reduxjs/toolkit";
import { UserListModel } from "../../modules/group-management/models/GroupManagementModels";

interface UserSelectState {
  addUserList: UserListModel[];
  removeUserList: UserListModel[];
}

const initialState = { addUserList: [], removeUserList: [] } as UserSelectState;

const userSelectReducer = createSlice({
  name: "userSelect",
  initialState,
  reducers: {
    setAddUserList: (state, action) => {
      state.addUserList = action.payload;
    },
    setRemoveUserList: (state, action) => {
      state.removeUserList = action.payload;
    },
  },
});

export const { setAddUserList, setRemoveUserList } = userSelectReducer.actions;
export default userSelectReducer.reducer;
