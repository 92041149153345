import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Link,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import { NewWorkflowConstants } from "../../../models/WorkflowData";
import { CREATE_WF_CONSTANTS } from "../../../models/CreateWorkflowConstant";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import { useAPI } from "../../../../../shared/services/api/API";
import { useTranslation } from "react-i18next";

export const ReviewWorkflow = (Props: any) => {
  const { t } = useTranslation();
  const { checkRole } = useAPI();
  return (
    <div className="bodyContainer">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <Typography variant="bodyMedium" style={{ marginBottom: "20px" }}>
        {t("opva.workflowDetails")}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflow: "auto",
            maxHeight: "170px",
            marginBottom: "20px",
          }}
        >
          <div className="workflow-card">
            <Card sx={{ display: "flex" }}>
              <Box>
                <CardMedia
                  component="img"
                  sx={{
                    width: "68px",
                    height: "68px",
                    margin: "14px",
                    borderRadius: "8px",
                  }}
                  image={Props?.workflowStepDetail?.url}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "180px",
                }}
              >
                <CardContent className="ellipsis-text">
                  <Typography
                    variant="h6"
                    className="ellipsis-text"
                    title={
                      Props?.tabValue ===
                      CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
                        ? Props?.workflowStepDetail?.name
                        : Props?.workflowStepDetail?.translatedNames &&
                          Props?.workflowStepDetail.translatedNames[
                            Props?.tabValue
                          ]
                        ? Props.workflowStepDetail.translatedNames[
                            Props?.tabValue
                          ]
                        : Props?.workflowStepDetail?.name
                    }
                  >
                    {Props?.tabValue ===
                    CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
                      ? Props?.workflowStepDetail?.name
                      : Props?.workflowStepDetail?.translatedNames &&
                        Props?.workflowStepDetail.translatedNames[
                          Props?.tabValue
                        ]
                      ? Props.workflowStepDetail.translatedNames[
                          Props?.tabValue
                        ]
                      : Props?.workflowStepDetail?.name}
                  </Typography>
                  <Typography
                    variant="labelSmall"
                    color="text.secondary"
                    className="ellipsis-text"
                    title={
                      Props?.tabValue ===
                      CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
                        ? Props?.workflowStepDetail?.description
                        : Props?.workflowStepDetail?.translatedDescriptions &&
                          Props?.workflowStepDetail.translatedDescriptions[
                            Props?.tabValue
                          ]
                        ? Props.workflowStepDetail.translatedDescriptions[
                            Props?.tabValue
                          ]
                        : Props?.workflowStepDetail?.description
                    }
                  >
                    {Props?.tabValue ===
                    CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
                      ? Props?.workflowStepDetail?.description
                      : Props?.workflowStepDetail?.translatedDescriptions &&
                        Props?.workflowStepDetail.translatedDescriptions[
                          Props?.tabValue
                        ]
                      ? Props.workflowStepDetail.translatedDescriptions[
                          Props?.tabValue
                        ]
                      : Props?.workflowStepDetail?.description}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pl: 1,
                    pb: 1,
                  }}
                >
                  <Chip
                    style={{
                      width: "77px",
                      height: "14px",
                      color: "rgba(0, 132, 244, 1)",
                      fontFamily: "Mulish",
                      fontSize: " 9px",
                    }}
                    className="blue"
                    label={Props?.tagName}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
                <Link
                  component="button"
                  className="ms-2"
                  title="Edit"
                  onClick={(e) => Props.editIconClick("Workflow")}
                >
                  <EditIcon />
                </Link>
              </Box>
            </Card>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <Typography variant="bodyMedium" style={{ marginBottom: "20px" }}>
        {t("opva.steps")}
        </Typography>
        {Props?.uploadedData.length === 0 ? (
          <div></div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                maxHeight: "200px",
              }}
            >
              {Props?.uploadedData.map((data: any, index: number) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 20,
                  }}
                  className={`item ${
                    data === Props?.draggingItem ? "dragging" : ""
                  }`}
                  draggable="true"
                  onDragStart={(e) => Props?.handleDragStart(e, data)}
                  onDragEnd={Props?.handleDragEnd}
                  onDragOver={Props?.handleDragOver}
                  onDrop={(e) => Props?.handleDrop(e, data)}
                >
                  <div className="video-image">
                    {Props?.renderFilePreview(index)}
                  </div>

                  <div style={{ marginLeft: "20px" }}>
                    <Typography className="content-number">
                      {index + 1}
                    </Typography>
                  </div>
                  <div
                    style={{
                      marginLeft: "20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <Typography variant="bodyNormal">
                        {Props?.tabValue ===
                        CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
                          ? data?.title
                          : data?.translatedTitles &&
                            data.translatedTitles[Props?.tabValue]
                          ? data.translatedTitles[Props?.tabValue]
                          : data?.title}
                      </Typography>
                    </div>
                  </div>

                  <div className="ms-3">
                    <Link
                      component="button"
                      title={t("opva.delete")}
                      onClick={(e) => Props.deleteIconClick(index)}
                    >
                      <DeleteForeverIcon color="error" />
                    </Link>
                  </div>
                  <div className="ms-3">
                    <Link
                      component="button"
                      title="Edit"
                      onClick={(e) => Props.editIconClick("Steps", index)}
                    >
                      <EditIcon />
                    </Link>
                  </div>
                  <div className="ms-3">
                    <Link
                      component="button"
                      title="Sort"
                      onClick={(e) => Props.reorderIconClick(index)}
                    >
                      <MultipleStopIcon sx={{ transform: "rotate(90deg)" }} />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div></div>
      </div>
    </div>
  );
};
