import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { QueryParamsModels } from "../models/APIModels";
import { useContext } from "react";
import { AlertColor } from "@mui/material";
import { SpinnerContext } from "../../SpinnerContext";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../../shared/Contexts";
import { AlertBarContext } from "../../AlertBarContext";
import { OPTIVAL_CONSTANTS } from "../../Constants";
import { useTranslation } from "react-i18next";

export const useAPI = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const authenticate = useContext(AuthenticationContext);
  const { toggleSpinner } = useContext(SpinnerContext);
  const { showAlertBar } = useContext(AlertBarContext);
  const { userRole } = useContext(AuthenticationContext);
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_OPVA_API_BASE_URL,
  });
  // request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.headers["isLoading"]) {
        toggleSpinner(true);
      }
      return config;
    },
    (error: AxiosError) => {
      toggleSpinner(false);
      console.error("API Request Error->", error);
      return Promise.reject(error);
    }
  );
  // response interceptor
  axiosInstance.interceptors.response.use(
    function (response: any) {
      toggleSpinner(false);
      if (response && response.data && response.data.error) {
        if (response.data.status && response.data.status === 400) {
          handleAlertBar(
            "error",
            t(`opva.${response.data.code}`) || OPTIVAL_CONSTANTS.BAD_REQUEST
          );
          throw new Error(response);
        } else if (response.data.status && response.data.status === 401) {
          handleAlertBar("info", OPTIVAL_CONSTANTS.USER_SESSION_TIMEOUT);
          handleLogout();
          throw new Error(response);
        } else if (response.data.status && response.data.status === 404) {
          handleAlertBar("error", OPTIVAL_CONSTANTS.SERVICE_NOT_FOUND);
          throw new Error(response);
        }
      }
      return response;
    },
    function (error: AxiosError) {
      console.error("API Response Error->", error);
      handleAlertBar("error", t("opva.serviceUnavailable"));
      toggleSpinner(false);
      return Promise.reject(error);
    }
  );

  const getRequestConfig = (
    params: QueryParamsModels | undefined,
    isSpinner: boolean | undefined = true,
    data?: any
  ) => {
    const requestConfig: AxiosRequestConfig = {
      validateStatus: () => true,
      params,
      headers: {
        Authorization: localStorage.getItem("jwtToken") || null,
        isLoading: isSpinner ? "BackDrop" : null,
      },
      data: data,
    };
    return requestConfig;
  };

  const httpGet = (
    url: string,
    params?: QueryParamsModels,
    isSpinner?: boolean
  ) => {
    return axiosInstance.get(url, getRequestConfig(params, isSpinner));
  };

  const httpPost = (
    url: string,
    data: any,
    params?: QueryParamsModels,
    isSpinner?: boolean
  ) => {
    return axiosInstance.post(url, data, getRequestConfig(params, isSpinner));
  };

  const httpPut = (
    url: string,
    data: any,
    params?: QueryParamsModels,
    isSpinner?: boolean
  ) => {
    return axiosInstance.put(url, data, getRequestConfig(params, isSpinner));
  };

  const httpDelete = (
    url: string,
    data?: any,
    params?: QueryParamsModels,
    isSpinner?: boolean
  ) => {
    return axiosInstance.delete(url, getRequestConfig(params, isSpinner, data));
  };

  const handleAlertBar = (
    type: AlertColor,
    message: string,
    title?: string
  ) => {
    showAlertBar({
      open: true,
      type,
      message,
      title,
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    sessionStorage.clear();
    authenticate.updateToken("");
    navigate("/");
  };

  const checkRole = (roles: string[]): boolean => {
    return !!userRole.filter((element) => roles.includes(element)).length;
  };

  return { httpGet, httpPost, httpPut, httpDelete, handleAlertBar, checkRole };
};
