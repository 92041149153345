export function updateURLParams(url: string, urlParams: any): string {
  for (const key in urlParams) {
    if (Object.prototype.hasOwnProperty.call(urlParams, key)) {
      url = url.replace(key, urlParams[key]);
    }
  }
  return url;
}

function comparator(a: any, b: any, property: string) {
  let valueA = a[property];
  let valueB = b[property];

  if (Array.isArray(valueA)) {
    valueA = valueA
      .map((item) => (item && item.name ? item.name : ""))
      .join("");
  }
  if (Array.isArray(valueB)) {
    valueB = valueB
      .map((item) => (item && item.name ? item.name : ""))
      .join("");
  }

  const valueAUpperCase = String(valueA).toUpperCase();
  const valueBUpperCase = String(valueB).toUpperCase();

  if (valueAUpperCase < valueBUpperCase) {
    return -1;
  }
  if (valueAUpperCase > valueBUpperCase) {
    return 1;
  }
  return 0;
}
const getPropertyValueByPath = (obj: any, path: string, property: string) => {
  const value = obj[path];
  return value ? value[property] : "";
};

export function tableSorting<T>(
  array: T[],
  direction: string,
  property: string,
  propertyType: string,
  path?: string
) {
  return array?.sort((a: any, b: any) => {
    let result: number = 0;
    //For sorting based on the nested object property.
    if (path) {
      const aValue = getPropertyValueByPath(a, path, property);
      const bValue = getPropertyValueByPath(b, path, property);
      if (propertyType === "string") {
        result =
          direction === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
      } else if (propertyType === "number") {
        result = direction === "asc" ? aValue - bValue : bValue - aValue;
      }
      return result;
    }
    if (propertyType === "string") {
      result =
        direction === "asc"
          ? comparator(a, b, property)
          : -comparator(a, b, property);
    }
    if (propertyType === "number") {
      result =
        direction === "asc"
          ? a[property] - b[property]
          : b[property] - a[property];
    }
    return result;
  });
}

export function emailValidator(emailId: string): boolean {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailId);
}
