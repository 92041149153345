import { Grid, Skeleton } from "@mui/material";

export const AssignLoading = () => {
  const loadContentList: number[] = Array.from({ length: 4 });
  return (
    <>
      {loadContentList.map((data, index: number) => (
        <Grid item xs={3} md={3} key={index}>
          <section className="assigned-user">
            <div className="d-flex align-items-center mb-3">
              <Skeleton variant="circular" width={45} height={40} />
              <div className="w-100 ms-2">
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              </div>
            </div>
            <Skeleton variant="rectangular" height={20} className="mb-3" />
            <Skeleton variant="rectangular" height={20} />
          </section>
        </Grid>
      ))}
    </>
  );
};
