import { Grid, Link, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { SkillsContext } from "../../../contexts/SkillsContext";
import { useTranslation } from "react-i18next";
import "./SkillsGainedMonth.scss";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useAPI } from "../../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

export const SkillsGainedMonth = () => {
    const { updateSkillsGainSkillLessMore } = useContext(SkillsContext);
    const { t } = useTranslation();
    const { httpGet, handleAlertBar } = useAPI();
    const [formatData, setFormatData] = useState<any>({});

    const getskillsMonthlyData = () => {
      httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_MONTHLY).then(
        (response) => {
          if (response && response.data) {
            if (response.data.error) {
              handleAlertBar("error", response.data.message);
            } else {
              // set monthly graph 
            const result= transformData(response?.data) 
            setFormatData(result);
            }
          } else {
            handleAlertBar("error", t("opva.serviceUnavailable"));
          }
        }
      );
    };

    const transformData = (data: any) =>{
      const resp: any = {
        2023: [],
        2024: []
      };
      for (const [key, value] of Object.entries(data)) {
        const year = key.split('-')[1];
        resp[year].push(value);
      }
      return resp;
    }
  
    useEffect(() => {
      getskillsMonthlyData();
    }, []);

    const options = {
      chart: {
        type: 'spline',
        backgroundColor: "#fafbfc",
        height:350,
      },
      title: {
          text: t("opva.totalSkills"),
          align: 'left'
      },
      xAxis: {
        categories: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      lineColor: 'transparent',
    },
    yAxis: {
        title: {
            text: ''
        },
        gridLineColor: 'transparent'
    },
      legend: {
          layout: 'horizontal',
          align: 'left',
          x: 100,
          verticalAlign: 'top',
          y: -45,
      },
      credits: {
        enabled: false
    },
      series: [{
          name: t("opva.lastYear"),
          data: formatData[2023],
          marker: {
              enabled: false
          },
          dashStyle: 'dash',
          lineColor: "#A8C5DA"
  
      }, {
          name: t("opva.thisYear"),
          data: formatData[2024],
          marker: {
            enabled: false,
          },
        type: "areaspline",
        fillOpacity: 0.1,
        color: "#0084f4"
      }],
  }
  return (
    <div className="skills-gained-container mt-4">
    <Grid container display={'flex'} justifyContent={'space-between'}>
      <Grid item xs={8} sm={8} md={10} lg={10}>
    <Typography variant="h5">
    {t("opva.skillGainThisMonth")}
              </Typography>
      </Grid>
      <Grid item xs={4} sm={4} md={1} lg={1} textAlign="right">
      <Link
          title={t("opva.backToOverview")}
          color={"#00000"}
          component="button"
          className="mb-2"
          onClick={() => updateSkillsGainSkillLessMore(false)}
        >
          <CloseFullscreenIcon fontSize="small" />
        </Link>
    </Grid>
    </Grid>
    <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
  </div>
  )
}