import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import "./ManageOrganisation.scss";
import { visuallyHidden } from "@mui/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Breadcrumb } from "../../breadcrumb/components/Breadcrumb";
import { ManageOrgConstants, useManageOrganisationUtil } from "./constants/MangeOrganisationConstants";
import { Order, TableHeadCell } from "../../../shared/models/TableModels";
import {
  ManageOrgListModel,
  TableDataActionsModel,
} from "./models/ManageOragnisaionModels";
import { useAPI } from "../../../shared/services/api/API";
import { SpinnerContext } from "../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { TableLoading } from "../../../shared/components/skeleton/table-loading/TableLoading";
import { tableSorting } from "../../../shared/utils/Utils";
import { AppDispatch } from "../../../shared/Store";
import { setPassDataState } from "../../../shared/reducers/PassDataReducer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
export const ManageOrganisation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { httpGet, handleAlertBar } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [tableDataActions, setTableDataActions] = useState({
    page: 0,
    perPageRows: 10,
    order: "desc",
    orderBy: "name",
    searchText: "",
  } as TableDataActionsModel);
  const [manageOrgList, setManageOrgList] = useState<ManageOrgListModel[]>([]);
  const [filteredManageOrgData, setFilteredManageOrgData] = useState<
    ManageOrgListModel[]
  >([]);
  const [searchManageText, setSearchManageText] = useState("");
  const { getManageOrgHeaders } = useManageOrganisationUtil();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updateProperty = {
      perPageRows: parseInt(event.target.value, 10),
      page: 0,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };
  const handlePaginationChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setTableDataActions((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };
  const handleSorting = (property: string) => {
    const isAsc =
      tableDataActions.orderBy === property && tableDataActions.order === "asc";
    const updateProperty = {
      order: isAsc ? "desc" : ("asc" as Order),
      orderBy: property,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };
  const handleSearchByOrg = (value: string) => {
    if (!value) {
      setManageOrgList([...manageOrgList]);
    } else {
      const filteredData = manageOrgList.filter((OrgData: ManageOrgListModel) =>
        OrgData.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredManageOrgData(
        filteredData.slice(
          tableDataActions.page * tableDataActions.perPageRows,
          tableDataActions.page * tableDataActions.perPageRows +
            tableDataActions.perPageRows
        )
      );
    }
  };
  const fetchManageOrgList = () => {
    const queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "name",
      isDescending: true,
      searchString: "",
    };
    httpGet(
      OPTIVAL_CONSTANTS.API_URLS.GET_MANAGE_ORGANISATION,
      queryParams
    ).then(
      (response) => {
        if (response && response?.data && !response.data?.error) {
          setManageOrgList(response.data);
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };
  useMemo(() => {
    const propertyType =
      tableDataActions.orderBy === "assignedEmployeesCount"
        ? "number"
        : "string";
    let path;
    //For sorting based on the nested object property. 
    if (tableDataActions.orderBy === "endDate") {
      path = "organizationPlans";
    }
    const sortedList = tableSorting(
      manageOrgList,
      tableDataActions.order,
      tableDataActions.orderBy,
      propertyType,
      path
    );
    setManageOrgList(sortedList);
    setFilteredManageOrgData(
      sortedList.slice(
        tableDataActions.page * tableDataActions.perPageRows,
        tableDataActions.page * tableDataActions.perPageRows +
          tableDataActions.perPageRows
      )
    );
  }, [tableDataActions, manageOrgList]);
  useEffect(() => {
    handleSearchByOrg(searchManageText);
  }, [searchManageText]);
  useEffect(() => {
    fetchManageOrgList();
  }, []);
  const handleEdit = (rowData: any) => {
    navigate("/edit-organisation?id=" + rowData.id, { state: { rowData } });
  };
  return (
    <div>
      <div className="manage-organisation-container px-5">
        <Grid container alignItems="center" className="pt-5 pb-5">
          <Grid item xs md lg={5} className="d-flex align-items-center">
            <Typography variant="h3" className="mb-0">
              {t("opva.manageOrganisation")}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            md
            lg={7}
            className="d-flex align-items-center justify-content-end"
          >
            <Input
              id="searchByOrganisation"
              placeholder={t("opva.searchForOrganisation")}
              value={searchManageText}
              style={{ minWidth: "400px", height: "32px" }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              }
              endAdornment={
                searchManageText && (
                  <InputAdornment position="end">
                    <Link component="button" title={t("opva.clear")}>
                      <CloseIcon
                        fontSize="small"
                        onClick={() => setSearchManageText("")}
                      />
                    </Link>
                  </InputAdornment>
                )
              }
              aria-describedby="standard-weight-helper-text"
              onChange={(event) => setSearchManageText(event.target.value)}
            />
          </Grid>
        </Grid>
      </div>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="table-container px-5"
      >
        <TableContainer className="table-container">
          <Table
            stickyHeader
            sx={{ width: "100%" }}
            size="medium"
            aria-label="Manage Organisation table"
          >
            <TableHead>
              <TableRow key="manage-org-table-header">
                {getManageOrgHeaders().map(
                  (headCell: TableHeadCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={
                        tableDataActions.orderBy === headCell.sortingProperty
                          ? tableDataActions.order
                          : false
                      }
                      align={headCell.position}
                      className="px-2"
                      sx={{
                        width: headCell.width + "%",
                      }}
                    >
                      {headCell.sorting ? (
                        <TableSortLabel
                          active={
                            tableDataActions.orderBy ===
                            headCell.sortingProperty
                          }
                          direction={
                            tableDataActions.orderBy ===
                            headCell.sortingProperty
                              ? tableDataActions.order
                              : "asc"
                          }
                          onClick={(event) =>
                            handleSorting(headCell.sortingProperty)
                          }
                        >
                          {headCell.label}
                          {tableDataActions.orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {tableDataActions.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            {isSpinnerShow ? (
              <TableLoading
                column={getManageOrgHeaders().length}
              />
            ) : (
              <TableBody>
                {filteredManageOrgData && filteredManageOrgData.length > 0 ? (
                  filteredManageOrgData.map(
                    (data: ManageOrgListModel, index: number) => (
                      <TableRow key={index}>
                        <TableCell className="p-2">{data.name}</TableCell>
                        <TableCell className="p-2">{data?.createdBy}</TableCell>
                        <TableCell className="p-2">
                          {data?.organizationPlans?.endDate &&
                            new Date(
                              data?.organizationPlans?.endDate
                            ).toLocaleDateString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="p-2"
                          onClick={(e) => handleEdit(data)}
                        >
                          <Link component="button" title="Edit">
                            <EditOutlinedIcon />
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={
                        getManageOrgHeaders().length
                      }
                    >
                      {searchManageText
                        ? t("opva.noSearchManagementOrgDataAvailable")
                        : t("opva.noManagementOrgDataAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <div className="d-flex align-items-center justify-content-end">
          <TablePagination
            component="div"
            className="pagination-container mb-4"
            count={manageOrgList.length}
            page={tableDataActions.page}
            rowsPerPage={tableDataActions.perPageRows}
            onPageChange={handlePaginationChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Box>
    </div>
  );
};
