import { createSlice } from "@reduxjs/toolkit";

interface PassDataState {
  data: any;
}

const initialState = { data: null } as PassDataState;

const passDataReducer = createSlice({
  name: "passData",
  initialState,
  reducers: {
    resetPassDataState: () => initialState,
    setPassDataState: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { resetPassDataState, setPassDataState } = passDataReducer.actions;
export default passDataReducer.reducer;
