import "./AssignWorkflow.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Chip,
  InputAdornment,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  AssignWorkflowProps,
  UserListModel,
} from "../../models/AssignWorkflowModel";
import { AuthenticationContext } from "../../../../shared/Contexts";
import { useAPI } from "../../../../shared/services/api/API";
import { updateURLParams } from "../../../../shared/utils/Utils";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { CREATE_WF_CONSTANTS } from "../../models/CreateWorkflowConstant";
import { useTranslation } from "react-i18next";

export const AssignWorkflow = (props: AssignWorkflowProps) => {
  const { authUserDetails } = useContext(AuthenticationContext);
  const { httpGet, httpPost, httpDelete, handleAlertBar } = useAPI();
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [userList, setUserList] = useState<UserListModel[] | null>(null);
  const [selectedUserItem, setSelectedUserItem] =
    useState<UserListModel | null>(null);
  const [searchUserName, setSearchUserName] = useState<string>("");
  const { t } = useTranslation();

  const handleAssignWorkflowToEmployee = (userData: UserListModel) => {
    setSelectedUserItem(userData);
    props.sentAssignUserIds([userData.id]);
    if (props.workflowDetail && props.workflowDetail?.id) {
      const urlParams = {
        employeeId: userData.id,
        versionId: props.workflowDetail?.id,
      };
      httpPost(
        updateURLParams(
          OPTIVAL_CONSTANTS.API_URLS.ASSIGN_WORKFLOW_TO_EMPLOYEE,
          urlParams
        ),
        null
      ).then(
        (response: any) => {
          if (response && response.data && response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            props.doPublishWorkflow(true);
            handleAlertBar(
              "success",
              t("opva.workflowAssignedEmpMsg")
            );
          }
        },
        (error: any) => {}
      );
    }
  };

  const handleRemoveEmployeeFromAssignedWorkflow = (
    userData: UserListModel
  ) => {
    if (props.workflowDetail && props.workflowDetail?.id) {
      const urlParams = {
        employeeId: userData.id,
        versionId: props.workflowDetail?.id,
      };
      httpDelete(
        updateURLParams(
          OPTIVAL_CONSTANTS.API_URLS.REMOVE_ASSIGN_WORKFLOW_FROM_EMPLOYEE,
          urlParams
        ),
        null
      ).then(
        (response: any) => {
          if (response && response.data && response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            setSelectedUserItem(null);
            props.sentAssignUserIds(null);
            props.doPublishWorkflow(false);
            handleAlertBar(
              "success",
              t("opva.removeWorkflowAssignedEmployeeMsg")
            );
          }
        },
        (error: any) => {}
      );
    } else {
      setSelectedUserItem(null);
      props.sentAssignUserIds(null);
    }
  };

  const fetchUserList = useMemo(
    () =>
      debounce((searchUser: string) => {
        if (authUserDetails && authUserDetails.organizationId) {
          setIsUserSearching(true);
          const urlParams = {
            organizationId: authUserDetails.organizationId,
          };
          const queryParams = {
            roleIds: 5,
            page: 0,
            size: 1000,
            sortColumn: "mail",
            isDescending: true,
            searchString: searchUser,
          };
          httpGet(
            updateURLParams(
              OPTIVAL_CONSTANTS.API_URLS.GET_EMPLOYEES_BY_ORGANISATION,
              urlParams
            ),
            queryParams,
            false
          ).then(
            (response: any) => {
              setIsUserSearching(false);
              if (response && response.data && !response.data?.error) {
                setUserList(response.data);
              } else {
                handleAlertBar(
                  "error",
                  response?.data?.message ||
                    t("opva.serviceUnavailable")
                );
              }
            },
            (error: any) => {
              setIsUserSearching(false);
              handleAlertBar(
                "error",
                error.message || t("opva.serviceUnavailable")
              );
            }
          );
        }
      }, 500),
    [authUserDetails]
  );

  useEffect(() => {
    if (
      props.workflowDetail &&
      props.workflowDetail.assignedContentEditors &&
      props.workflowDetail.assignedContentEditors.length > 0
    ) {
      setSelectedUserItem(props.workflowDetail.assignedContentEditors[0]);
    }
  }, [props.workflowDetail]);

  return (
    <div className="assign-workflow-container">
      <Typography variant="bodyMedium">{props.title}</Typography>
      <div className="d-flex mt-4 mb-3">
        <Autocomplete
          id="search-by-user"
          className="me-5 w-25"
          options={userList ? userList : []}
          autoComplete
          loading={isUserSearching}
          loadingText={t("opva.userSearching")}
          noOptionsText={
            !userList ? (
              <Typography variant="labelNormal">
               {t("opva.type2Letters")}
              </Typography>
            ) : (
              <Typography variant="labelNormal">{t("opva.noUsersFound")}</Typography>
            )
          }
          getOptionLabel={(option: UserListModel | string) => searchUserName}
          renderOption={(props, option: UserListModel) => (
            <li {...props} key={option.id} value={option.mail}>
              <Typography variant="labelLarge">
                {option.firstName}&nbsp;{option.lastName}
              </Typography>
            </li>
          )}
          value={null}
          onChange={(event: any, value: UserListModel | null) => {
            if (value && typeof value === "object") {
              handleAssignWorkflowToEmployee(value);
            }
          }}
          onInputChange={(event: any, newInputValue: string) => {
            setSearchUserName(newInputValue);
            if (newInputValue && newInputValue.length > 2) {
              fetchUserList(newInputValue);
            }
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              size="small"
              variant="standard"
              placeholder={t("opva.searchByEditor")}
              className="search-name-text"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {selectedUserItem && (
          <Chip
            key={selectedUserItem.id}
            label={selectedUserItem.mail}
            color="primary"
            onDelete={(e) =>
              handleRemoveEmployeeFromAssignedWorkflow(selectedUserItem)
            }
            sx={{ borderRadius: "8px" }}
          />
        )}
      </div>
    </div>
  );
};
