import { createContext, useMemo, useState } from "react";
import {
  AlertBarContextModel,
  AlertBarModel,
} from "./models/AlertBarContextModel";

export const AlertBarContext = createContext({} as AlertBarContextModel);

export const AlertBarProvider = ({ children }: any) => {
  const [alertBarState, setAlertBarState] = useState<AlertBarModel>({
    open: false,
    type: "success",
  });

  const showAlertBar = (state: AlertBarModel) => {
    setAlertBarState(state);
  };

  const closeAlertBar = () => {
    setAlertBarState({ ...alertBarState, open: false });
  };

  const alertBarProviderValues = useMemo(
    () => ({ alertBarState, showAlertBar, closeAlertBar }),
    [alertBarState]
  );

  return (
    <AlertBarContext.Provider value={alertBarProviderValues}>
      {children}
    </AlertBarContext.Provider>
  );
};
