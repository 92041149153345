import { GapAnalysisByGroup } from "./gap-analysis-by-group/GapAnalysisByGroup";
import { GapAnalysisBySkills } from "./gap-analysis-by-skills/GapAnalysisBySkills";
import "./GapAnalysis.scss";
export const GapAnalysis = (Props: {byGroup: boolean}) => {
  return (
    <div className="gap-analysis-container">
      {Props?.byGroup ? <GapAnalysisByGroup /> : <GapAnalysisBySkills />}
    </div>
  );
};
